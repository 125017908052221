import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'

/**
 * 
 * @param {string} title the title to display 
 */
export default function PageTitle(props) {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
    <Typography color={'text.dark'} variant={'h4'} sx={{ textAlign: 'center', backgroundColor: 'text.secondary', display: 'inline-flex', padding: '5px', borderRadius: '8px'}}>
      {props.title}
    </Typography>
    </Box>
  )
}