import React from 'react';
import PricingContextParagraph from './PricingContextParagraph';
import FeaturesBox from './FeaturesBox';
import PricingMatrix from './PricingMatrix';
import PageTitle from '../../components/shared/PageTitle';
import { ComputersContainer } from '../../style/ComputersContainer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SEO from '../../components/shared/SEO';

function SpecsPageSubtitle(props) {
  return (
    <Typography
      color={'text.primary'}
      variant={'h5'}
      sx={{
        paddingBottom: '20px',
        marginTop: { xs: '5%', sm: '40px' },
        textAlign: 'center',
      }}
    >
      {props.subtitle}
    </Typography>
  );
}

export default function SpecsPage() {
  return (
    <>
      <SEO
        title={'Pricing & Specifications'}
        description={
          'With no monthly subscription fees and different computing options, there is something for everyone at Computir.'
        }
      />
      <ComputersContainer>
        <PageTitle title={'Pricing & Specifications'} />
        <PricingContextParagraph />
        <SpecsPageSubtitle subtitle={'Pricing'} />
        <Box sx={{ justifyContent: 'center', display: 'grid' }}>
          <PricingMatrix />
        </Box>
        <SpecsPageSubtitle subtitle={'Features'} />
        <Box sx={{ justifyContent: 'center', display: 'grid' }}>
          <FeaturesBox />
        </Box>
      </ComputersContainer>
    </>
  );
}
