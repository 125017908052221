import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button'
import Info from '@mui/icons-material/Info'
// or info outlined?

/**
 * 
 * @param {string} placement - where tooltip pops up from
 * @param {string} tooltipText - what pops up in tooltip
 * @param {string} color - color of tooltip icon
 * @param {string} underline - if text should be underlined or not?
 * @param {string} infoSize - size of info font
 * @returns 
 */
export default function TooltipIcon({placement, tooltipText, color, underline, infoSize}) {

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement={placement}
                    title={tooltipText}
                    componentsProps={{tooltip: {sx: {textAlign: 'left'}}}}
                >
                    <Button disableRipple
                        onClick={handleTooltipOpen}
                        sx={{
                            background: "none!important",
                            border: "none",
                            padding: "0!important",
                            color: color,
                            textDecoration: underline,
                            cursor: "pointer",
                            textTransform: "none",
                            minWidth: "0px"
                        }}><Info fontSize={infoSize}/></Button>
                </Tooltip>
            </div>
        </ClickAwayListener>
    )
}