import React from 'react';
import PageTitle from '../../components/shared/PageTitle';
import { ComputersContainer } from '../../style/ComputersContainer';
import NewsLetterSignUp from '../../components/NewsLetterSignUp';

function AccessDeniedContent() {
  return (
    <div style={{ padding: '3%' }}>
      <h2>
        Unfortunately, access to Computir is currently restricted from your
        country.
      </h2>
      <br />
      <p>
        We are hard at work to bring Computir worldwide. To stay up to date
        about our progress and Computir news, please sign up for our newsletter:
      </p>
      <NewsLetterSignUp />
    </div>
  );
}

export default function AccessDenied() {
  return (
    <>
      <ComputersContainer>
        <PageTitle title={'Access Denied'} />
        <br />
        <AccessDeniedContent />
      </ComputersContainer>
    </>
  );
}
