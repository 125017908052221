import { observable, action } from 'mobx';

export default class StripeStore {
  @observable actionState = 'pending';
  @observable portalActionState = 'pending';
  @observable url = 'notSet';
  @observable portalUrl = 'notSet';
  @observable paymentVerified = 'loading...';
  @observable customerBalance = 0;

  constructor(stripeService) {
    this.stripeService = stripeService;
    this.token = '';
  }

  async waitForToken() {
    let returnToken;
    await Promise.resolve(this.token).then(function (value) {
      returnToken = value;
    });
    return returnToken;
  }

  @action
  async getPaymentVerifiedStatus() {
    this.stripeService.accessToken = await this.waitForToken();
    const result = await this.stripeService.getPaymentVerifiedStatus();
    if (result) {
      this.paymentVerified = result.data.body.status;
      return result.data.body.status;
    }
  }

  @action
  async updateStripeMetaData(stripeCheckoutSessionId) {
    this.stripeService.accessToken = await this.waitForToken();
    const result = await this.stripeService.updateStripeMetaData(
      stripeCheckoutSessionId,
    );
    if (result) {
      if (JSON.parse(result.data.body).status === 'success') {
        this.paymentVerified = 'verified';
        // TODO set so banner is no longer needed
        console.log(
          'developers need to do something to remove this logging message...',
        );
      }
    }
  }

  @action
  async associateStripeAuth0Accounts(stripeCheckoutSessionId) {
    this.stripeService.accessToken = await this.waitForToken();
    await this.stripeService.associateStripeAuth0Accounts(
      stripeCheckoutSessionId,
    );
  }

  @action
  async getStripeCheckoutUrl() {
    const url = window.location.href;
    const baseUrl = url.split('/').slice(0, 3).join('/');
    this.stripeService.accessToken = await this.waitForToken();
    const result = await this.stripeService.getStripeCheckoutUrl(baseUrl);
    if (result && this.actionState === 'pending') {
      this.actionState = 'done';
      const url = result.data.url;
      this.url = url;
    }
  }

  @action
  async getStripePortalUrl() {
    this.stripeService.accessToken = await this.waitForToken();
    const result = await this.stripeService.getStripePortalUrl();
    if (result && this.portalActionState === 'pending') {
      console.log(result);
      const portalUrl = result.data.portal.url;
      this.portalUrl = portalUrl;
      this.customerBalance = result.data.customerBalance;
      this.portalActionState = 'done';
    }
  }
}
