export const COMPUTIR_TYPES = {
  'computir_quadro-windows': {
    details: '8 vCPU | 12GB RAM | Quadro RTX 4000',
    displayName: 'Computir RTX 4000 (Windows)',
    price: '$0.70',
    Gpu: 'NVIDIA Quadro RTX 4000',
    generalDisplayName: 'Computir RTX 4000',
    availableOs: 'Windows or Linux',
  },
  'computir_quadro-linux': {
    details: '8 vCPU | 12GB RAM | Quadro RTX 4000',
    displayName: 'Computir RTX 4000 (Linux)',
    price: '$0.70',
    Gpu: 'NVIDIA Quadro RTX 4000',
    generalDisplayName: 'Computir RTX 4000',
    availableOs: 'Windows or Linux',
  },
  'computir_rtx_5000-windows': {
    details: '8 vCPU | 12GB RAM | Quadro RTX 5000',
    displayName: 'Computir RTX 5000 (Windows)',
    price: '$1.00',
    Gpu: 'NVIDIA Quadro RTX 5000',
    generalDisplayName: 'Computir RTX 5000',
    availableOs: 'Windows or Linux',
  },
  'computir_rtx_5000-linux': {
    details: '8 vCPU | 12GB RAM | Quadro RTX 5000',
    displayName: 'Computir RTX 5000 (Linux)',
    price: '$1.00',
    Gpu: 'NVIDIA Quadro RTX 5000',
    generalDisplayName: 'Computir RTX 5000',
    availableOs: 'Windows or Linux',
  },
  'computir_rtx_a5000-windows': {
    details: '8 vCPU | 12GB RAM | Quadro RTX A5000',
    displayName: 'Computir RTX A5000 (Windows)',
    price: '$1.25',
    Gpu: 'NVIDIA Quadro RTX A5000',
    generalDisplayName: 'Computir RTX A5000',
    availableOs: 'Windows or Linux',
  },
  'computir_rtx_a5000-linux': {
    details: '8 vCPU | 12GB RAM | Quadro RTX A5000',
    displayName: 'Computir RTX A5000 (Linux)',
    price: '$1.25',
    Gpu: 'NVIDIA Quadro RTX A5000',
    generalDisplayName: 'Computir RTX A5000',
    availableOs: 'Windows or Linux',
  },
  'computir_xeon_v3-windows': {
    details: '2 vCPU | 4GB RAM',
    displayName: 'Computir Basic - No GPU (Windows)',
    price: '$0.25',
    Gpu: 'None',
    generalDisplayName: 'Computir Basic - No GPU',
    availableOs: 'Windows',
  },
};
