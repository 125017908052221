import React from 'react';
import SEO from '../../components/shared/SEO';

const privacyPolicyString = `
PRIVACY POLICY
Last Revised: 12/30/22
Your privacy is important to Computir, Inc. (“Computir”). This Computir Privacy Policy (“Privacy Policy”) describes how Computir may collect, use, store, disclose, and process your personal information, through your access to or use of Computir products and services, including those at http://www.computir.com, related websites, mobile software applications, and other offerings (collectively, the “Services”). By using these Services, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use the Services. Computir may change this Privacy Policy from time to time by posting changes at this URL and may send notification emails. Your continued use of the Services following the posting of such changes will be deemed your acceptance of those changes.

Computir encourages you to read this Privacy Policy in its entirety, but here are a few key points of our privacy practices:

Privacy Policy Applicability
This Privacy Policy applies when you access the Services or share information with Computir, and may be revised at any time.

Information We Collect from You 
We collect all information that you provide including information you provide via the Services, your devices (e.g., computers, smartphones, and tablets), telephone and email, as well as information we receive from partners and through the use of cookies and other such technologies.

How We Use Your Information
We process your personal information only with your consent or as needed to provide you Services. We may also use your personal information to comply with legal obligations, operate our business, protect the vital interests of you, our customers, or the public, or for other legitimate interests of Computir as further described below.

How We Share Your Information 
We share your information with our partners, service providers, contractors, agents and third party vendors as they need it to fulfill Services or orders, or for other requests you make of Computir.  We may also share your information to comply with law or for other legitimate interests.

How We Store and Secure Your Information
Computir may store your information in any country where Computir or its affiliates, partners, or providers operate facilities, and will retain your information as long as necessary for the purposes outlined in this Privacy Policy. Computir takes technological and organizational measures to protect your personal information against loss, theft, and unauthorized access, use, disclosure or modification.

Accessing and Updating Your Information
You can access, get copies of, delete, change or correct your personal information, or request changes to our use of your personal information by using the contact information below.

Additional Privacy Information 
Computir’s databases are located in the United States and you consent to the transfer or your personal information from your location to the United States. The Services is not for use by children under the age of 13 years old. You may have additional rights under applicable law. If you have any questions about this privacy policy, please contact us via the information below.

Privacy Policy Applicability
This Privacy Policy applies when you access the Services or share information with Computir, as indicated below. By using the Services, you consent to this Privacy Policy, which may be updated by us at any time. If you do not consent to this Privacy Policy for any reason, please do not use the Services or share your information with us. Please note that this Privacy Policy applies only to the Services, and not to any other third party website linked to or from it, or any third party website in which Computir content or functionality is embedded. We do not control the privacy policies or practices of others.

Information We Collect from You
In general, we receive and collect all information you provide via the Services, including through website input fields (including advertisements), phone, email (including email campaigns), web chat, or in other such ways. This includes personal information that can identify or relates to you, including but not limited to your first and last name, telephone number, IP, postal, and email addresses. You have the choice on what information to share and the services you want to engage. You can choose not to provide information to us, but in general some information about you is required in order for you to access certain functionality of the Services, such as those mentioned above or for tracking your preferences, subscribing to a newsletter, or initiating other such actions. 

We also may periodically obtain information about you from affiliated entities, partners and other independent third-party sources and will add it to our database of information. For example, we may receive information about your interaction with advertisements on third party websites, including updated postal addresses and demographic information. 

We may use cookies, log files, web beacons, device identifiers, advertising identifiers and similar tracking technologies, including those from third-party service providers like Google Analytics, Google Tag Manager, HubSpot, and other cloud-based tools, to automatically collect your preferences, performance data, and information about your web usage when you visit the Services. For example, we may collect your IP address, device and Internet service provider information, Web browser details and the address of any referring website. This may include collecting geolocation signals from your IP address or device settings to determine your location so that we may operate and personalize the Services for you, including to provide more relevant ads and search results.  We may also collect information about your online activity, such as pages viewed and interactions with other users. The Services are not designed to recognize or respond to “do not track” signals received from browsers. You can control the information collected by such tracking technologies or be alerted when cookies are sent by adjusting the settings on your Internet browser or devices, but such adjustments may affect or disable certain functionality of the Services. You can learn more about Google Analytics and your options for opting out of interest-based Google ads at https://adssettings.google.com.  You can learn more about targeted ads and your ability to opt out of receiving interest-based ads at optout.aboutads.info and www.networkadvertising.org/choices.

How We Use Your Information
We process your personal information with your consent or as needed to provide you Services. We may also use your personal information to comply with legal obligations, operate our business, protect the vital interests of you, our customers, or the public, or for other legitimate interests of Computir as described in this Privacy Policy.

More specifically, we may use your personal information to: 
●	Optimize and improve the Services - We continually try to improve the Services based on the information and feedback we receive from you, including by optimizing the content on the Services.

●	Personalize the user experience - We may use your information to measure engagement with the Services, and to understand how you and our other users interact with and use the Services and other resources we provide.

●	Improve customer service - Your information helps us to more effectively develop the Services and respond to your support needs.

●	Process transactions - We may use the information you provide about yourself to fulfill your requests and product orders. We do not share this information with outside parties except to the extent necessary to provide the Services, fulfill orders, and related activities.

●	To send periodic emails - The email address you provide through our contact forms, will be used to send information and updates pertaining to the Services. It may also be used to respond to your inquiries or other requests. If you opt in to our mailing list, you may receive emails that include Computir news, updates, related product offerings and service information, and marketing material. If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or you may contact us via the contact information below.

How We Share Your Information
We share your information with our partners, service providers, contractors, agents and third party vendors as needed to fulfill Services. Please note that our partners may contact you as necessary to obtain additional information about you, facilitate any use of the Services, fulfill orders, or respond to a request you submit.

Third-party vendors who provide product, services or functions on our behalf may include business analytics companies, customer service vendors, communications service vendors, marketing vendors, delivery, parcel, and transportation vendors, and security vendors. We may also authorize third-party vendors to collect information on our behalf, including as necessary to operate features of the Services, facilitate the delivery of orders, or provide online advertising tailored to your interests. Third-party vendors have access to and may collect personal information only as needed to perform their functions, may only use personal information consistent with this Privacy Policy and other appropriate confidentiality and security measures, and are not permitted to share or use the information for any other purpose.

We also may share your information:
●	In response to subpoenas, court orders, or other legal process; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases we reserve the right to raise or waive any legal objection or right available to us.
●	When we believe it is appropriate to investigate, prevent, or take action regarding illegal or suspected illegal activities; to protect and defend the rights, interests, or safety of our company or the Services, our customers, or others; or in connection with our Terms of Service and other agreements.
●	In connection with a corporate transaction, such as a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.

Other than as set out above, we will attempt to notify you when your personal information will be shared with third parties.

Personal Information You Post in Public Areas. When you post a message in a Computir forum, chat room, review, or customer feedback, the information you post may be accessible to other users of the Services and the public. If you post personal information anywhere on the Services that is accessible to other users or the public, you are advised that such personal information can be read, collected, used, or disseminated by others and could be used to send you unsolicited information or for other purposes. Accordingly, you assume full responsibility for posting such information and agree that Computir is not responsible in any way for personal information you choose to post in these public areas.

Aggregated Information.  We may publish, share, distribute, or disclose personal information that has been aggregated with information from other users or otherwise de-identified in a manner that does not allow such data about you to be separated from the aggregate data and identified as originating from you, to third parties, including Computir partners, sponsors, and advertisers. Such information may help Computir identify and analyze training, demographic, and psychographic trends and information, and report to third parties how many people saw, visited, or clicked on certain content, areas of the Services, ads, or other materials. We may also use such data for research purposes.

How We Store and Secure Your Information
Personal information collected by Computir may be stored and processed in your region, in the United States, and in any other country where Computir or its affiliates, subsidiaries, or service providers operate facilities. Computir will retain your information as long as necessary for the purposes outlined in this Privacy Policy and for a commercially reasonable time thereafter for backup, archival, fraud prevention or detection or audit purposes, or as otherwise required by law.

Computir takes technological and organizational measures to protect your personal information against loss, theft, and unauthorized access, use, disclosure or modification. For example:
●	we transmit data over secured communication channels,
●	all personal information is stored by trusted third party providers (e.g., Stripe and AWS),
●	all systems used to provide the Services are password protected, and
●	all Services usage is restricted on a per-user basis on the principle of least privilege.

We rely on AWS’ security programs to protect personal information while stored in their respective controlled facilities.  For more information on AWS’ security practices and processes, please see https://aws.amazon.com/security/.
Computir complies with applicable data protection laws, including applicable security breach notification requirements.

Accessing and Updating Your Information
To the extent provided by the law of your jurisdiction, you may (i) have the right to access certain personal information we maintain about you and request details about how we process it, (ii) request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes, (iii) request that we update or correct inaccuracies in that information, (iv) object to our use of your personal information, (v) ask us to block or delete your personal information from our database, and (vi) request to download the information you have shared on the Services. You may make these requests and any other inquiries about this Privacy Policy by emailing privacy@computir.com,. Any such requests are subject to the protection of other individuals’ rights and applicable law. Additionally, to help protect your privacy and maintain security, we may take steps to verify your identity before granting you access to the information. To the extent permitted by applicable law, a charge may apply before we provide you with a copy of any of your personal information that we maintain.

Additional Privacy Information
Data Transfer. Our databases are currently located in the United States. Computir makes no claim that its Services are appropriate or lawful for use or access outside the United States. If you access the Services from outside the United States, you are consenting to the transfer of your personal information from your location to the United States. You are solely responsible for complying with all local laws, rules and regulations regarding online conduct and access to the Services. By sending us your information, you further consent to its storage within the United States.

Collection of Data from Children. The Services are not directed to children, and you may not use the Services or provide any personal information to Computir if you are under the age of 13 or if you are not old enough to consent to the processing of your personal information in your country. 

Your California Privacy Rights. California Civil Code Section 1798.83 permits our customers who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please e-mail us or write to us at the addresses below.

How to Contact Us. You can e-mail us at privacy@computir.com. Depending on where you are located, you may also have a right to complain to your local data protection regulator.
`

export default function Privacy() {
    return (
        <>
        <SEO
        title={'Privacy Policy'}
        description={'Page containing Computir Privacy Policy.'} />
        <pre style={{whiteSpace: 'pre-wrap', paddingLeft: '5%', paddingRight: '5%'}}>{privacyPolicyString}</pre>
        </>
    )
}
