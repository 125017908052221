import React, { Component } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
// import styled from 'styled-components';
import { styled } from '@mui/system';
import Loading from '../../components/Loading';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import { inject, observer } from 'mobx-react';
import Backdrop from '@mui/material/Backdrop';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import AlertDialog from '../../components/AlertDialog';
import { useHistory } from 'react-router-dom';
import openStripeCheckoutWithTags from '../../components/shared/openCheckoutWithTags';
import SEO from '../../components/shared/SEO';
import Referral from '../../components/Referral';
import CouponCode from '../../components/CouponCode';
import { parseEntryPrice } from '../billing/BillingCard';
import TooltipIcon from '../../components/TooltipIcon';

const UserInfo = styled('div')({
  color: 'text.primary',
  fontSize: 'medium',
  display: 'flex',
});

const ProfileTitle = styled('p')({
  color: 'text.primary',
  fontSize: 'small',
  display: 'inline',
});

const WarningText = styled('p')({
  color: 'text.primary',
  fontSize: 'small',
  display: 'inline',
});

const SendButtonDiv = styled('div')({
  paddingTop: '20px',
  textAlign: 'center',
});

function PaymentInfo(props) {
  if (props.paymentVerified === 'verified') {
    return (
      <>
        {props.portalActionState === 'done' ? (
          <Button
            disableRipple
            sx={{
              display: 'inline',
              background: 'none!important',
              border: 'none',
              padding: '0!important',
              color: 'text.primary',
              textDecoration: 'underline',
              cursor: 'pointer',
              textTransform: 'none',
            }}
            onClick={() => props.handleClickToPortal()}
          >
            Click here to open payment portal
          </Button>
        ) : (
          <Skeleton width={222} />
        )}
      </>
    );
  } else if (props.paymentVerified === 'not verified') {
    return (
      <>
        {props.checkoutActionState === 'done' ? (
          <div style={{ textAlign: 'left' }}>
            {/* <Button
              disableRipple
              sx={{
                display: 'inline',
                background: 'none!important',
                border: 'none',
                padding: '0!important',
                color: 'text.primary',
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => props.handleClickToCheckout()}
            >
              click here to add payment in portal
            </Button> */}
            <Typography color="red">
              Adding payment is currently disabled
            </Typography>
          </div>
        ) : (
          <Skeleton width={222} />
        )}
      </>
    );
  } else {
    return <Typography>Error with Payment Data</Typography>;
  }
}

/**
 *
 * @param {string} confirmationMessage - string to display in the backdrop confirmation message
 * @param {boolean} openState - whether this is open or not
 * @param {function} handleClose - function for what to do when closing
 * @returns
 */
const ConfirmationBackdrop = ({
  confirmationMessage,
  openState,
  handleClose,
}) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openState}
      onClick={handleClose}
    >
      <Box
        sx={{
          backgroundColor: 'text.secondary',
          display: 'inline-flex',
          padding: '10px',
        }}
      >
        <ErrorOutlineIcon />
        <Typography>&nbsp; {confirmationMessage}</Typography>
      </Box>
    </Backdrop>
  );
};

@inject('computersStore')
@observer
class DeleteUserButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backdropOpen: false,
    };
  }

  toggleConfirmationBackdrop() {
    this.setState({ backdropOpen: true });
  }

  closeConfirmationBackdropAndRefresh() {
    this.props.history.push('/');
    this.props.history.go(0);
  }

  async handleClick() {
    this.props.computersStore.token = this.props.getAccessTokenSilently();
    await this.props.computersStore.deleteUser();
    this.toggleConfirmationBackdrop();
  }

  render() {
    return (
      <>
        <ConfirmationBackdrop
          confirmationMessage="Your account has been deleted"
          openState={this.state.backdropOpen}
          handleClose={() => this.closeConfirmationBackdropAndRefresh()}
        />
        <SendButtonDiv>
          <AlertDialog
            buttonLabel="DELETE ACCOUNT"
            dialogTitle="Delete your user account?"
            dialogDescription="Are you sure you want to delete your user account? Any existing Computirs 
        will be terminated and you will not be able recover any of your data. If you have an existing
        subscription, it will be ended at the end of the current billing cycle month and you will receive
        a final invoice and charge to your card upon end of the billing month."
            deleteButtonLabel="Delete"
            color="error"
            handleDelete={() => this.handleClick()}
          />
        </SendButtonDiv>
      </>
    );
  }
}

@inject('computersStore')
@observer
class ResendEmailButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleClick() {
    this.props.computersStore.token = this.props.getAccessTokenSilently();
    this.props.computersStore.sendVerificationEmail();
    this.handleClose();
    this.handleToggle();
  }

  render() {
    return (
      <SendButtonDiv>
        <ConfirmationBackdrop
          confirmationMessage="Email sent!"
          openState={this.state.open}
          handleClose={() => this.handleClose()}
        />
        {!this.props.user.email_verified && (
          <AlertDialog
            buttonLabel={`RE-SEND VERIFICATION EMAIL TO ${this.props.user.email}`}
            dialogTitle="Please confirm email address below"
            dialogDescription={`This will re-send a verification email to ${this.props.user.email}. If
        this email address is correct and you need a new verification email, please click send below.`}
            deleteButtonLabel="Send"
            color="warning"
            handleDelete={() => this.handleClick()}
          />
        )}
      </SendButtonDiv>
    );
  }
}

@inject('stripeStore')
@observer
class ProfileInfo extends Component {
  // this is to handle if you navigate to /profile after already loading isPaymentVerified: it can/should load the url on mount
  async componentDidMount() {
    if (
      this.props.stripeStore.paymentVerified === 'not verified' &&
      this.props.stripeStore.actionState === 'pending'
    ) {
      await this.props.stripeStore.getStripeCheckoutUrl();
    } else if (
      this.props.stripeStore.paymentVerified === 'verified' &&
      this.props.stripeStore.portalActionState === 'pending'
    ) {
      await this.props.stripeStore.getStripePortalUrl();
    }
  }
  // this is to handle if you have a fresh load of /profile (reload page, etc.) - it has to check payment verified first, so then on component did update it will run this check
  async componentDidUpdate() {
    if (
      this.props.stripeStore.paymentVerified === 'not verified' &&
      this.props.stripeStore.actionState === 'pending'
    ) {
      await this.props.stripeStore.getStripeCheckoutUrl();
    } else if (
      this.props.stripeStore.paymentVerified === 'verified' &&
      this.props.stripeStore.portalActionState === 'pending'
    ) {
      await this.props.stripeStore.getStripePortalUrl();
    }
  }
  handleClickToPortal = (e) => {
    if (this.props.stripeStore.portalActionState === 'pending') {
      alert('Whoops - something went wrong, please try again...');
    } else {
      window.open(this.props.stripeStore.portalUrl);
    }
  };

  handleClickToCheckout = (e) => {
    if (this.props.stripeStore.actionState === 'pending') {
      alert('Whoops - something went wrong, please try again...');
    } else {
      openStripeCheckoutWithTags(this.props.stripeStore.url);
    }
  };

  render() {
    return (
      <>
        <Box
          sx={{
            flexGrow: 1,
            paddingTop: '20px',
            display: 'block',
            '& > :not(style)': {
              m: 1,
              backgroundColor: 'transparent',
              padding: '20px',
              border: '2',
              borderColor: 'white',
            },
          }}
        >
          <Paper variant="outlined">
            {this.props.isAuthenticated && (
              <Grid container spacing={2}>
                <Grid
                  item
                  alignItems="center"
                  justifyContent="center"
                  xs={6}
                  sm={'auto'}
                >
                  <ButtonBase>
                    <img
                      display={'block'}
                      src={this.props.user.picture}
                      alt={this.props.user.name}
                    />
                  </ButtonBase>
                </Grid>
                <Grid item xs={6} sm={'auto'} spacing={5} container>
                  <Grid item>
                    <ProfileTitle>Email:&nbsp;</ProfileTitle>
                    {this.props.user.email_verified ? (
                      <ProfileTitle>
                        <CheckCircleIcon fontSize="10px" color="success" />
                        &nbsp;verified
                      </ProfileTitle>
                    ) : (
                      <WarningText>
                        <DangerousIcon fontSize="10px" color="error" />
                        unverified!
                      </WarningText>
                    )}
                    <UserInfo>{this.props.user.email}</UserInfo>
                  </Grid>
                  <Grid item>
                    <ProfileTitle>Payment:&nbsp;</ProfileTitle>
                    {this.props.stripeStore.paymentVerified === 'verified' ? (
                      <ProfileTitle>
                        <CheckCircleIcon fontSize="10px" color="success" />
                        &nbsp;verified
                      </ProfileTitle>
                    ) : (
                      <WarningText>
                        <DangerousIcon fontSize="10px" color="error" />
                        unverified!
                      </WarningText>
                    )}
                    <UserInfo>
                      {this.props.stripeStore.paymentVerified ===
                      'loading...' ? (
                        <Skeleton
                          animation="wave"
                          sx={{ width: { xs: 100, sm: 291 } }}
                        />
                      ) : (
                        <PaymentInfo
                          user={this.props.user}
                          paymentVerified={
                            this.props.stripeStore.paymentVerified
                          }
                          handleClickToCheckout={this.handleClickToCheckout}
                          handleClickToPortal={this.handleClickToPortal}
                          checkoutActionState={
                            this.props.stripeStore.actionState
                          }
                          portalActionState={
                            this.props.stripeStore.portalActionState
                          }
                        />
                      )}
                    </UserInfo>
                  </Grid>
                  <Grid item>
                    {this.props.stripeStore.paymentVerified === 'verified' ? (
                      <>
                        <ProfileTitle>
                          Remaining Launch Deposit Credit:
                        </ProfileTitle>
                        <UserInfo>
                          {this.props.stripeStore.portalActionState !==
                          'done' ? (
                            <Skeleton
                              animation="wave"
                              sx={{ width: { xs: 50, sm: 100 } }}
                            />
                          ) : (
                            <>
                              $
                              {parseEntryPrice(
                                this.props.stripeStore.customerBalance
                                  .toString()
                                  .replace('-', ''),
                              )}
                              &nbsp;
                              <TooltipIcon tooltipText="Upon paying the Launch Deposit, you are credited that amount towards future invoice(s)" />
                            </>
                          )}
                        </UserInfo>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Box>
        <Box></Box>
      </>
    );
  }
}

const Profile = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  let history = useHistory();
  return (
    <>
      <SEO
        title={'Profile'}
        description={
          'Manage your Computir profile and adjust payment and billing.'
        }
      />
      <ResendEmailButton
        user={user}
        getAccessTokenSilently={getAccessTokenSilently}
      />
      <ProfileInfo
        user={user}
        isAuthenticated={isAuthenticated}
        getAccessTokenSilently={getAccessTokenSilently}
      />
      {/* <Referral
        user={user}
        isAuthenticated={isAuthenticated}
        getAccessTokenSilently={getAccessTokenSilently}
      />
      <CouponCode
        user={user}
        isAuthenticated={isAuthenticated}
        getAccessTokenSilently={getAccessTokenSilently}
      /> */}
      <DeleteUserButton
        user={user}
        getAccessTokenSilently={getAccessTokenSilently}
        history={history}
      />
    </>
  );
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
});
