import React from 'react';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';

function RequirementIndicator({ errorBool, displayText }) {
  if (errorBool) {
    return (
      <div style={{ display: 'flex' }}>
        <DangerousIcon fontSize="10px" color="error" />
        <Typography fontSize={'small'}>&nbsp;{displayText}</Typography>
      </div>
    );
  } else {
    return (
      <div style={{ display: 'flex' }}>
        <CheckCircleIcon fontSize="10px" color="success" />
        <Typography fontSize={'small'}>&nbsp;{displayText}</Typography>
      </div>
    );
  }
}
export default function PasswordCharacterRequirements({
  passwordLength,
  passwordUpperCaseIndicator,
  passwordLowerCaseIndicator,
  passwordNumberIndicator,
  passwordSpecialCharIndicator,
}) {
  const passwordChecks = [
    [passwordUpperCaseIndicator, 'Uppercase'],
    [passwordLowerCaseIndicator, 'Lowercase'],
    [passwordNumberIndicator, 'Number'],
    [passwordSpecialCharIndicator, 'Special Character (@ $ ! % * ? _ &)'],
  ].map((check) => (
    <>
      {check[0] === 'missing' ? (
        <RequirementIndicator
          key={'true' & check[1]}
          errorBool={true}
          displayText={check[1]}
        />
      ) : (
        <RequirementIndicator
          key={'false' & check[1]}
          errorBool={false}
          displayText={check[1]}
        />
      )}
    </>
  ));

  if (passwordLength > 0) {
    return (
      <>
        <div
          style={{
            display: 'grid',
            gridAutoColumns: 'min-content',
            gridTemplateColumns: '1fr 2fr',
          }}
        >
          {passwordChecks}
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
