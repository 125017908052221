import React, { Component } from 'react';
import { inject } from 'mobx-react';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { signInorUpStyle } from './Header';
import Snackbar from '@mui/material/Snackbar';

const textStyle = {
  width: '100%',
};

/**
 * To add a reason for signup, pass in a prop `reason` of type string that will be passed to signUp api as the reason
 */
@inject('marketingStore')
class NewsLetterSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      emailValidationError: false,
      openSnackbarMsg: false,
      subscribeButtonClicked: false,
    };
  }

  emailValidator(email) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({ emailValidationError: !re.test(email) });
    return email;
  }

  async signUpForNewsLetter() {
    this.setState({ subscribeButtonClicked: true });
    let signUpReason = 'general-interest';
    if (this.props.reason) {
      signUpReason = this.props.reason;
    }
    let response = await this.props.marketingStore.addEmailContact({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      reason: signUpReason,
    });
    if (response.status === 201) {
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        openSnackbarMsg: true,
      });
    }
  }

  handleCloseSnackbar = (event, reason) => {
    this.setState({
      openSnackbarMsg: false,
    });
    this.props.closeDialogFunc();
  };

  render() {
    return (
      <Container
        sx={{
          paddingTop: '5%',
          paddingBottom: '5%',
          maxWidth: { xs: '80%', md: '50%' },
          border: '1px solid',
          borderColor: 'text.primary',
          borderRadius: '7px',
          marginTop: '5%',
          marginBottom: '5%',
        }}
      >
        <Typography>
          {this.props.customHeader
            ? this.props.customHeader
            : 'Subscribe to keep up to date with our latest news and features'}
        </Typography>
        <TextField
          onChange={(e) => this.setState({ firstName: e.target.value })}
          value={this.state.firstName}
          sx={textStyle}
          required
          id="first-name-input"
          label="First Name"
          variant="standard"
        />
        <TextField
          onChange={(e) => this.setState({ lastName: e.target.value })}
          value={this.state.lastName}
          sx={textStyle}
          required
          id="last-name-input"
          label="Last Name"
          variant="standard"
        />
        <TextField
          onChange={(e) =>
            this.setState({ email: this.emailValidator(e.target.value) })
          }
          value={this.state.email}
          sx={textStyle}
          required
          id="email-input"
          label="Email"
          variant="standard"
          error={this.state.emailValidationError}
          helperText={
            !this.state.emailValidationError
              ? ''
              : 'please enter a valid email address'
          }
        />
        <Box textAlign="center">
          <Button
            onClick={() => this.signUpForNewsLetter()}
            variant={'contained'}
            sx={{ marginTop: '5%', ...signInorUpStyle }}
            disabled={
              this.state.firstName.length === 0 ||
              this.state.lastName.length === 0 ||
              this.state.email.length === 0 ||
              this.state.emailValidationError ||
              this.state.subscribeButtonClicked
            }
          >
            Subscribe
          </Button>
        </Box>
        <Snackbar
          open={this.state.openSnackbarMsg}
          autoHideDuration={8000}
          onClose={this.handleCloseSnackbar}
          message="You have been subscribed"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
      </Container>
      // </div>
    );
  }
}

export default NewsLetterSignUp;
