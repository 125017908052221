import axios from 'axios';

const generateBaseUrl = () => {
  if (process.env.REACT_APP_BRANCH === 'dev') {
    return 'https://zx18x89z5b.execute-api.us-east-1.amazonaws.com/dev'
  } else if (process.env.REACT_APP_BRANCH === 'master') {
    return 'https://3sahi82ro6.execute-api.us-east-1.amazonaws.com/prod'
  } else if (process.env.REACT_APP_BRANCH === 'local') {
    // return 'https://3sahi82ro6.execute-api.us-east-1.amazonaws.com/prod'
    // return 'https://zx18x89z5b.execute-api.us-east-1.amazonaws.com/dev'
    return process.env.REACT_APP_BASE_URL
  }
}

export default class BaseHttpService {
  // TODO - make different locations per environment
  // BASE_URL = process.env.REACT_APP_BASE_URL || 'https://6cfdfud6yi.execute-api.us-east-1.amazonaws.com/dev';
  BASE_URL = generateBaseUrl()
  _accessToken = null;

  constructor(routerStore) {
    this.routerStore = routerStore;
    this.token = ''
  }

  set accessToken(newToken) {
    this.token = newToken
  }

  async get(endpoint, options = {}) {
    Object.assign(options, this._getCommonOptions());
    return axios.get(`${this.BASE_URL}/${endpoint}`, options)
      .catch(error => this._handleHttpError(error));
  }

  async post(endpoint, data = {}, options = {}) {
    Object.assign(options, this._getCommonOptions());
    return axios.post(`${this.BASE_URL}/${endpoint}`, data, options)
      .catch(error => this._handleHttpError(error));
  }

  async delete(endpoint, options = {}) {
    Object.assign(options, this._getCommonOptions());
    return axios.delete(`${this.BASE_URL}/${endpoint}`, options)
      .catch(error => this._handleHttpError(error));
  }

  async patch(endpoint, data = {}, options = {}) {
    Object.assign(options, this._getCommonOptions());
    return axios.patch(`${this.BASE_URL}/${endpoint}`, data, options)
      .catch(error => this._handleHttpError(error));
  }

  _handleHttpError(error) {
    const { statusCode } = error.response.data;

    if (statusCode !== 401) {
      throw error;
    } else {
      return this._handle401();
    }
  }

  _handle401() {
    window.location = '/';
  }

  _getCommonOptions() {
    const token = this.token;

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  // getToken = async () => {
  //   const { getAccessTokenSilently } = this.props.auth0;
  //   const token = await getAccessTokenSilently();
  //   return token
  // }


}
