import React from 'react';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ComputirTitle from '../style/ComputirTitle';
import ThemeSelectorButton from '../style/ThemeSelectorButton';

function LegalLink(props) {
  let history = useHistory();
  return (
    <Button
      disableRipple={true}
      sx={{
        background: 'none!important',
        border: 'none',
        padding: '0!important',
        color: 'text.primary',
        textDecoration: props.underline,
        cursor: 'pointer',
        textTransform: 'none',
        fontSize: '9px',
        minWidth: '0px',
      }}
      onClick={() => {
        history.push(props.windowLocationHash);
      }}
    >
      {props.buttonText}
    </Button>
  );
}

export const LogoWithSocials = ({ theme, colorMode, themeSelector = true }) => {
  return (
    <Box>
      <Box>
        <Box
          sx={{
            marginTop: '20px',
            textAlign: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <ComputirTitle />
        </Box>
        <Box sx={{ marginBottom: '2%', textAlign: 'center' }}>
          <IconButton
            onClick={() => {
              window.open(
                'https://www.facebook.com/people/Computir/100089570655969/',
                '_blank',
              );
            }}
          >
            <FacebookIcon color={'secondary'} />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open('https://www.instagram.com/computir_inc/', '_blank');
            }}
          >
            <InstagramIcon color={'secondary'} />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open('https://twitter.com/computir_inc', '_blank');
            }}
          >
            <TwitterIcon color={'secondary'} />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open('https://www.reddit.com/r/computir/', '_blank');
            }}
          >
            <RedditIcon color={'secondary'} />
          </IconButton>
        </Box>
        {themeSelector ? (
          <ThemeSelectorButton theme={theme} colorMode={colorMode} />
        ) : (
          <></>
        )}
      </Box>
      <LegalLink
        buttonText="Legal Terms of Service"
        windowLocationHash="legal"
      />
      <Typography display={'inline'} sx={{ fontSize: '9px' }}>
        &nbsp;|&nbsp;
      </Typography>
      <LegalLink buttonText="Privacy Policy" windowLocationHash="privacy" />
    </Box>
  );
};

const Footer = ({ theme, colorMode }) => {
  return (
    <FooterContainer>
      <LogoAndThemeButtonContainer>
        <LogoWithSocials theme={theme} colorMode={colorMode} />
        <ColumnContainer>
          <Row>
            <Column>
              <Heading>Product</Heading>
              <FooterLink
                text={'Technical Specifications'}
                toLink={'specs'}
              ></FooterLink>
              <FooterLink text={'FAQ'} toLink={'faq'}></FooterLink>
              {/* <FooterLink text={'Testimonials'} toLink={'testimonials'}></FooterLink> */}
              <FooterLink
                text={'Terms of Service'}
                toLink={'legal'}
              ></FooterLink>
            </Column>
            <Column>
              <Heading>Company</Heading>
              <FooterLink text={'About Us'} toLink={'about'}></FooterLink>
              {/* <FooterLink text={'Blog'} toLink={'blog'}></FooterLink> */}
              <FooterLink text={'Careers'} toLink={'careers'}></FooterLink>
              <FooterLink
                text={'Privacy Policy'}
                toLink={'privacy'}
              ></FooterLink>
            </Column>
            <Column>
              <Heading>Contact</Heading>
              <FooterLink text={'Support'} toLink={'support'}></FooterLink>
            </Column>
          </Row>
        </ColumnContainer>
      </LogoAndThemeButtonContainer>
      <br />
    </FooterContainer>
  );
};
export default Footer;
// justify-content: space-between;
const LogoAndThemeButtonContainer = styled.div`
  display: flex;
`;

export const FooterContainer = styled.div`
  border-top: 3px solid #757c87;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0px 0px 0px 50px;

  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

// display: flex;
// flex-direction: column;
// justify-content: center;
// margin: 0 auto;
export const ColumnContainer = styled.div`
  padding-left: 10%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10%;
`;

export const Row = styled.div`
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const Heading = styled.p`
  font-size: 24px;
  color: text.primary;
  margin-bottom: 15%;
  font-weight: bold;
`;

/**
 *  @param {string} text
 *  @param {string} toLink
 */
export const FooterLink = (props) => {
  return <StyledLink href={`/${props.toLink}`}>{props.text}</StyledLink>;
};

const StyledLink = styled.a`
  margin-bottom: 5%;
  text-decoration: none;
  cursor: pointer;
  :hover {
    color: gray;
  }
  color: inherit;
`;

// export const FooterLink = styled.a`
//   color: #fff;
//   margin-bottom: 20px;
//   font-size: 18px;
//   text-decoration: none;
//   cursor: pointer;

//   &:hover {
//       color: green;
//       transition: 200ms ease-in;
//   }
// `;
