import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import StripeVerificationAlert from './StripeVerificationAlert';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';

const PerformanceWarning = ({ paymentVerified }) => {
  if (paymentVerified === 'loading...') {
    return <></>;
  } else {
    if (paymentVerified === 'not verified') {
      return <></>;
    } else {
      return (
        <Alert variant="filled" severity="warning">
          Due to the recent White House Executive Order, GPU inventories are at
          an all time low and new Computirs may not be able to be created.
        </Alert>
      );
    }
  }
};

const ErrorText = (props) => {
  let history = useHistory();
  if (props.isAuthenticated && !props.isLoading && !props.emailVerified) {
    return (
      //link to profile page
      <Alert variant="filled" severity="error">
        Please verify your email to use Computir. If you did not receive an
        email, please{' '}
        <Button
          sx={{
            background: 'none!important',
            border: 'none',
            padding: '0!important',
            color: 'white',
            textDecoration: 'underline',
            cursor: 'pointer',
            textTransform: 'none',
          }}
          onClick={() => {
            history.push('/profile');
          }}
        >
          visit your profile
        </Button>{' '}
        to confirm your email address is correct and re-send email.
      </Alert>
    );
  } else {
    return <div></div>;
  }
};

@inject('stripeStore', 'computersStore')
@observer
class EmailVerificationAlert extends Component {
  componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    if (this.props.stripeStore.paymentVerified === 'loading...') {
      try {
        const backendToken = getAccessTokenSilently();
        this.props.stripeStore.token = backendToken;
        this.props.stripeStore.getPaymentVerifiedStatus();
        this.props.computersStore.token = backendToken;
        // check for computirs if you're not on the computers page (because that page checks it already)
        if (this.props.location.pathname !== '/computers') {
          this.props.computersStore.fetchComputers();
        }
      } catch (e) {
        console.log('error here: ', e);
      }
    }
  }

  renderEmailVerificationAlert = () => {
    const { user, isLoading, isAuthenticated } = this.props.auth0;
    return (
      <>
        <PerformanceWarning
          paymentVerified={this.props.stripeStore.paymentVerified}
        />
        <ErrorText
          isAuthenticated={isAuthenticated}
          isLoading={isLoading}
          emailVerified={user.email_verified}
        />
        <StripeVerificationAlert
          paymentVerified={this.props.stripeStore.paymentVerified}
        />
      </>
    );
  };

  render() {
    return <>{this.renderEmailVerificationAlert()}</>;
  }
}

export default withAuthenticationRequired(
  withRouter(withAuth0(EmailVerificationAlert)),
);
