import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import styled from '@emotion/styled';
import ComputirTitle from '../../style/ComputirTitle';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { signInorUpStyle } from '../Header';
import loginWithTags from '../shared/loginWithTags';
import EmailVerificationAlert from '../EmailVerificationAlert';
import { TextLink } from '../TextButton';
import TextButton from '../TextButton';

const CreateButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5%;
`;

const TopFixedDiv = styled.div`
  position: sticky;
  width: 100%;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
`;

export default function MobileHeader() {
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();
  let history = useHistory();
  return (
    <TopFixedDiv>
      {isAuthenticated ? <EmailVerificationAlert /> : null}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '5%',
          backgroundColor: 'background.default',
        }}
      >
        <Button
          disableRipple={true}
          sx={{
            background: 'none!important',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            textTransform: 'none',
          }}
          onClick={() => {
            history.push('/');
          }}
        >
          <ComputirTitle />
        </Button>
        <CreateButtonContainer>
          {isLoading ? (
            <Skeleton width={'100px'} height={'50px'} />
          ) : isAuthenticated ? (
            <Button
              onClick={() => logout({ returnTo: window.location.origin })}
              variant={'contained'}
              sx={{
                color: 'text.primary',
                borderColor: 'text.secondary',
                backgroundColor: 'text.secondary',
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              Log Out
            </Button>
          ) : (
            <Button
              onClick={() => loginWithTags(loginWithRedirect)}
              variant={'contained'}
              sx={signInorUpStyle}
            >
              Create an Account
            </Button>
          )}

          {/* <Button onClick={() => loginWithRedirect()}
                variant={'contained'}
                sx={{ color: 'text.primary', borderColor: 'text.secondary', backgroundColor: 'text.secondary', '&:hover': { backgroundColor: 'transparent'} }}>
                Create an Account
          </Button> */}
        </CreateButtonContainer>
      </Box>
      <Box
        sx={{
          justifyContent: 'space-evenly',
          width: '100%',
          display: 'flex',
          backgroundColor: 'background.default',
        }}
      >
        <TextLink text="Home" toLink="/" />
        <TextLink text="FAQ" toLink="/faq" />
        {isAuthenticated ? (
          <>
            <TextLink text="Computirs" toLink="/computirs" />
            <TextLink text="Profile" toLink="/profile" />
            {/* <TextButton
              buttonText="Computirs"
              windowLocationHash="/computirs"
              color="white"
              titleFont={true}
            />
            <TextButton
              buttonText="Profile"
              windowLocationHash="/profile"
              color="white"
              titleFont={true}
            /> */}
          </>
        ) : (
          <>
            <TextLink text="About" toLink="/about" />
            <TextLink text="Pricing" toLink="/specs" />
          </>
        )}
      </Box>
    </TopFixedDiv>
  );
}
