import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withAuth0 } from '@auth0/auth0-react';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { signInorUpStyle } from './Header';
import Snackbar from '@mui/material/Snackbar';
import { Modal } from '@mui/material';
import { SmallLoading } from './Loading';

const textStyle = {
  width: '100%',
};

@inject('marketingStore', 'routerStore')
@observer
class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailValidationError: false,
      openSnackbarMsg: false,
      snackbarEmail: '',
      openSnackbarErrorMsg: false,
      detailsModalOpen: false,
      spendAmount: '(loading...)',
      referralDiscountPercentage: '(loading...)',
      refereeDiscountPercentage: '(loading...)',
      displayLoading: false,
    };
  }

  async componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    this.props.marketingStore.token = getAccessTokenSilently();
    const resp = await this.props.marketingStore.getReferralCodeParameters();
    const spendAmount = resp.spendAmount;
    const referralDiscountPercentage = resp.referralDiscountPercentage;
    const refereeDiscountPercentage = resp.refereeDiscountPercentage;
    this.setState({
      ...this.state,
      spendAmount,
      referralDiscountPercentage,
      refereeDiscountPercentage,
    });
  }

  emailValidator(email) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({ emailValidationError: !re.test(email) });
    return email;
  }

  async sendReferralCode() {
    const { getAccessTokenSilently } = this.props.auth0;
    this.props.marketingStore.token = getAccessTokenSilently();
    this.setState({ displayLoading: true });
    let response = await this.props.marketingStore.sendReferralCode({
      email: this.state.email,
    });
    if (response.status === 201) {
      this.setState({
        email: '',
        openSnackbarMsg: true,
        snackbarEmail: this.state.email,
        displayLoading: false,
      });
    } else {
      this.setState({
        openSnackbarErrorMsg: true,
        displayLoading: false,
      });
    }
  }

  handleCloseSnackbar = (event, reason) => {
    this.setState({
      openSnackbarMsg: false,
      snackbarEmail: '',
    });
  };

  handleCloseSnackbarError = (event, reason) => {
    this.setState({
      openSnackbarErrorMsg: false,
    });
  };

  handleOpenDetailsModal = () => {
    this.setState({ detailsModalOpen: true });
  };

  handleCloseDetailsModal = () => {
    this.setState({ detailsModalOpen: false });
  };

  render() {
    return (
      <Container
        sx={{
          paddingTop: '5%',
          paddingBottom: '5%',
          maxWidth: { xs: '80%', md: '50%' },
          border: '1px solid',
          borderColor: 'text.primary',
          borderRadius: '7px',
          marginTop: '5%',
          marginBottom: '5%',
        }}
      >
        <Typography>Refer a friend!</Typography>
        <Typography sx={{ paddingTop: '1%', paddingBottom: '1%' }}>
          With our referral program, you can invite your friends to join
          Computir and earn a discount on your next eligible invoice when they
          use Computir. See full details{' '}
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={this.handleOpenDetailsModal}
          >
            here
          </span>
          .
        </Typography>
        <TextField
          onChange={(e) =>
            this.setState({ email: this.emailValidator(e.target.value) })
          }
          value={this.state.email}
          sx={textStyle}
          required
          id="email-input"
          label="Email"
          variant="standard"
          error={this.state.emailValidationError}
          helperText={
            !this.state.emailValidationError
              ? ''
              : 'please enter a valid email address'
          }
        />
        <Box textAlign="center">
          <Button
            onClick={() => this.sendReferralCode()}
            variant={'contained'}
            sx={{ marginTop: '5%', ...signInorUpStyle }}
            disabled={
              this.state.email.length === 0 || this.state.emailValidationError
            }
          >
            Refer
          </Button>
          {this.state.displayLoading ? <SmallLoading /> : ''}
        </Box>
        <Snackbar
          open={this.state.openSnackbarMsg}
          autoHideDuration={10000}
          onClose={this.handleCloseSnackbar}
          message={`You have sent a referral to ${this.state.snackbarEmail}`}
        />
        <Snackbar
          open={this.state.openSnackbarErrorMsg}
          autoHideDuration={10000}
          onClose={this.handleCloseSnackbarError}
          message="Sorry - something went wrong please try again"
          color="red"
        />
        <Modal
          open={this.state.detailsModalOpen}
          onClose={this.handleCloseDetailsModal}
          aria-labelledby="referral-details-modal"
          aria-describedby="a-modal-to-detail-referral-conditions"
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#2C2C2C',
              boxShadow: 24,
              padding: '1.5% 4% 1.5% 4%',
              zIndex: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography>
              <Typography>Our referral program works by:</Typography>
              {/* TODO better li fix here... */}
              <List>
                <ListItem>
                  <ListItemText
                    primary={`1. sending an email to your friend (your email will be included in this message) with a promotion code for ${this.state.refereeDiscountPercentage}% off their first invoice`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`2. Once your friend redeems their code and spends over $${this.state.spendAmount}, a discount of ${this.state.referralDiscountPercentage}% will be automatically applied to your next eligible invoice`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="3. Only one referral discount can be applied at a time." />
                </ListItem>
              </List>
            </Typography>
          </div>
        </Modal>
      </Container>
    );
  }
}

export default withAuth0(Referral);
// export default Referral;
