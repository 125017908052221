import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({title, description, name, type}) {
  return (
    <Helmet>
      { /* standard metadata tags */ } 
      <title>{title}</title>
      <meta name='description' content={description} />
      { /* end standard metadata tags*/ }
      { type ?? 
        <>
        { /* Facebook tags */ }
        <meta property='og:type' content={type} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        { /* end Facebook tags */}
        { /* Twitter tags */}
        <meta property='twitter:Creator' content={name} />
        <meta property='twitter:card' content={type} />
        <meta property='twitter:title' content={title} />
        <meta property='twitter:description' content={description} />
        { /* end Twitter tags */}
        </>
      }

    </Helmet>
  )
}