import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TooltipIcon from './TooltipIcon';

// function formatDate(timestamp) {
//   const _date = new Date(timestamp);
//   const hours = _date.getHours().toString()
//   const minutes = "0"+_date.getMinutes().toString()
//   const seconds = "0"+_date.getSeconds().toString()

//   return hours + ':' + minutes.slice(-2) + ':' + seconds.slice(-2)
// }

function translateTimeToPercentage(time) {
  return 100 * (time / 60);
}

function getStartTime(timestampString) {
  return Date.parse(timestampString);
}

export function checkIfLessThanTwentyMinsSinceCreation(creationTime) {
  const startTimestamp = getStartTime(creationTime);
  const startPlusTwenty = startTimestamp + 30 * 60000;
  return startPlusTwenty > Date.now();
}

function CircleCounter({ progress, label }) {
  return (
    <div style={{ marginTop: '10%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <CircularProgress
          variant="determinate"
          value={translateTimeToPercentage(progress)}
          style={{}}
        />
        <Typography
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            margin: '0',
          }}
        >
          {progress}
        </Typography>
      </div>
      <Typography align="center" style={{ top: '50%' }}>
        {label}
      </Typography>
    </div>
  );
}

export default function EstimatedCountdown({ estimatedMinutes, startTime }) {
  const [seconds, setSeconds] = useState(59);
  const [minutes, setMinutes] = useState(estimatedMinutes - 1);

  useEffect(() => {
    // get start time to derive when end time is
    const startTimestamp = getStartTime(startTime);
    const estimatedEndTime = startTimestamp + estimatedMinutes * 60000;
    // get current time and calculate ms between them
    const currentTime = Date.now();
    const timeDifference = estimatedEndTime - currentTime;
    // convert that into minutes and seconds left
    const minutesLeft = Math.floor(timeDifference / 1000 / 60);
    const secondsLeft = Math.floor((timeDifference / 1000) % 60);
    if (secondsLeft < 0) {
      setMinutes(0);
      setSeconds(0);
    } else {
      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div style={{ display: 'inline-block', width: 'max-content' }}>
      <div style={{ display: 'flex' }}>
        <TooltipIcon
          placement="top"
          tooltipText="This is an estimate for how long it will take for configuration. We are currently installing drivers and quality of life enhancements on your Computir."
        />
        <Typography>&nbsp;Estimated time for configuration:</Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <CircleCounter progress={minutes} label={'min'} />
        <CircleCounter progress={seconds} label={'sec'} />
      </div>
    </div>
  );
}
