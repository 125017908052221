import React from 'react';
import Box from '@mui/material/Box';
import {
  Table,
  TableCell,
  TableRow,
  Typography,
  TableHead,
  TableBody,
} from '@mui/material';
import TooltipIcon from '../../components/TooltipIcon';
import { parseEntryPrice } from './BillingCard';

function calculateAppliedBalance(monthPrice, customerBalance) {
  if (Math.abs(customerBalance) >= Math.abs(monthPrice)) {
    return parseEntryPrice(monthPrice);
  } else if (monthPrice === 0) {
    return '0.00';
  } else if (Math.abs(customerBalance) === Math.abs(monthPrice)) {
    return parseEntryPrice(customerBalance);
  } else {
    return parseEntryPrice(customerBalance).toString().replace('-', '');
  }
}

export default function InvoicePreview({ currentInvoice, billingThreshold }) {
  const amountDue = currentInvoice.amountDue;
  const chargeDate = new Date(currentInvoice.billDate * 1000).toLocaleString(
    'en',
    { year: 'numeric', month: 'numeric', day: 'numeric' },
  );
  const customerBalance = currentInvoice.startingBalance;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'cardBackground.secondary',
          width: '60%',
          padding: '3%',
          marginTop: '2%',
          borderRadius: '10px',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Invoice from Computir Inc.
        </Typography>
        <Typography gutterBottom>
          Projected Date of Issue: {chargeDate}
        </Typography>
        <Typography gutterBottom>
          {' '}
          ${parseEntryPrice(amountDue)} USD due {chargeDate}{' '}
        </Typography>
        <br />
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Typography gutterBottom>
            {' '}
            Billing Threshold: ${billingThreshold}
          </Typography>
          <TooltipIcon tooltipText="You will be billed at the projected date of issue, or when your usage exceeds this threshold" />
        </div>
        {parseInt(customerBalance) !== 0 ? (
          <>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Typography gutterBottom>
                Current Launch Deposit credit: $
                {parseEntryPrice(customerBalance.toString().replace('-', ''))}
              </Typography>
              <TooltipIcon tooltipText="Upon paying the Launch Deposit, you are credited that amount towards future invoice(s)" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Typography gutterBottom>
                Launch Deposit credit after invoice: $
                {parseEntryPrice(
                  currentInvoice.endingBalance.toString().replace('-', ''),
                )}
              </Typography>
              <TooltipIcon tooltipText="This amount will persist after your invoice is issued" />
            </div>
          </>
        ) : (
          <></>
        )}
        <UsageTable currentInvoice={currentInvoice} />
      </Box>
    </>
  );
}

function couponAmountOff(coupon) {
  if (coupon.couponAmountOff != null) {
    return `$${parseEntryPrice(coupon.couponAmountOff)} off`;
  } else {
    return `${coupon.couponPercentOff}% off`;
  }
}

function renderCoupon(discount, coupons) {
  // find which coupon is being used on the invoice and render that coupon's details
  // it seems like multiple coupons can be used, so iterating through just in case
  // eslint-disable-next-line
  return coupons.map((coupon) => {
    if (discount.discount === coupon.couponId) {
      console.log('equal test');
      return (
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell>
            Coupon:{' '}
            <Typography
              style={{
                fontStyle: 'italic',
                display: 'inline',
                fontSize: 'small',
              }}
            >
              {coupon.couponName} ({couponAmountOff(coupon)})
            </Typography>
          </TableCell>
          <TableCell align="right">
            -${parseEntryPrice(discount.amount)}
          </TableCell>
        </TableRow>
      );
    }
  });
}

function UsageTable({ currentInvoice }) {
  console.log(currentInvoice);
  const endDate = new Date(currentInvoice.billDate * 1000).toLocaleString(
    'en',
    { year: 'numeric', month: 'numeric', day: 'numeric' },
  );
  const startDate = new Date(currentInvoice.periodStart * 1000).toLocaleString(
    'en',
    { year: 'numeric', month: 'numeric', day: 'numeric' },
  );
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell align="right">Qty</TableCell>
          <TableCell align="right">Unit price</TableCell>
          <TableCell align="right">Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ padding: '1% 0px 0px 2%', borderBottom: 'none' }}>
            <Typography
              style={{
                fontStyle: 'italic',
                display: 'inline',
                fontSize: 'small',
              }}
            >
              {startDate} - {endDate}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Computir Usage</TableCell>
          <TableCell align="right">{currentInvoice.subtotal}</TableCell>
          <TableCell align="right">$0.01</TableCell>
          <TableCell align="right">
            ${parseEntryPrice(currentInvoice.subtotal)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell>Subtotal</TableCell>
          <TableCell align="right">
            ${parseEntryPrice(currentInvoice.subtotal)}
          </TableCell>
        </TableRow>
        {currentInvoice.totalDiscountAmounts.map((discount) => {
          return renderCoupon(discount, currentInvoice.coupons);
        })}
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell>Total</TableCell>
          <TableCell align="right">
            ${parseEntryPrice(currentInvoice.total)}
          </TableCell>
        </TableRow>
        {parseInt(currentInvoice.startingBalance) !== 0 &&
        parseInt(currentInvoice.total) !== 0 ? (
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell>Launch Deposit credit</TableCell>
            <TableCell align="right">
              -$
              {calculateAppliedBalance(
                currentInvoice.total,
                currentInvoice.startingBalance - currentInvoice.endingBalance,
              )}
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell>Amount Due</TableCell>
          <TableCell align="right">
            ${parseEntryPrice(currentInvoice.amountDue)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

// <List style={{ width: '100%' }}>
//           <ListItem>
//             <ListItemText primary="Summary" />
//           </ListItem>
//           {/* <Divider /> */}

//         </List>
