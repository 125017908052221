import React from 'react';
import PageTitle from '../../components/shared/PageTitle';
import { ComputersContainer } from '../../style/ComputersContainer';
import SwipeableTextMobileStepper from '../faq/ImageCarousel';
import { howToCreateAComputer } from '../faq/FaqPage';
import SEO from '../../components/shared/SEO';
import LaunchDeposit from './LaunchDeposit';

export default function GettingStarted() {
  return (
    <>
      <SEO
        title={'Getting Started'}
        description={
          'Quickstart guide for getting up and running with your GPU cloud gaming computer.'
        }
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <PageTitle title={'Getting Started'} />
        <br />
        <SwipeableTextMobileStepper images={howToCreateAComputer} />
        <br />
        <LaunchDeposit />
      </div>
    </>
  );
}
