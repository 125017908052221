import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TooltipIcon from '../../components/TooltipIcon';
import Table from '@mui/material/Table';
import { COMPUTIR_TYPES } from '../create-computer/computirTypes';

// function calculateUsageCost(usageMinutes, computerType) {
//   const costMapping = {
//     'Computir Lite': 0.25,
//     'Computir Pro': 1.99
//   }
//   const rawCost = costMapping[computerType]/60 * usageMinutes
//   return Math.round(rawCost * 100)/100
// }
// shape of details:
// details = [{computerName: string, type: string, time: number}]

// function getKeyByValue(object, value) {
//   return Object.keys(object).find((key) => object[key] === value);
// }

function renderPrice(computirTypeDisplay) {
  let computirTypeKey;
  for (const [key, value] of Object.entries(COMPUTIR_TYPES)) {
    if (value['displayName'] === computirTypeDisplay) {
      computirTypeKey = key;
    }
  }
  return COMPUTIR_TYPES[computirTypeKey]['price'];
}

function renderUsageEntries(usageEntryList) {
  return (
    <Table
      sx={{ minWidth: 650 }}
      size="small"
      aria-label="billing usage table per month"
    >
      <TableHead>
        <TableRow key={'head'}>
          <TableCell>Computir Name</TableCell>
          <TableCell>Computir Type</TableCell>
          <TableCell>Cost per Hour (Running)</TableCell>
          <TableCell>Total Running Time (minutes)</TableCell>
          <TableCell>Cost per Hour (Stopped)</TableCell>
          <TableCell>Total Stopped Time (minutes)</TableCell>
          <TableCell>Total Cost</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {usageEntryList.map((entry) => (
          <TableRow key={entry.computerName}>
            <TableCell>{entry.computerName}</TableCell>
            <TableCell>{entry.computer_type}</TableCell>
            <TableCell>{renderPrice(entry.computer_type)}</TableCell>
            <TableCell>{entry.running}</TableCell>
            <TableCell>$0.03</TableCell>
            <TableCell>{entry.stopped}</TableCell>
            <TableCell>${parseEntryPrice(entry.usage_cost)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

// function parseDate(d) {
//   const monthToName = {
//     1: 'January',
//     2: 'February',
//     3: 'March',
//     4: 'April',
//     5: 'May',
//     6: 'June',
//     7: 'July',
//     8: 'August',
//     9: 'September',
//     10: 'October',
//     11: 'November',
//     12: 'December',
//   };
//   const year = d.slice(-4);
//   // todo fix this in the backend
//   let monthInt;
//   if (d.length === 5) {
//     monthInt = d.slice(0, 1);
//   } else {
//     monthInt = d.slice(0, 2);
//   }
//   const monthName = monthToName[monthInt];
//   return `${monthName} ${year}`;
// }

export function parseEntryPrice(usageCost) {
  const stringPrice = usageCost.toString();
  if (stringPrice.length === 1) {
    return '0.0' + stringPrice;
  }
  if (stringPrice.length === 2) {
    return '0.' + stringPrice;
  }
  if (stringPrice.length >= 3) {
    return (
      stringPrice.substring(0, stringPrice.length - 2) +
      '.' +
      stringPrice.substring(stringPrice.length - 2)
    );
  }
}

export function parseMonthPrice(monthPrice) {
  if (parseInt(monthPrice) < 50) {
    return '0.50';
  } else {
    const stringPrice = monthPrice.toString();
    if (stringPrice.length === 1) {
      return '0.0' + stringPrice;
    }
    if (stringPrice.length === 2) {
      return '0.' + stringPrice;
    }
    if (stringPrice.length >= 3) {
      return (
        // here is the issue
        stringPrice.substring(0, stringPrice.length - 2) +
        '.' +
        stringPrice.substring(stringPrice.length - 2)
      );
    }
  }
}

export default function BillingCard({
  monthYear,
  monthPrice,
  usageEntryList,
  defaultExpanded,
}) {
  return (
    <Accordion
      defaultExpanded={defaultExpanded === true ? true : false}
      key={monthYear}
      sx={{
        borderRadius: '10px',
        marginTop: '1%',
        backgroundColor: 'cardBackground.secondary',
        width: '80%',
        '&::before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${monthYear}a-content`}
        id={`panel${monthYear}a-header`}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              border: 'solid',
              borderWidth: 'thin',
              padding: '1%',
              whiteSpace: 'nowrap',
            }}
          >
            {monthYear}
          </Box>
          <Typography sx={{ whiteSpace: 'nowrap' }}>
            {' '}
            &nbsp;Total Month Charge: ${parseMonthPrice(monthPrice)}&nbsp;
          </Typography>
          {parseInt(monthPrice) < 50 ? (
            <TooltipIcon
              placement="top"
              tooltipText="The Computir billing minimum is $0.50, please see FAQ for more details"
              color="text.secondary"
              infoSize="small"
            />
          ) : (
            <></>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{renderUsageEntries(usageEntryList)}</AccordionDetails>
    </Accordion>
  );
}
