import React from 'react';
import styled from 'styled-components';
import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Divider from '@mui/material/Divider';
import { useHistory } from 'react-router-dom';
import EmailVerificationAlert from './EmailVerificationAlert';
import { TextLink } from './TextButton';
import ComputirTitle from '../style/ComputirTitle';
import { Skeleton } from '@mui/material';
import loginWithTags from './shared/loginWithTags';

export const signInorUpStyle = {
  color: 'text.primary',
  borderColor: 'text.secondary',
  backgroundColor: 'text.secondary',
  display: 'flex',
  '&:hover': { backgroundColor: 'transparent' },
  fontFamily: 'Montserrat !important',
};

export default function CommonHeader() {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigateClick = (navHash) => {
    if (history.location.pathname === navHash) {
      handleClose();
    } else {
      history.push(navHash);
      handleClose();
    }
  };
  const { isAuthenticated, logout, loginWithRedirect, isLoading, user } =
    useAuth0();

  return (
    <TopFixedDiv>
      {isAuthenticated ? <EmailVerificationAlert /> : null}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          borderBottom: '3px solid #757c87',
          padding: '0px 30px 0px 30px',
        }}
        bgcolor={'background.header'}
      >
        <Button
          disableRipple={true}
          sx={{
            background: 'none!important',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            textTransform: 'none',
          }}
          onClick={() => {
            history.push('/');
          }}
        >
          <ComputirTitle />
        </Button>
        <>
          <CreateButtonContainer>
            <InfoContainer>
              <TextLink text={'Home'} toLink={'/'} />
              <TextLink text={'FAQ'} toLink={'/faq'} />
              <TextLink text={'Pricing'} toLink={'/specs'} />
              <TextLink text={'Getting Started'} toLink={'/gettingstarted'} />
              {isAuthenticated ? (
                <TextLink text={'Computirs'} toLink={'/computirs'} />
              ) : (
                <TextLink text={'About'} toLink={'/about'} />
              )}
            </InfoContainer>
            {history.location.pathname === '/create' ? (
              <Button
                onClick={() => {
                  history.push('/computirs');
                }}
                sx={{ color: 'white', background: 'black' }}
              >
                <ArrowBackIosNewIcon />
                Cancel
              </Button>
            ) : (
              isAuthenticated && (
                <Button
                  onClick={() => {
                    history.push('/create');
                  }}
                  sx={{ color: 'white', background: 'black' }}
                >
                  <Add />
                  Create Computir
                </Button>
              )
            )}
            {isLoading ? (
              <Skeleton width={'300px'} />
            ) : isAuthenticated ? (
              <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                color={'primary'}
                // disableRipple
                size="small"
                sx={{
                  borderRadius: '50%',
                  minWidth: '36px',
                  height: '36px',
                  padding: '0',
                  marginLeft: '2%',
                }}
                // backgroundImage={user.picture}
              >
                {/* Menu */}
                <img
                  display={'block'}
                  src={user.picture}
                  alt={user.name}
                  width="36"
                  height="36"
                  style={{ borderRadius: '50%' }}
                />
              </Button>
            ) : (
              <div>
                <Button
                  onClick={() => loginWithTags(loginWithRedirect)}
                  variant={'contained'}
                  sx={signInorUpStyle}
                >
                  Sign in or sign up
                </Button>
              </div>
            )}
          </CreateButtonContainer>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={() => {
                handleNavigateClick('/');
              }}
            >
              {' '}
              Home{' '}
            </MenuItem>

            {isAuthenticated && (
              <MenuItem
                onClick={() => {
                  handleNavigateClick('/computirs');
                }}
              >
                Computirs
              </MenuItem>
            )}
            {isAuthenticated && (
              <MenuItem
                onClick={() => {
                  handleNavigateClick('/billing');
                }}
              >
                Billing
              </MenuItem>
            )}
            {isAuthenticated && (
              <MenuItem
                onClick={() => {
                  handleNavigateClick('/faq');
                }}
              >
                FAQ
              </MenuItem>
            )}
            {<Divider />}
            {isAuthenticated && (
              <MenuItem
                onClick={() => {
                  handleNavigateClick('/profile');
                }}
              >
                Profile
              </MenuItem>
            )}
            {isAuthenticated ? (
              <MenuItem
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Logout
              </MenuItem>
            ) : (
              <MenuItem onClick={() => loginWithTags(loginWithRedirect)}>
                Login
              </MenuItem>
            )}
          </Menu>
        </>
      </Box>
    </TopFixedDiv>
  );
}

const TopFixedDiv = styled.div`
  position: sticky;
  width: 100%;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
`;

// const ComputersHeader = styled.div`
//   display: flex;
//   justify-content: center;
//   border-bottom: 3px solid #757c87;
//   padding: 0px 30px 0px 30px;
// `;

const CreateButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const InfoContainer = styled.div`
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 20px;
`;
