import { observable, action } from 'mobx';

export default class ComputersStore {
  @observable computers = [];
  @observable actionState = 'pending';
  @observable errorState = false;

  constructor(computersService) {
    this.computersService = computersService;
    this.token = '';
  }

  async waitForToken() {
    let returnToken;
    await Promise.resolve(this.token).then(function (value) {
      returnToken = value;
    });
    return returnToken;
  }

  @action
  async fetchComputers() {
    // TODO improve this error handling probably
    this.actionState = 'fetching';
    this.computersService.accessToken = await this.waitForToken();
    try {
      const result = await this.computersService.fetchComputers();
      if (result) {
        console.log(result);
        this.computers = result.data.all_instances;
        this.actionState = 'done';
      }
    } catch (e) {
      if (e.response.status === 408) {
        if (this.computers.length !== 0) {
          this.actionState = 'done';
        } else {
          // TODO - potentially handle this differently
          console.log('refetching');
          this.fetchComputers();
        }
      } else {
        this.actionState = 'done';
        this.errorState = true;
      }
    }
    this.computersService.accessToken = '';
  }

  @action
  async createComputer(
    computerName,
    computerType,
    computerStorage,
    selectedRegion,
    computerPassword,
  ) {
    this.computersService.accessToken = await this.waitForToken();
    const creationFlag = await this.computersService.createComputer(
      computerName,
      computerType,
      computerStorage,
      selectedRegion,
      computerPassword,
    );
    window.localStorage.setItem(
      creationFlag.data.instance_name,
      creationFlag.data.created_time,
    );
    this.computersService.accessToken = '';
    return creationFlag.data;
  }

  @action
  async terminateComputer(computer_name, status) {
    this.actionState = 'pending';
    this.computersService.accessToken = await this.waitForToken();
    const computer = this.computers.find(
      (computer) => computer.computer_name === computer_name,
    );
    const newStatus = await this.computersService.terminateComputer(
      computer,
      status,
    );
    computer.status = newStatus.data;
    this.actionState = 'done';
    this.computersService.accessToken = '';
  }

  @action
  async updateComputerStatus(computer_name, status) {
    this.actionState = 'pending';
    this.computersService.accessToken = await this.waitForToken();
    const computer = this.computers.find(
      (computer) => computer.computer_name === computer_name,
    );
    const newStatus = await this.computersService.updateComputerStatus(
      computer,
      status,
    );
    computer.status = newStatus.data;
    this.actionState = 'done';
    this.computersService.accessToken = '';
  }

  @action
  async fetchComputerPassword(computer_name) {
    this.actionState = 'pending';
    this.computersService.accessToken = await this.waitForToken();
    const computer = this.computers.find(
      (computer) => computer.computer_name === computer_name,
    );
    const computerPassword = await this.computersService.fetchComputerPassword(
      computer,
    );
    computer.computerPassword = computerPassword.data;
    this.actionState = 'done';
    this.computersService.accessToken = '';
  }

  @action
  async getComputerPassword(computer_name) {
    this.actionState = 'pending';
    this.computersService.accessToken = await this.waitForToken();
    const computer = this.computers.find(
      (computer) => computer.computer_name === computer_name,
    );
    const computerPassword = await this.computersService.fetchComputerPassword(
      computer,
    );
    this.actionState = 'done';
    this.computersService.accessToken = '';
    return computerPassword;
  }

  @action
  async getRdpUrl(computer_name, computerPassword) {
    this.actionState = 'pending';
    this.computersService.accessToken = await this.waitForToken();
    const computer = this.computers.find(
      (computer) => computer.computer_name === computer_name,
    );
    // const computerPassword = await this.computersService.fetchComputerPassword(computer);
    const rdpUrl = await this.computersService.fetchRdpUrl(
      computer,
      computerPassword,
    );
    this.actionState = 'done';
    this.computersService.accessToken = '';
    return rdpUrl;
  }

  @action
  async clearPassword(computer_name) {
    const computer = this.computers.find(
      (computer) => computer.computer_name === computer_name,
    );
    computer.computerPassword = null;
    this.computersService.accessToken = '';
  }

  // TODO - potentially refactor this into a different service (auth service?)
  // my thinking for not doing this is it's all going to the nest backend endpoint, so group by that
  @action
  async sendVerificationEmail() {
    this.computersService.accessToken = await this.waitForToken();
    const email = await this.computersService.sendVerificationEmail();
    this.computersService.accessToken = '';
    return email;
  }
  // TODO - definitely refactor this as above!
  @action
  async deleteUser() {
    this.computersService.accessToken = await this.waitForToken();
    await this.computersService.deleteUser();
    this.computersService.accessToken = '';
  }
}
