import React from 'react';
import Grid from '@mui/material/Grid';
import { LogoWithSocials } from '../Footer';
import { TextLink } from '../TextButton';

function PaddedNavButton({ color, windowLocationHash, buttonText }) {
  return (
    <div style={{ padding: '2% 2% 10% 2%' }}>
      <TextLink toLink={windowLocationHash} text={buttonText} />
    </div>
  );
}

function MobileNavButtonGrid() {
  const buttonData = [
    {
      color: 'text.primary',
      windowLocationHash: '/',
      buttonText: 'Home',
    },
    {
      color: 'text.primary',
      windowLocationHash: 'faq',
      buttonText: 'FAQ',
    },
    {
      color: 'text.primary',
      windowLocationHash: 'specs',
      buttonText: 'Pricing',
    },
    {
      color: 'text.primary',
      windowLocationHash: 'about',
      buttonText: 'About',
    },
    // {
    //   color: 'text.primary',
    //   windowLocationHash: 'privacy',
    //   buttonText: 'Privacy Policy',
    // },
    // {
    //   color: 'text.primary',
    //   windowLocationHash: 'legal',
    //   buttonText: 'Terms & Conditions',
    // },
  ];
  return (
    <Grid container>
      {buttonData.map((item) => (
        <Grid item xs={6}>
          <PaddedNavButton
            key={item.buttonText}
            color={item.color}
            windowLocationHash={item.windowLocationHash}
            buttonText={item.buttonText}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default function MobileFooter() {
  return (
    <Grid container spacing={2} style={{ paddingBottom: '10%' }}>
      <Grid item xs={6} sx={{ textAlign: 'center' }}>
        <LogoWithSocials themeSelector={false} />
      </Grid>
      <Grid
        item
        xs={6}
        style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}
      >
        <MobileNavButtonGrid />
      </Grid>
    </Grid>
  );
}
