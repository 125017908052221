import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from './Loading';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { withRouter } from 'react-router';
import openStripeCheckoutWithTags from './shared/openCheckoutWithTags';
import NewsLetterSignUp from './NewsLetterSignUp';
import { Dialog, DialogContent, DialogTitle, Skeleton } from '@mui/material';

@inject('stripeStore', 'routerStore')
@observer
class StripeVerificationAlert extends Component {
  constructor(props) {
    super(props);
    this.state = { interestSignUpOpen: false };
    this.handler = this.handler.bind(this);
  }
  handler() {
    this.setState({ interestSignUpOpen: false });
  }
  async componentDidUpdate() {
    if (
      this.props.stripeStore.paymentVerified === 'not verified' &&
      this.props.stripeStore.actionState === 'pending' &&
      (this.props.location.pathname !== '/profile' ||
        this.props.location.pathname !== '/billing')
    ) {
      await this.props.stripeStore.getStripeCheckoutUrl();
    }
  }
  handleClick = (e) => {
    if (this.props.stripeStore.actionState === 'pending') {
      alert('Whoops - something went wrong, please try again...');
    } else {
      openStripeCheckoutWithTags(this.props.stripeStore.url);
    }
  };
  handleInterestClick = (e) => {
    this.setState((prevState) => ({
      interestSignUpOpen: !prevState.interestSignUpOpen,
    }));
  };

  render() {
    const { user } = this.props.auth0;
    if (user.email_verified) {
      if (this.props.paymentVerified === 'not verified') {
        return (
          //link to profile page
          <Alert variant="filled" severity="error">
            A payment method is required to use Computir. Due to high demand and
            low inventory, we are not accepting new users at this time.
            <br />
            If you would like to be notified when inventory has been
            restored,&nbsp;
            <Button
              disableRipple
              sx={{
                background: 'none!important',
                border: 'none',
                padding: '0!important',
                color: 'white',
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => this.handleInterestClick()}
            >
              click here
            </Button>
            <Dialog
              open={this.state.interestSignUpOpen}
              onClose={this.handleInterestClick}
            >
              <DialogTitle>
                Sign up to be notified when it's time to game!
              </DialogTitle>
              <DialogContent>
                <NewsLetterSignUp
                  customHeader="Subscribe to be alerted when Computir is available"
                  reason="2024whiteHouseOrderShortage"
                  closeDialogFunc={this.handler}
                />
              </DialogContent>
            </Dialog>
          </Alert>
        );
      } else {
        return null;
      }
    } else if (!user.email_verified) {
      return (
        <Alert variant="filled" severity="error">
          Please add payment method to use Computir. You must first verify your
          email.
        </Alert>
      );
    }
  }

  // old sign up header - re-implement this when AWS or another provider is online
  // render() {
  //   const { user } = this.props.auth0;
  //   if (user.email_verified) {
  //     if (this.props.paymentVerified === 'not verified') {
  //       return (
  //         //link to profile page
  //         <Alert variant="filled" severity="error">
  //           Please add payment method to use Computir.&nbsp;
  //           <Button
  //             disableRipple
  //             sx={{
  //               background: 'none!important',
  //               border: 'none',
  //               padding: '0!important',
  //               color: 'white',
  //               textDecoration: 'underline',
  //               cursor: 'pointer',
  //               textTransform: 'none',
  //             }}
  //             onClick={() => {
  //               this.props.history.push('/profile');
  //             }}
  //           >
  //             Visit your profile
  //           </Button>
  //           &nbsp;or&nbsp;
  //           {/* make this disabled (and visibly disabled) while it pulls the url */}
  //           {this.props.stripeStore.actionState === 'done' ? (
  //             <Button
  //               disableRipple
  //               sx={{
  //                 background: 'none!important',
  //                 border: 'none',
  //                 padding: '0!important',
  //                 color: 'white',
  //                 textDecoration: 'underline',
  //                 cursor: 'pointer',
  //                 textTransform: 'none',
  //               }}
  //               onClick={() => this.handleClick()}
  //             >
  //               click here
  //             </Button>
  //           ) : (
  //             <Skeleton width={90} style={{ display: 'inline-flex' }} />
  //           )}
  //         </Alert>
  //       );
  //     } else {
  //       return null;
  //     }
  //   } else if (!user.email_verified) {
  //     return (
  //       <Alert variant="filled" severity="error">
  //         Please add payment method to use Computir. You must first verify your
  //         email.
  //       </Alert>
  //     );
  //   }
  // }
}

export default withAuthenticationRequired(
  withRouter(withAuth0(StripeVerificationAlert)),
  {
    onRedirecting: () => <Loading />,
  },
);
