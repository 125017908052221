import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { ParagraphBox } from '../../components/ParagraphBox';
import SEO from '../../components/shared/SEO';
import { Typography } from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy';

const supportParagraph = "Please contact us with any questions or inquiries regarding our service. Computir offers quick and efficient customer support via email correspondence:"

function CenteredEmailAddress({copyFunction}) {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Button startIcon={<ContentCopy fontSize='inherit' />} sx={{textTransform: 'none'}} onClick={copyFunction}>
                <Typography align='center' variant='h5' color={'primary.main'} sx={{cursor: 'pointer'}}>
                    support@computir.com
                </Typography>
            </Button>
        </Box>
    )
}

export default function SupportPage() {
    const [openSnackbarMsg, setOpenSnackbarMsg] = useState(false);
    
    const handleCloseSnackbar = (event, reason) => {
        setOpenSnackbarMsg(false);
    };
    
    const handleCopyPassword = () => {
        navigator.clipboard.writeText('support@computir.com');
        setOpenSnackbarMsg(true);
    };
    
    return (
    <>
      <SEO
      title={'Support'}
      description={'Computir offers comprehensive support via email for quick and efficient resolution of questions and inquiries about service or billing.'} />
      <ParagraphBox title="Support" paragraph={supportParagraph} />
      <CenteredEmailAddress copyFunction={handleCopyPassword}/>
      <Snackbar
        open={openSnackbarMsg}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Copied support@computir.com to clipboard!"
        variant="success"
        ContentProps={{sx: {background: '#4BB543'}}}
        sx={{background: '#4BB543'}}
        />
    <br /> <br />
      <Divider variant="middle" sx={{ position: "relative", left: "20%", width: "60%", borderColor: 'text.primary', marginTop: "2%" }} />
    </>
    )
}