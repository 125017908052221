import { Skeleton } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  max-width: 480px;
  padding: 30px;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 20px;
  border: 1px solid white;
`;

export default function CreateNotAllowed() {
  return (
    <div>
      <FormContainer>
        <h1>Computir Limit Reached</h1>
        <p>
          During our Beta phase, only one Computir per user can be created at a
          time.
        </p>
        <p>
          To create a new Computir, please terminate your existing Computir.
        </p>
      </FormContainer>
    </div>
  );
}

export function CreateErrorMessage() {
  return (
    <div>
      <FormContainer>
        <h1>There was a temporary error</h1>
        <p>Please refresh the page while we investigate.</p>
      </FormContainer>
    </div>
  );
}

export function CreateBoxSkeleton() {
  return (
    <div>
      <FormContainer>
        <Skeleton height={'50px'} />
        <Skeleton width={'75%'} />
        <br />
        <Skeleton width={'50%'} />
        <Skeleton width={'50%'} />
      </FormContainer>
    </div>
  );
}
