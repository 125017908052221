import { observable, action } from 'mobx';

// function calculateTotalMonthCharge(totalMonthCost) {
//   if (totalMonthCost < 0.50) {
//     return 0.50
//   } else {
//     return totalMonthCost
//   }
// }

/**
 * function to sort bills
 */
function sortBills(a, b) {
  return b.monthYear.localeCompare(a.monthYear);
}

export default class BillingStore {
  @observable bills = [];
  @observable customerBalance = 0;
  @observable actionState = 'pending';
  @observable currentInvoice = {};
  @observable billingThreshold = 500;
  @observable filters = { status: '', search: '' };

  constructor(billingService) {
    this.billingService = billingService;
    this.token = '';
  }

  async waitForToken() {
    let returnToken;
    await Promise.resolve(this.token).then(function (value) {
      returnToken = value;
    });
    return returnToken;
  }

  updateFilters({ status, search }) {
    this.filters.status = status;
    this.filters.search = search;
    this.fetchBills();
  }

  @action
  resetTasks() {
    this.bills = [];
  }

  @action
  async fetchBills() {
    this.billingService.accessToken = await this.waitForToken();
    const result = await this.billingService.fetchBills();
    if (result && this.actionState === 'pending') {
      for (let i = 0; i < Object.keys(result.data.usageRecords).length; i++) {
        let monthYear = Object.keys(result.data.usageRecords)[i];
        let monthlyUsage = result.data.usageRecords[monthYear];
        let totalMonthCost = monthlyUsage['month_price'];
        let computerNames = Object.keys(monthlyUsage).filter(
          (j) => j !== 'month_price',
        );
        let monthDetails = {
          monthYear: monthYear,
          monthPrice: totalMonthCost,
          details: [],
        };
        for (let k = 0; k < computerNames.length; k++) {
          let computerDetails = monthlyUsage[computerNames[k]];
          computerDetails['computerName'] = computerNames[k];
          monthDetails['details'].push(computerDetails);
        }
        // if (monthYear === currentMonthYear) {
        //   // only store data for the most recent month
        //   this.bills.push(monthDetails);
        // }
        this.bills.push(monthDetails);
      }
      this.bills = this.bills.sort(sortBills);
      this.customerBalance = result.data.customerBalance;
      this.currentInvoice = result.data.currentInvoice;
      this.billingThreshold = result.data.billingThreshold;
      this.actionState = 'done';
    }
  }
}
