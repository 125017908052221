import BaseHttpService from './base-http.service';

export default class MarketingService extends BaseHttpService {
  addEmailContact(addEmailContactData) {
    return this.post('marketing/addEmailContact/', {
      firstName: addEmailContactData.firstName,
      lastName: addEmailContactData.lastName,
      email: addEmailContactData.email,
      reason: addEmailContactData.reason,
    });
  }

  addUserFeedback(addUserFeedbackData) {
    return this.post('marketing/addUserFeedback/', {
      score: addUserFeedbackData.score,
    });
  }

  sendReferralCode(sendReferralCodeData) {
    return this.post('marketing/sendReferralCode/', {
      email: sendReferralCodeData.email,
    });
  }

  getReferralCodeParameters() {
    return this.get('marketing/getReferralCodeParameters');
  }

  redeemPromoCode(redeemPromoCodeData) {
    return this.post('marketing/redeemPromoCode/', {
      promoCode: redeemPromoCodeData.promoCode,
    });
  }
}
