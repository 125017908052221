import React from 'react';

// transform: "rotate(45deg)"

export default function ComputirTitle() {
  return (
    <div style={{display: "flex", alignItems: "center", position: "relative"}}>
      <img src="/logo.png" height="70" alt="logo" />
      <img src="/beta.png" height="28" alt="beta callout" style={{borderRadius: "5%", position: "absolute", left: "80px", bottom: "38px"}}/>
    </div>
  )
}
