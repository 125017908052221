import * as React from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SecurityIcon from '@mui/icons-material/Security';
import { Grid, Typography, Box } from '@mui/material';
import FeatureCard from './FeatureCard';
import '../../style/css/FeatureSection.css';

const features = [
  {
    // old color
    // #00C853
    color: 'secondary.main',
    headline: 'Performance',
    text: 'Use the latest GPUs without having to buy. Or create low cost, speedy non-GPU computers.',
    icon: <SpeedIcon sx={{ fontSize: '30px', color: 'background.default' }} />,
    mdDelay: '0',
    smDelay: '0',
  },
  {
    // old color
    // #6200EA
    color: 'secondary.main',
    headline: 'Flexibility',
    text: 'Create computers as you want, and always ONLY pay for what you use.',
    icon: (
      <AutoFixHighIcon sx={{ fontSize: '30px', color: 'background.default' }} />
    ),
    mdDelay: '0',
    smDelay: '0',
  },
  {
    // old color
    // #0091EA
    color: 'secondary.main',
    headline: 'Security',
    text: 'Your personal computers are secured in an isolated cloud environment.',
    icon: (
      <SecurityIcon sx={{ fontSize: '30px', color: 'background.default' }} />
    ),
    mdDelay: '0',
    smDelay: '0',
  },
];

function FeatureSection() {
  return (
    <Box
      sx={{ backgroundColor: 'cardBackground.default' }}
      className="feature-section-container"
    >
      <div className="container-fluid lg-p-top">
        <Typography
          fontWeight="bold"
          variant="h3"
          align="center"
          className="lg-mg-bottom"
        >
          Features
        </Typography>
        <div className="container-fluid" style={{ paddingTop: '15px' }}>
          <Grid container>
            {features.map((element) => (
              <Grid
                item
                xs={12}
                sm={4}
                data-aos="zoom-in-up"
                key={element.headline}
                sx={{ padding: '0px 15px 0px 15px' }}
              >
                <FeatureCard
                  Icon={element.icon}
                  color={element.color}
                  headline={element.headline}
                  text={element.text}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Box>
  );
}

export default FeatureSection;
