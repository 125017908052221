import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import '../../style/css/FeatureCard.css';

// function shadeColor(hex, percent) {
//   const f = parseInt(hex.slice(1), 16);

//   const t = percent < 0 ? 0 : 255;

//   const p = percent < 0 ? percent * -1 : percent;

//   const R = f >> 16;

//   const G = (f >> 8) & 0x00ff;

//   const B = f & 0x0000ff;
//   return `#${(
//     0x1000000 +
//     (Math.round((t - R) * p) + R) * 0x10000 +
//     (Math.round((t - G) * p) + G) * 0x100 +
//     (Math.round((t - B) * p) + B)
//   )
//     .toString(16)
//     .slice(1)}`;
// }

// changedColor: 4C4C54
// backgroundColor: #35353B

function ComputerTypeCard(props) {
  const { price, headline, text } = props;
  const [isShown, setIsShown] = useState(false);
  let history = useHistory();

  return (
    <Box
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onClick={() => history.push('/specs')}
      sx={{
        backgroundColor: 'cardBackground.secondary',
        padding: '10px 0px 5px 0px',
        height: '100%',
        alignText: 'center',
        borderRadius: '16px',
        ':hover': { bgcolor: 'secondary.main', cursor: 'pointer' },
        transitionDuration: '0.5s',
      }}
    >
      <Typography variant="h5" sx={{ textAlign: 'center' }} paragraph>
        {headline}
      </Typography>
      <Typography
        variant="body1"
        color="text.primary"
        sx={{ textAlign: 'center' }}
      >
        {text}
      </Typography>
      <Typography
        variant="body1"
        color="text.primary"
        sx={{ textAlign: 'center' }}
      >
        {price}&nbsp;per hour
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: '20px',
          textAlign: 'center',
          color: isShown ? 'text.primary' : 'cardBackground.secondary',
        }}
      >
        Click here to learn more!
      </Typography>
    </Box>
  );
}

export default ComputerTypeCard;
