import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputAdornment from '@mui/material/InputAdornment';
import styled from 'styled-components';
import ErrorMessage from '../../components/ErrorMessage';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import Alert from '@mui/material/Alert';
import TooltipIcon from '../../components/TooltipIcon';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import TextButton from '../../components/TextButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import Link from '@mui/material/Link';
import NewsLetterSignUp from '../../components/NewsLetterSignUp';
import Zoom from '@mui/material/Zoom';
import { CircularProgress, InputLabel, Select } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { COMPUTIR_TYPES } from './computirTypes';
import PasswordCharacterRequirements from './PasswordCharacterRequirements';

const FormContainer = styled.div`
  max-width: 480px;
  padding: 30px;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 20px;
  border: 1px solid white;
`;

const STATUS = {
  IDLE: 'IDLE',
  SUBMITTED: 'SUBMITTED',
  SUBMITTING: 'SUBMITTING',
  COMPLETED: 'COMPLETED',
};

const MAX_COMPUTER_NAME_LENGTH = 15;
const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 20;
const MIN_STORAGE = 100;
const MAX_STORAGE = 200;

function getStartingRegion() {
  // Get user's timezone for region
  // TODO daylight savings time might screw this up
  // TODO use better logic here...
  const utcOffset = new Date().getTimezoneOffset() / 60;
  let startingRegion = '';
  if (utcOffset <= 4) {
    startingRegion = 'US East';
  } else if (utcOffset > 4 && utcOffset <= 6) {
    startingRegion = 'US Central';
  } else {
    startingRegion = 'US West';
  }
  return startingRegion;
}

export default function CreateComputerForm(props) {
  const [computerName, setComputerName] = React.useState('');
  const [computerType, setComputerType] = React.useState('');
  const [computerStorage, setComputerStorage] = React.useState(200);
  const [formStatus, setFormStatus] = React.useState(STATUS.IDLE);
  const [overallErrorMessage, setOverallErrorMessage] = React.useState('');
  const [computerNameErrorMessage, setComputerNameErrorMessage] =
    React.useState('');
  const [computerStorageErrorMessage, setComputerStorageErrorMessage] =
    React.useState('');
  const [touched, setTouched] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selectedRegion, setSelectedRegion] = React.useState(
    getStartingRegion(),
  );
  const [excessDemandFlag, setExcessDemandFlag] = React.useState(false);
  const [createButtonShow, setCreateButtonShow] = React.useState(true);
  const [createCircularLoaderShow, setCreateCircularLoaderShow] =
    React.useState(false);
  const [checkmarkShow, setCheckmarkShow] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [passwordSpecialCharErrorMessage, setPasswordSpecialCharErrorMessage] =
    React.useState('');
  const [passwordUpperCaseIndicator, setPasswordUpperCaseIndicator] =
    React.useState('missing');
  const [passwordLowerCaseIndicator, setPasswordLowerCaseIndicator] =
    React.useState('missing');
  const [passwordNumberIndicator, setPasswordNumberIndicator] =
    React.useState('missing');
  const [passwordSpecialCharIndicator, setPasswordSpecialCharIndicator] =
    React.useState('missing');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  let history = useHistory();

  React.useEffect(() => {
    const preventUnload = (event) => {
      // NOTE: This message isn't used in modern browsers, but is required
      const message =
        'Please wait until the action has completed before navigating away from this page.';
      if (formStatus === STATUS.SUBMITTING) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    window.addEventListener('beforeunload', preventUnload);

    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  });

  React.useEffect(() => {
    // Set errorMessage only if text is equal or bigger than MAX_LENGTH
    if (computerName.length > MAX_COMPUTER_NAME_LENGTH) {
      setComputerNameErrorMessage('Maximum of 15 characters');
    }
    // password validation
    if (password.length < MIN_PASSWORD_LENGTH && password !== '') {
      setPasswordErrorMessage('Minimum of 8 characters');
    }
    if (password.length > MAX_PASSWORD_LENGTH && password !== '') {
      setPasswordErrorMessage('Maximum of 20 characters');
    }
    if (password.match(/[A-Z]/g)) {
      setPasswordUpperCaseIndicator('present');
    } else {
      setPasswordUpperCaseIndicator('missing');
    }
    if (password.match(/[a-z]/g)) {
      setPasswordLowerCaseIndicator('present');
    } else {
      setPasswordLowerCaseIndicator('missing');
    }
    if (password.match(/[0-9]/g)) {
      setPasswordNumberIndicator('present');
    } else {
      setPasswordNumberIndicator('missing');
    }
    // eslint-disable-next-line
    if (password.match(/[@$!%\*?\_&]/g)) {
      setPasswordSpecialCharIndicator('present');
    } else {
      setPasswordSpecialCharIndicator('missing');
    }
    // ensure no special characters that aren't in the approved list are included
    // eslint-disable-next-line
    if (password.match(/[^@$!%\*?\_&A-Z0-9]/gi)) {
      setPasswordSpecialCharErrorMessage(
        'must be only A-Z, a-z, 0-9, or @ $ ! % * ? _ &',
      );
    }
    // /[A-Z]/gi
    // only a to z /^[a-zA-Z]+$/
    // anything but a to z /[^A-Z]/gi
    // set computerNameError only if text contains something that's not A-Z, a-z
    if (computerName.match(/[^A-Z0-9]/gi) && computerName.length > 0) {
      setComputerNameErrorMessage('Must be A-Z, a-z, 0-9 only.');
    }
    if (computerStorage.length > 0 && computerStorage <= MIN_STORAGE) {
      setComputerStorageErrorMessage('Must be greater than or equal to 30gb.');
    }
    if (computerStorage > MAX_STORAGE) {
      setComputerStorageErrorMessage('Must be less than 150gb.');
    }

    // if (computerType.match(/[^A-Z]/gi))
  }, [computerName, computerStorage, password]);

  React.useEffect(() => {
    // Set empty erroMessage only if text is less than MAX_LENGTH
    // and errorMessage is not empty.
    // avoids setting empty errorMessage if the errorMessage is already empty
    if (
      computerName.length > 0 &&
      computerName.length < MAX_COMPUTER_NAME_LENGTH &&
      computerName.match(/^[a-zA-Z0-9]+$/) &&
      computerNameErrorMessage
    ) {
      setComputerNameErrorMessage('');
    }
    if (
      computerStorage.length > 0 &&
      computerStorage >= MIN_STORAGE &&
      computerStorage < MAX_STORAGE &&
      computerStorageErrorMessage
    ) {
      setComputerStorageErrorMessage('');
    }
    if (
      password.length >= MIN_PASSWORD_LENGTH &&
      passwordErrorMessage &&
      password.length <= MAX_PASSWORD_LENGTH
    ) {
      setPasswordErrorMessage('');
    }
    if (!password.match(/[^@$!%\*?\_&A-Z0-9]/gi)) {
      setPasswordSpecialCharErrorMessage('');
    }
  }, [
    computerName,
    computerNameErrorMessage,
    computerStorage,
    computerStorageErrorMessage,
    password,
    passwordErrorMessage,
  ]);

  function handleBlur(event) {
    event.persist();
    setTouched((cur) => {
      return { ...cur, [event.target.id]: true };
    });
    if (touched['mui-1'] === false && computerName.length === 0) {
      setComputerNameErrorMessage('Required.');
    }
    if (touched['mui-2'] === false && computerStorage.length === 0) {
      setComputerStorageErrorMessage('Required.');
    }
    if (touched['mui-7'] === false && password.length === 0) {
      setPasswordErrorMessage('Required.');
    }
  }
  function handleFocus(event) {
    event.persist();
    setTouched((cur) => {
      return { ...cur, [event.target.id]: false };
    });
  }

  // function sleep(ms) {
  //     return new Promise(resolve => setTimeout(resolve, ms));
  // }

  const handleCreateComputer = async (computersStore) => {
    setFormStatus(STATUS.SUBMITTING);
    try {
      computersStore.token = getAccessTokenSilently();
      setCreateButtonShow(false);
      setCreateCircularLoaderShow(true);
      const creationFlagData = await computersStore.createComputer(
        computerName,
        computerType,
        computerStorage,
        selectedRegion,
        password,
      );
      if (creationFlagData === 'vcpu limit exceeded') {
        setExcessDemandFlag(true);
      }
      setFormStatus(STATUS.SUBMITTED);
      setCreateCircularLoaderShow(false);
      setCheckmarkShow(true);
    } catch (error) {
      setOverallErrorMessage(error.response.data.message);
    }
  };

  const handleClose = (computersStore) => {
    setFormStatus(STATUS.COMPLETED);
    // TODO - have this run fetchComputers
    // TODO - add refresh button
    history.push('/computirs');
    computersStore.fetchComputers();
  };

  const handleChange = (event) => {
    setComputerType(event.target.value);
  };

  const handleSelectStorage = (event) => {
    setComputerStorage(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseRegionMenu = (event) => {
    if (event.currentTarget.textContent) {
      setSelectedRegion(event.currentTarget.textContent);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <FormContainer>
        {user.email_verified &&
          props.stripeStore.paymentVerified === 'not verified' && (
            <Alert sx={{ marginTop: '8px' }} severity="error">
              You must add a payment method before creating a Computir.
            </Alert>
          )}
        <h1>Create a new Computir</h1>
        <p>Customize your Computir.</p>
        <TextButton
          underline="underline"
          windowLocationHash="/specs"
          buttonText="Please see pricing page for more details on Computir types"
        />

        {overallErrorMessage && <ErrorMessage message={overallErrorMessage} />}

        <FormControl fullWidth sx={{ marginBottom: 1 }} disabled>
          <TextField
            InputProps={{ style: { color: 'text.primary' } }}
            InputLabelProps={{
              sx: { color: 'text.primary' },
            }}
            label="Name"
            placeholder="Name"
            margin="normal"
            variant="outlined"
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={computerNameErrorMessage.length !== 0}
            helperText={computerNameErrorMessage}
            onChange={(e) => setComputerName(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ marginBottom: 3 }}>
          <FormLabel component="legend" sx={{ color: 'text.primary' }}>
            Computir Type
          </FormLabel>
          <RadioGroup
            aria-label="computir-type"
            defaultValue="computir_xeon_v3-windows"
            name="radio-buttons-group"
            value={computerType}
            onChange={handleChange}
          >
            {Object.entries(COMPUTIR_TYPES).map(
              ([computirType, computirDetails]) => {
                return (
                  <FormControlLabel
                    key={computirType}
                    disableTypography
                    value={computirType}
                    control={<Radio />}
                    label={
                      <Box sx={{ display: 'contents' }}>
                        <Typography>
                          {computirDetails.displayName}&nbsp;&nbsp;
                        </Typography>
                        <TooltipIcon
                          color="secondary.main"
                          placement="right"
                          tooltipText={computirDetails.details}
                        />
                      </Box>
                    }
                    onChange={(e) => setComputerType(e.target.value)}
                  />
                );
              },
            )}
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 1 }}>
          <InputLabel sx={{ color: 'text.primary' }}>
            Computir Storage
          </InputLabel>
          <Select
            labelId="storage-select-label"
            id="storage-select"
            value={computerStorage}
            label="Computir Storage"
            onChange={handleSelectStorage}
            // onBlur={handleBlur}
            // onFocus={handleFocus}
          >
            <MenuItem value={200} disableRipple>
              200
            </MenuItem>
            <MenuItem value={100} disableRipple>
              100
            </MenuItem>
          </Select>
        </FormControl>
        {/* OLD TEXT ENTRY FOR STORAGE <FormControl fullWidth>
          <TextField
            InputLabelProps={{
              sx: { color: 'text.primary' },
            }}
            label="Computir Storage (100-200 gb)"
            value={computerStorage}
            margin="normal"
            variant="outlined"
            input="number"
            type="number"
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={computerStorageErrorMessage.length !== 0}
            helperText={computerStorageErrorMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  disableTypography
                  sx={{ color: 'text.primary' }}
                >
                  gb
                </InputAdornment>
              ),
            }}
            onChange={(e) => setComputerStorage(e.target.value)}
          />
        </FormControl> */}
        <FormControl fullWidth>
          <TextField
            InputProps={{ style: { color: 'text.primary' } }}
            InputLabelProps={{
              sx: { color: 'text.primary' },
            }}
            label="Password"
            placeholder="Password"
            margin="normal"
            variant="outlined"
            type="password"
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={
              passwordErrorMessage.length !== 0 ||
              passwordSpecialCharErrorMessage.length !== 0 ||
              (password.length > 0 &&
                (passwordUpperCaseIndicator === 'missing' ||
                  passwordLowerCaseIndicator === 'missing' ||
                  passwordNumberIndicator === 'missing' ||
                  passwordSpecialCharIndicator === 'missing'))
            }
            helperText={
              passwordErrorMessage && passwordSpecialCharErrorMessage
                ? passwordErrorMessage.concat(
                    ' & ',
                    passwordSpecialCharErrorMessage,
                  )
                : passwordErrorMessage && !passwordSpecialCharErrorMessage
                ? passwordErrorMessage
                : !passwordErrorMessage && passwordSpecialCharErrorMessage
                ? passwordSpecialCharErrorMessage
                : ''
            }
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PasswordCharacterRequirements
            passwordLength={password.length}
            passwordUpperCaseIndicator={passwordUpperCaseIndicator}
            passwordLowerCaseIndicator={passwordLowerCaseIndicator}
            passwordNumberIndicator={passwordNumberIndicator}
            passwordSpecialCharIndicator={passwordSpecialCharIndicator}
          />
        </div>
        <FormControl fullWidth>
          <TextField
            InputProps={{ style: { color: 'text.primary' } }}
            InputLabelProps={{
              sx: { color: 'text.primary' },
            }}
            label="Confirm Password"
            placeholder="Confirm Password"
            margin="normal"
            variant="outlined"
            type="password"
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={password && password !== confirmPassword}
            helperText={
              password && password !== confirmPassword
                ? 'password does not match'
                : ''
            }
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </FormControl>
        <Box display={'flex'}>
          <Button
            id="region-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            sx={{ color: 'text.primary' }}
            onClick={handleClick}
          >
            Hosted Region: {selectedRegion}
            <ExpandMoreSharpIcon />
          </Button>
          <TooltipIcon
            placement="right-start"
            tooltipText={
              'For lowest latency, selct the region closest to where you live.'
            }
            color="text.secondary"
          />
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseRegionMenu}
          MenuListProps={{
            'aria-labelledby': 'region-button',
          }}
        >
          <MenuItem onClick={handleCloseRegionMenu}>US East</MenuItem>
          <MenuItem onClick={handleCloseRegionMenu}>US Central</MenuItem>
          <MenuItem onClick={handleCloseRegionMenu}>US West</MenuItem>
        </Menu>
        <div
          style={{
            position: 'relative',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Zoom in={createButtonShow}>
            <Button
              style={{ marginTop: '10px' }}
              fullWidth
              variant="contained"
              // color="primary"
              onClick={() => handleCreateComputer(props.store)}
              disabled={
                formStatus === STATUS.SUBMITTING ||
                computerName.length === 0 ||
                computerType.length === 0 ||
                computerStorage.length === 0 ||
                computerNameErrorMessage.length !== 0 ||
                computerStorageErrorMessage.length !== 0 ||
                password.length < MIN_PASSWORD_LENGTH ||
                passwordErrorMessage.length !== 0 ||
                passwordSpecialCharErrorMessage.length !== 0 ||
                passwordUpperCaseIndicator === 'missing' ||
                passwordLowerCaseIndicator === 'missing' ||
                passwordNumberIndicator === 'missing' ||
                passwordSpecialCharIndicator === 'missing' ||
                password !== confirmPassword ||
                selectedRegion.length === 0 ||
                props.stripeStore.paymentVerified !== 'verified' ||
                (!user.email_verified && !isLoading)
              }
            >
              CREATE COMPUTIR
            </Button>
          </Zoom>
          <Zoom in={createCircularLoaderShow}>
            <CircularProgress
              style={{ marginTop: '10px', position: 'absolute' }}
            />
          </Zoom>
          <Zoom in={checkmarkShow}>
            <CheckCircle
              color="success"
              style={{
                marginTop: '10px',
                position: 'absolute',
                fontSize: '40px',
              }}
            />
          </Zoom>
        </div>
        <Typography style={{ marginTop: '10%' }}>
          By creating a Computir you agree to Computir's&nbsp;
          <Link href="/legal" color="inherit">
            terms of service
          </Link>
        </Typography>
        {!user.email_verified && (
          <Alert sx={{ marginTop: '8px' }} severity="error">
            You must verify your email before creating a computer.
          </Alert>
        )}
      </FormContainer>

      <Dialog
        open={formStatus === STATUS.SUBMITTED}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {excessDemandFlag
            ? 'Unable to create Computir: high demand'
            : 'Created'}
        </DialogTitle>
        <DialogContent>
          {excessDemandFlag ? (
            <>
              <Alert severity="error">
                We are sorry we are unable to create new Computir currently,
                there is high demand volume right now.
              </Alert>
              <br />
              <DialogContentText
                id="alert-dialog-description"
                sx={{ color: 'text.primary' }}
              >
                We are hard at work to increase Computir availability. Please
                try another region, or wait a bit for demand to reduce. You can
                sign up here to be notified when we have increased availability.
              </DialogContentText>
              <NewsLetterSignUp
                customHeader="Sign up here to be notified when Computirs are available"
                reason="availability"
              />
            </>
          ) : (
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: 'text.primary' }}
            >
              Your new Computir has been created. REMEMBER THE PASSWORD YOU SET,
              YOU WILL NEED IT TO CONNECT TO YOUR COMPUTIR. Please click OK to
              navigate to your list of Computirs.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose(props.store);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
