import React from "react";

export function FaqJsonLd(props) {
    const mainEntityArray = props.pageContent.map(
        x => {
            return (
                {
                    "@type":"Question",
                    "name": x.faqQuestion,
                    "acceptedAnswer":
                    {
                        "@type":"Answer",
                        "text": x.faqAnswer
                    }
                }
            )
        }
    )
    const schemaData = {
            "@context":"https://schema.org",
            "@type":"FAQPage",
            "mainEntity": mainEntityArray
        }

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
        )
}
