import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

function SummaryCard(props) {
  const { headline, hiddenText } = props;
  const [showMainText, setShowMainText] = useState(true);
  let history = useHistory();

  return (
    <Box
      onMouseEnter={(prevState) => setShowMainText(!prevState)}
      onMouseLeave={(prevState) => setShowMainText(true)}
      onClick={() => history.push('/specs')}
      sx={{
        backgroundColor: 'cardBackground.secondary',
        alignItems: 'center',
        borderRadius: '16px',
        ':hover': { bgcolor: 'secondary.main', cursor: 'pointer' },
        transitionDuration: '0.5s',
        height: '8em',
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'center',
      }}
    >
      {showMainText ? (
        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          {headline}
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', color: 'text.dark' }}
          >
            {hiddenText}
          </Typography>
          <br />
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', color: 'text.dark' }}
          >
            click to learn more
          </Typography>
        </Box>
      )}
    </Box>
  );
}

const TopSummarySection = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: '0px 20px 0px 20px',
        width: '80%',
        position: 'relative',
        left: '10%',
        display: { xs: 'none', sm: 'flex' },
      }}
    >
      <Grid container rowSpacing={5} columnSpacing={8}>
        <Grid item xs={16}>
          <Typography
            className="head-section-text-box"
            sx={{ backgroundColor: 'transparent', color: 'text.primary' }}
          >
            start gaming from any computer
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SummaryCard
            headline="powerful"
            hiddenText="Connect directly to powerful NVIDIA GPUs"
          />
        </Grid>
        <Grid item xs={4}>
          <SummaryCard
            headline="no committment"
            hiddenText="Never a monthly subscription, only pay for what you use"
          />
        </Grid>
        <Grid item xs={4}>
          <SummaryCard
            headline="direct in-browser connection"
            hiddenText="We take the guess work out of configuring your cloud computer"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopSummarySection;
