import React from 'react';
import PageTitle from '../../components/shared/PageTitle';
import faqClickCreateComputer from '../../assets/FAQclickCreateComputer.png';
import faqSignUp from '../../assets/FAQsignUp.png';
import faqCreateComputer from '../../assets/FAQcreateANewComputer.png';
import faqVerifyEmail from '../../assets/FAQVerifyEmail.png';
import faqSeeNewComputer from '../../assets/FAQseeNewComputer.png';
import faqHowToConnect from '../../assets/FAQhowToConnect.gif';
import faqAddPayment from '../../assets/FAQaddPayment.png';
import { ComputersContainer } from '../../style/ComputersContainer';
import QuestionCard from './QuestionCard';
import SwipeableTextMobileStepper from './ImageCarousel';
import { FaqJsonLd } from '../../components/JsonLd';
import BillingExample from './BillingExample';
import SEO from '../../components/shared/SEO';
import { Typography } from '@mui/material';
// import PersistenceExplanation from './PersistenceExplanation';

export const howToCreateAComputer = [
  {
    label: 'First, create an account',
    imgPath: faqSignUp,
  },
  {
    label: 'You will then be prompted to verify your email',
    imgPath: faqVerifyEmail,
  },
  {
    label:
      'Then, add a payment option. You will be prompted to authorize the fully refundable $5 Launch Deposit (see below for details), and you will only be charged for usage of your Computirs',
    imgPath: faqAddPayment,
  },
  {
    label: 'Then, click "Create Computir"',
    imgPath: faqCreateComputer,
  },
  {
    label:
      'In "Create a new Computir", add a name, select a Computir type, storage, region, and set a memorable password that you will use to connect. Then click "Create Computir"',
    imgPath: faqClickCreateComputer,
  },
  {
    label: "You'll then have a Computir in the Computirs page in seconds",
    imgPath: faqSeeNewComputer,
  },
  {
    label:
      'Connect directly to your Computir in the easiest way possible, click the connect button, enter your password, and get gaming! We recommend using this connection to install a low-latency client, such as Parsec',
    imgPath: faqHowToConnect,
  },
];

const generalContent = [
  {
    faqQuestion: 'What is Computir?',
    faqAnswer:
      'Computir is the new innovative cloud gaming service, designed with users and gamers in mind. We strive to bring the fastest computers at the lowest cost possible, delivered in an easy-to-use interface. We believe it is critically important to only charge users for the computational power they use and storage they require, never a monthly subscription fee!',
  },
  {
    faqQuestion: 'How does Computir work?',
    faqAnswer:
      'Computir creates a high performance computer in the cloud and makes this computer available to you with our in browser connection or by providing the needed credentials to log in via your preferred RDP method. At a very basic level, you log into the Computir web portal, create a computer to your desired specifications, and connect in our secure in-browser client to begin using your brand new computer!',
  },
  {
    faqQuestion: 'How do I create a Computir?',
    faqAnswer: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SwipeableTextMobileStepper images={howToCreateAComputer} />
      </div>
    ),
  },
  {
    faqQuestion: 'How do I connect to my Computir?',
    faqAnswer:
      'Use our browser-based connection by clicking "connect" to access your computir. We recommend switching to a low latency streaming client (e.g. "parsec") for best performance on a Computir Pro.',
    faqImage: faqHowToConnect,
    faqAltText: 'Connect to computir gif',
  },
  {
    faqQuestion: 'Can I keep my same Computir forever?',
    faqAnswer:
      'You can keep your same Computir forever, but keep in mind there is a fee for the time it is stopped. This may prevent other users from being able to access a Computir for their usage. As a best practice we encourage terminating a Computir when you are done with it.',
  },
  {
    faqQuestion: 'What is cloud gaming? Is cloud gaming for me?',
    faqAnswer:
      'Cloud gaming is an online service that runs powerful computers on remote servers and streams them directly to your device with low/no latency. Cloud gaming is perfect for those who do not want to purchase a full gaming rig or gaming console, but still play the most recent games being released (or older nostalgia-inducing games!).',
  },
  {
    faqQuestion: 'What can I use Computir for?',
    faqAnswer:
      'Computir runs Windows and can be used for any Windows-based application (within our Terms and Conditions). Computir Pro is powerful enough to play the newest games, and Computir Lite is a quick, easy, and cheap solution to run Windows programs on a Mac or Linux machine (for example). The only limits are your imagination (and legal boundaries...)!',
  },
  {
    faqQuestion: 'Does Computir have a long wait time or waiting list?',
    faqAnswer:
      'No! Computirs can be created and destroyed on demand, ready as quickly as they can configure themselves!',
  },
];
const billingContent = [
  {
    faqQuestion: 'How am I billed?',
    faqAnswer: (
      <>
        You will be billed after a usage threshold is met or monthly, whichever
        is met first. You will be sent an invoice for your usage and your card
        on file will be charged the amount stated on the invoice. Please see the{' '}
        <a style={{ color: 'inherit' }} href="/specs">
          specs/pricing
        </a>{' '}
        page for specific pricing information. We have partnered with{' '}
        <a
          style={{ color: 'inherit' }}
          href="https://stripe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stripe
        </a>{' '}
        to simplify our billing process and keep all financial data secure - we
        never see your credit card information!
      </>
    ),
  },
  {
    faqQuestion: 'How is my bill calculated?',
    faqAnswer: <BillingExample />,
  },
  {
    faqQuestion:
      "What is this Launch Deposit? I thought you said there aren't any subscription fees?",
    faqAnswer:
      "The Launch Deposit is not a fee! The primary use is to validate that you are a real user, and the entirety of the deposit is put towards your usage of the Computir platform. If you don't use it all, it is fully refundable.",
  },
  {
    faqQuestion:
      "I didn't use my entire Launch Deposit, how do I get a refund?",
    faqAnswer:
      'Simply cancel your subscription. When the current billing cycle is complete, you will receive a refund for the unused portion of your Launch Deposit.',
  },
];
const securityContent = [
  {
    faqQuestion: 'Is Computir secure? Safe?',
    faqAnswer:
      'Yes. Every decision made at Computir is made with security in the forefront. At the infrastructure level, our servers are hosted in the most advanced data centers in the world. At the access level, we have added functionality so that only YOU can connect to your computer. At the individual computer level, security is up to YOU! Think about these computers functioning as any other desktop you have used in the past - keep it safe!',
  },
  {
    faqQuestion:
      'I forgot the password to my Computir, can you reset it for me?',
    faqAnswer:
      "Unfortunately, you are solely responsible for your Computir's password, and we are unable to reset it. This is solely due to security of how Computirs are kept isolated without access to passwords.",
  },
];

/**
 *
 * @param {object} pageContent - content to render
 */
const FaqContent = (props) => {
  return props.pageContent.map(
    ({ faqImage, faqQuestion, faqAnswer, faqAltText }, key) => {
      return (
        <QuestionCard
          key={faqQuestion}
          asdf={`${key}${faqQuestion.slice(30)}`}
          faqQuestion={faqQuestion}
          faqAnswer={faqAnswer}
          faqImage={faqImage}
          faqAltText={faqAltText}
        />
      );
    },
  );
};

export default function FaqPage() {
  return (
    <>
      <SEO
        title={'FAQ'}
        description={
          'Learn more here about using Computir for GPU cloud gaming or a low cost workstation.'
        }
      />
      <ComputersContainer>
        <FaqJsonLd
          pageContent={generalContent
            .concat(billingContent)
            .concat(securityContent)}
        />
        <PageTitle title={'Frequently Asked Questions'} />
        <br />
        <FaqTitle titleText="General" />
        <FaqContent pageContent={generalContent} />
        <FaqTitle titleText="Billing" />
        <FaqContent pageContent={billingContent} />
        <FaqTitle titleText="Security" />
        <FaqContent pageContent={securityContent} />
      </ComputersContainer>
    </>
  );
}

function FaqTitle({ titleText }) {
  return (
    <div
      style={{
        position: 'relative',
        width: '80%',
        left: '15%',
        marginTop: '2%',
      }}
    >
      <Typography variant="h6">{titleText}</Typography>
    </div>
  );
}
