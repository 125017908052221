import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function ParagraphBox(props) {
  return (
    <Box
      sx={{
        borderRadius: '10px',
        marginTop: '5%',
        justifyContent: 'center',
        backgroundColor: 'cardBackground.default',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: { xs: '90%', sm: '80%' },
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom>
          {props.title}
        </Typography>
        <Typography paragraph>{props.paragraph}</Typography>
      </Box>
    </Box>
  );
}
