import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/**
 * 
 *  a generalized delete/confirmation modal - displays as a button and when clicked shows a popup confirmation modal
 * 
 * @param {string} buttonLabel - the label of the button to display
 * @param {string} dialogTitle - the title to display on the popup modal
 * @param {string} dialogDescription - the description / text to display on the popup modal
 * @param {string} deleteButtonLabel - what to label the final delete button in the popup modal
 * @param {string} color - color of button
 * @param {function} handleDelete - what the delete button should do
 */
const AlertDialog = ({ buttonLabel, dialogTitle, dialogDescription, deleteButtonLabel, color, handleDelete }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const closeAndHandleClick = () => {
        handleClose()
        handleDelete()
    }

    return (
        <div>
            <Button disableRipple variant='contained' onClick={handleClickOpen} color={color}>
                {buttonLabel}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{sx: {backgroundColor: 'cardBackground.default'}}}
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="text.primary">
                        {dialogDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={closeAndHandleClick} autoFocus color='error' variant='contained'>
                        {deleteButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog