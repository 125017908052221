import React from 'react';
import { CircularProgress } from '@mui/material';

const Loading = () => (
  <div className="spinner">
    <CircularProgress color="primary" size="5rem" />
  </div>
);

export const SmallLoading = () => (
  <div className="spinner">
    <CircularProgress color="primary" size="2.5rem" />
  </div>
);

export default Loading;
