import React from 'react';
import { Typography, Paper, Container, Button, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#1f1f1f',
  color: '#ffffff',
  borderRadius: '12px',
  textAlign: 'center',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

// TODO - add CTA at bottom of this box
const LaunchDeposit = () => {
  return (
    <Container maxWidth="lg">
      <StyledPaper elevation={3}>
        <StyledTypography variant="h4" gutterBottom>
          Launch Deposit
        </StyledTypography>
        <Typography variant="body1" paragraph>
          At Computir, we're revolutionizing gaming. Say hello to the{' '}
          <strong>Launch Deposit</strong> - where your gaming journey begins.
        </Typography>
        <Typography variant="body1" paragraph>
          Discover the purpose of the Launch Deposit:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>1. No Subscriptions, Only Freedom</strong>
          <br />
          We're leaving subscriptions behind. The Launch Deposit unlocks a
          subscription-free gaming world, giving you the control to game your
          way.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>2. Usage, Your Way</strong>
          <br />
          Your deposit becomes a credit, tailored to your gaming style. Every
          dollar is used for gaming, ready whenever you dive in.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3. Unplayed Credits, Uncomplicated Refunds</strong>
          <br />
          Life's unpredictable. Unused credit? Fully refundable. We're all about
          fairness, ensuring your gaming journey remains seamless.
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to a new era of gaming at Computir. With the Launch Deposit,
          you're in control.
        </Typography>
        <Box marginTop={3}>
          {/* <Button variant="contained" color="secondary" size="large">
            Let's Begin
          </Button> */}
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default LaunchDeposit;
