import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import UserFeedback from './UserFeedback';

export default function TerminateDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [terminating, setTerminating] = React.useState(false);
  const [showUserFeedback, setShowUserFeedback] = React.useState(false);

  React.useEffect(() => {
    const preventUnload = (event) => {
      // NOTE: This message isn't used in modern browsers, but is required
      const message =
        'Please wait until the action has completed before navigating away from this page.';
      if (terminating) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    window.addEventListener('beforeunload', preventUnload);

    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      // currently allowing this, but we could restrict if we think that would be easier to understand
      setOpen(false);
    } else if (terminating) {
      return;
    } else {
      setOpen(false);
    }
  };

  const handleTerminate = () => {
    setTerminating(true);
    props.store.terminateComputer(props.computerName).then((results) => {
      setOpen(false);
      window.localStorage.removeItem(props.computerName);
      setShowUserFeedback(true);
      setTerminating(false);
    });
  };
  return (
    <div>
      {props.computerStatus === 'terminated' ||
      props.computerStatus === 'shutting-down' ? (
        <Button hidden></Button>
      ) : (
        <Button
          onClick={handleClickOpen}
          sx={{
            borderRadius: '4px',
            backgroundColor: 'primary.error',
            color: 'text.primary',
            fontSize: 'small',
            width: '100%',
          }}
        >
          <DeleteIcon sx={{ color: 'text.primary' }} /> TERMINATE
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Terminate Computir?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: 'text.primary' }}
          >
            Are you sure you want to terminate this Computir? You will not be
            able to recover any data on it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={terminating ? true : false}>
            Cancel
          </Button>
          <Button
            onClick={handleTerminate}
            autoFocus
            disabled={terminating ? true : false}
          >
            Terminate
          </Button>
        </DialogActions>
        {props.actionState === 'pending' ? (
          <Box sx={{ width: '100%' }} active={false}>
            <LinearProgress />
          </Box>
        ) : null}
      </Dialog>
      {showUserFeedback ? <UserFeedback /> : null}
    </div>
  );
}
