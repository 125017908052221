import React from 'react';
import Link from '@mui/material/Link';
import { ParagraphBox } from '../../components/ParagraphBox';
import SEO from '../../components/shared/SEO';

const careerParagraph = <>We do not currently have any open positions but you can inquire at <Link underline="none" sx={{color: 'text.primary'}} href="mailto:careers@computir.com">careers@computir.com</Link> if you are interested in working with us.</>;

export default function CareersPage() {
    return (
      <>
      <SEO
      title={'Careers'}
      description={'Learn more about working at Computir and apply to open roles.'} />
      <ParagraphBox title="Careers" paragraph={careerParagraph} />
      </>
    )
}