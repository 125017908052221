import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/system';
import Computer from './Computer';
import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { withRouter } from 'react-router';
import { ComputersContainer } from '../../style/ComputersContainer';
import PageTitle from '../../components/shared/PageTitle';
import RefreshButton from './RefreshButton';
import SEO from '../../components/shared/SEO';

const EmptyTasksPlaceholder = styled('div')({
  color: 'text.primary',
  textAlign: 'center',
  fontSize: '22px',
});

@inject('computersStore', 'routerStore')
@observer
class ComputersPage extends Component {
  componentDidMount() {
    console.log(this.props.computersStore);
    const { getAccessTokenSilently, user, isLoading } = this.props.auth0;
    if (
      !isLoading &&
      user.email_verified &&
      this.props.computersStore.actionState === 'pending'
    ) {
      this.props.computersStore.token = getAccessTokenSilently();
      this.props.computersStore.fetchComputers();
    } else if (this.props.computersStore.actionState === 'fetching') {
      console.log('');
    } else {
      this.props.computersStore.actionState = 'done';
    }
  }

  renderComputers = () => {
    const { computersStore } = this.props;
    const { user, isLoading } = this.props.auth0;
    if (
      computersStore.actionState === 'fetching' &&
      !computersStore.computers.length
    ) {
      return (
        <Grid justify="space-between" container>
          <Box sx={{ margin: '0 auto' }}>
            <CircularProgress color="secondary" size="5rem" />
          </Box>
        </Grid>
      );
    } else if (
      !computersStore.computers.length &&
      computersStore.actionState === 'done'
    ) {
      if (!isLoading && !user.email_verified) {
        return (
          <EmptyTasksPlaceholder>
            You must verify your email before creating computers.
          </EmptyTasksPlaceholder>
        );
      } else {
        return (
          <EmptyTasksPlaceholder>
            No computirs available.{' '}
            <Button
              disableRipple={true}
              sx={{
                background: 'none!important',
                border: 'none',
                padding: '0!important',
                color: 'text.primary',
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
                textAlign: 'inherit',
                fontSize: 'inherit',
                fontWeight: 'inherit',
              }}
              onClick={() => {
                this.props.history.push('/create');
              }}
            >
              Create one?
            </Button>
            <Typography>
              First time here? See our&nbsp;
              <Button
                disableRipple={true}
                variant={'text'}
                sx={{
                  background: 'none!important',
                  border: 'none',
                  minWidth: '0px',
                  padding: '0!important',
                  color: 'text.primary',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  textTransform: 'none',
                  textAlign: 'inherit',
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                }}
                onClick={() => {
                  this.props.history.push('/faq');
                }}
              >
                FAQ.
              </Button>
            </Typography>
          </EmptyTasksPlaceholder>
        );
      }
    }

    return computersStore?.computers.map((computer) => (
      <Computer
        key={computer.ip_address}
        ipAddress={computer.ip_address}
        id={computer.id}
        computer_name={computer.computer_name}
        status={computer.status}
        actionState={computersStore.actionState}
        computerPassword={computer.computerPassword}
        activeComputer={computer.computer_name}
        computer_type={computer.computer_type}
      />
    ));
  };

  testRenderComputers = () => {
    const computers = [
      {
        key: '127.0.0.1',
        ip_address: '127.0.0.1',
        id: 'id',
        computer_name: 'coolcomputir',
        status: 'running',
        actionState: 'asdf',
        computerPassword: 'asdf',
        activeComputer: 'name',
        computer_type: 'computir_quadro-windows',
      },
      {
        key: '127.0.0.1',
        ipAddress: '127.0.0.1',
        id: 'id',
        computer_name: 'name',
        status: 'stopped',
        actionState: 'asdf',
        computerPassword: 'asdf',
        activeComputer: 'name',
        computer_type: 'computir_quadro-windows',
      },
      {
        key: '127.0.0.1',
        ipAddress: '127.0.0.1',
        id: 'id',
        computer_name: 'poopy',
        status: 'Error Creating',
        actionState: 'asdf',
        computerPassword: 'asdf',
        activeComputer: 'name',
        computer_type: 'computir_quadro-windows',
      },
    ];
    return computers.map((computer) => (
      <Computer
        key={computer.ip_address}
        ipAddress={computer.ip_address}
        id={computer.id}
        computer_name={computer.computer_name}
        status={computer.status}
        actionState={computers.actionState}
        computerPassword={computer.computerPassword}
        activeComputer={computer.computer_name}
        computer_type={computer.computer_type}
      />
    ));
  };

  render() {
    return (
      <>
        <SEO
          title={'Computirs'}
          description={
            'The hub of cloud gaming, a control center for using the Computir platform.'
          }
        />
        <ComputersContainer>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PageTitle title="Your Computirs" />
            <RefreshButton renderComputers={this.renderComputers} />
          </Box>
          <br />
          {this.renderComputers()}
          {/* {this.testRenderComputers()} */}
        </ComputersContainer>
      </>
    );
  }
}

export default withAuthenticationRequired(withRouter(withAuth0(ComputersPage)));
