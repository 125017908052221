import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

const buttonStyle = {
    marginRight: '1.5%',
    marginLeft: '1.5%',
    minWidth: '4em',
  }

@inject('marketingStore')
class UserFeedback extends Component {
    constructor (props) {
        super(props);
        this.state = {
            open: true,
            buttonStyling: {
                1: 'outlined',
                2: 'outlined',
                3: 'outlined',
                4: 'outlined',
                5: 'outlined',
            },
            disabledStatus: true,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

  handleClick(e) {
    const selectedNumber = parseInt(e.target.innerText);
    let updateButtonStyling = {...this.state.buttonStyling};
    Object.keys(updateButtonStyling).forEach((i) => updateButtonStyling[i] = 'outlined');
    updateButtonStyling[selectedNumber] = 'contained';
    this.setState({buttonStyling: updateButtonStyling, disabledStatus: false})
  };

  handleClose() {
    this.setState({open: false});
  };

  async handleSubmit() {

    const score = Object.keys(this.state.buttonStyling).find(key => this.state.buttonStyling[key] === 'contained');
    const { getAccessTokenSilently } = this.props.auth0;
    this.props.marketingStore.token = getAccessTokenSilently();
    this.props.marketingStore.addUserFeedback(
      {
        score: score,
      }
    );
    this.handleClose();
  }

render() {
  return (
    <Dialog
    open={this.state.open}
    onClose={this.handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      How was your experience using Computir?
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{color: 'text.primary', display: 'flex', justifyContent: 'space-evenly'}} component="div">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <Button sx={buttonStyle} variant={this.state.buttonStyling[1]} onClick={this.handleClick}>1</Button>
                    <Button sx={buttonStyle} variant={this.state.buttonStyling[2]} onClick={this.handleClick}>2</Button>
                    <Button sx={buttonStyle} variant={this.state.buttonStyling[3]} onClick={this.handleClick}>3</Button>
                    <Button sx={buttonStyle} variant={this.state.buttonStyling[4]} onClick={this.handleClick}>4</Button>
                    <Button sx={buttonStyle} variant={this.state.buttonStyling[5]} onClick={this.handleClick}>5</Button>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div>poor</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>{"\u00A0"}</div>
                    <div>excellent</div>
                </div>
            </Grid>
        </Grid>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <Button onClick={this.handleClose}>Cancel</Button>
                    <Button onClick={this.handleSubmit} disabled={this.state.disabledStatus}>Submit</Button>
                </div>
            </Grid>
        </Grid>
    </DialogActions>
  </Dialog>
  )
}
}

export default withAuth0(UserFeedback)
