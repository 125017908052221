import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TooltipIcon from '../../components/TooltipIcon';
import { COMPUTIR_TYPES } from '../create-computer/computirTypes';

function createData(name, vCpu, Gpu, Memory, Price, detailsObject) {
  return {
    name,
    vCpu,
    Gpu,
    Memory,
    Price,
    detailsObject,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            color="primary"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.vCpu}</TableCell>
        <TableCell align="right">{row.Gpu}</TableCell>
        <TableCell align="right">{row.Memory}</TableCell>
        <TableCell align="right">{row.Price}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="details">
                <TableBody>
                  {/* // eslint-disable-next-line */}
                  {row.detailsObject.map((historyRow) => (
                    <TableRow key={historyRow.title}>
                      <TableCell
                        sx={{ borderBottom: 'none' }}
                        component="th"
                        scope="row"
                      >
                        {historyRow.title}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        {historyRow.content}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    vCpu: PropTypes.number.isRequired,
    Gpu: PropTypes.number.isRequired,
    Memory: PropTypes.number.isRequired,
    detailsObject: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    Price: PropTypes.string.isRequired,
  }).isRequired,
};

// const rows = [
//   createData('Computir Pro', '$1.99', 'Yes', '16 gb', 4, [
//     {
//       title: 'GPU',
//       content: 'NVIDIA Tesla T4',
//     },
//     {
//       title: 'CPU',
//       content: 'Intel Cascade Lake (2 cores)',
//     },
//     {
//       title: 'Operating System',
//       content: 'Windows',
//     },
//     {
//       title: 'Graphics',
//       content: '60fps, 4K resolution support',
//     },
//   ]),
//   createData('Computir Lite', '$0.25', 'No', '1 gb', 1, [
//     {
//       title: 'GPU',
//       content: 'None',
//     },
//     {
//       title: 'CPU',
//       content: '3.3GHz Intel Xeon (1 core)',
//     },
//     {
//       title: 'Operating System',
//       content: 'Windows',
//     },
//   ]),
//   // createData('Computir Linux', 1, 0, 1.0, 0.08, [
//   //   {
//   //     title: 'GPU',
//   //     content: 'None'
//   //   },
//   //   {
//   //     title: 'CPU',
//   //     content: '3.3GHz Intel Xeon (1 core)'
//   //   },
//   //   {
//   //     title: 'Operating System',
//   //     content: 'Linux'
//   //   }
//   // ]),
// ];
let rows = [];
// eslint-disable-next-line
Object.entries(COMPUTIR_TYPES).map(([computirType, computirDetails]) => {
  if (computirType.split('-')[1] === 'windows') {
    rows.push(
      createData(
        computirDetails.generalDisplayName,
        computirDetails.price,
        computirDetails.Gpu,
        computirDetails.details.split('|')[1].trim().split(' ')[0],
        computirDetails.details.split('|')[0].trim().split(' ')[0],
        [
          {
            title: 'GPU',
            content: computirDetails.Gpu,
          },
          {
            title: 'CPU',
            content: `Multithreaded Intel or AMD with ${
              computirDetails.details.split('|')[0].trim().split(' ')[0]
            } threads (${
              computirDetails.details.split('|')[0].trim().split(' ')[0] / 2
            } core(s))`,
          },
          {
            title: 'Operating System',
            content: computirDetails.availableOs,
          },
        ],
      ),
    );
  }
});

function HeaderTitle(props) {
  return (
    <Box sx={{ display: 'inline-flex' }}>
      {props.title}&nbsp;
      <TooltipIcon
        color={'secondary.main'}
        infoSize={'small'}
        placement={'top'}
        tooltipText={props.tooltipText}
      />
    </Box>
  );
}

export default function PricingMatrix() {
  return (
    <TableContainer sx={{ width: { xs: 375, sm: 800 } }} component={Paper}>
      <Table aria-label="pricing table" sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Computir Type</TableCell>
            <TableCell align="right">
              <HeaderTitle
                title="Price/ Hour"
                tooltipText="Computir bills pro-rated per minute of usage"
              />
            </TableCell>
            <TableCell align="right">
              <HeaderTitle
                title="GPU"
                tooltipText="Graphics processing unit (GPU), a specialized processor for high performance graphics rendering"
              />
            </TableCell>
            <TableCell align="right">
              <HeaderTitle
                title="Memory"
                tooltipText="Random-access memory (RAM) serves as a temporary workspace in a computer where instructions can be executed and data can be processed"
              />
            </TableCell>
            <TableCell align="right" padding="none">
              {' '}
              <HeaderTitle
                title="vCPU"
                tooltipText="Virtual central processing unit (vCPU), each is a thread of a CPU core"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
