import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';

export default function QuestionCard(props) {
  const imageWidth =
    props.faqAltText === 'Connect to computir gif' ? '75%' : '75%';

  return (
    <Accordion
      sx={{
        borderRadius: '10px',
        marginTop: '1%',
        backgroundColor: 'cardBackground.secondary',
        width: '80%',
        left: '10%',
        '&::before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${props.asdf}a-content`}
        id={`panel${props.asdf}a-header`}
      >
        <Typography fontFamily="Montserrat" fontSize={'24px'}>
          {props.faqQuestion}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {props.faqImage && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              sx={{
                width: imageWidth,
              }}
              alt={props.faqAltText}
              src={props.faqImage}
            />
          </Box>
        )}
        <Typography component={'span'}>{props.faqAnswer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
