import { action } from 'mobx';

export default class MarketingStore {
  constructor(marketingService) {
    this.marketingService = marketingService;
    this.token = '';
  }

  async waitForToken() {
    let returnToken;
    await Promise.resolve(this.token).then(function (value) {
      returnToken = value;
    });
    return returnToken;
  }

  @action
  async addEmailContact(
    firstName,
    lastName,
    email,
    reason = 'general-interest',
  ) {
    const response = await this.marketingService.addEmailContact(
      firstName,
      lastName,
      email,
      reason,
    );
    return response;
  }

  @action
  async addUserFeedback(score) {
    this.marketingService.accessToken = await this.waitForToken();
    const response = await this.marketingService.addUserFeedback(score);
    this.marketingService.accessToken = '';
    return response;
  }

  @action
  async sendReferralCode(email) {
    this.marketingService.accessToken = await this.waitForToken();
    const response = await this.marketingService.sendReferralCode(email);
    this.marketingService.accessToken = '';
    return response;
  }

  @action
  async getReferralCodeParameters() {
    this.marketingService.accessToken = await this.waitForToken();
    const response = await this.marketingService.getReferralCodeParameters();
    this.marketingService.accessToken = '';
    return {
      spendAmount: response.data.spend_threshold,
      referralDiscountPercentage: response.data.referrer_percent_off,
      refereeDiscountPercentage: response.data.percent_off,
    };
  }

  @action
  async redeemPromoCode(promoCode) {
    this.marketingService.accessToken = await this.waitForToken();
    const response = await this.marketingService.redeemPromoCode(promoCode);
    this.marketingService.accessToken = '';
    return response;
  }
}
