import React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

// TODO implement this to replace where this is used over and over

/** accepts props:
 * buttonText=string
 * color=string,
 * underline={"underline" | null},
 * windowLocationHash=string */
export default function TextButton(props) {
  let fontClass = {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    color: props.color,
    textDecoration: props.underline,
    cursor: 'pointer',
    textTransform: 'none',
  };
  if (props.titleFont) {
    fontClass = {
      background: 'none!important',
      border: 'none',
      padding: '0!important',
      color: props.color,
      textDecoration: props.underline,
      cursor: 'pointer',
      textTransform: 'none',
      fontFamily: 'Montserrat!important',
    };
  }
  let history = useHistory();
  return (
    <Button
      disableRipple={true}
      sx={fontClass}
      onClick={() => {
        history.push(props.windowLocationHash);
      }}
    >
      {props.buttonText}
    </Button>
  );
}

export function TextLink(props) {
  return (
    <Link
      component={RouterLink}
      TypographyClasses="title-font"
      to={props.toLink}
      sx={{
        background: 'none!important',
        border: 'none',
        padding: '0!important',
        color: 'text.primary',
        textDecoration: 'none',
        cursor: 'pointer',
        textTransform: 'none',
        fontWeight: '500',
        fontSize: '0.875rem',
        lineHeight: '1.75',
        fontFamily: 'Montserrat!important',
      }}
    >
      {props.text}
    </Link>
  );
}
