import * as React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Grid } from '@mui/material';
import { useHistory } from "react-router-dom";
import loginWithTags from './shared/loginWithTags';

const buttonStyle = {
    p: "5%",
    width: "25%",
    backgroundColor: 'text.secondary',
    color: 'text.primary',
    borderColor: 'text.secondary',
    '&:hover': { backgroundColor: 'transparent' }
}
//color: 'text.primary', borderColor: 'text.secondary', backgroundColor: 'text.secondary', '&:hover': { backgroundColor: 'transparent'}

const gridLayout = {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "2%",
    marginBottom: "2%",
}

function PreFooter() {

    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
    let history = useHistory();
    if (isLoading) {
        return <></>
    } else if (isAuthenticated) {
        return (<></>)
    } else {
        return (
            <>
            <Grid sx={gridLayout}>
                <Button sx={buttonStyle} variant="contained" onClick={() => loginWithTags(loginWithRedirect)}>
                    Sign Up
                </Button>
                <div style={{width: "5%"}}></div>
                <Button sx={buttonStyle} variant="contained" onClick={() => history.push('faq')}>
                    Learn More
                </Button>
            </Grid>
            </>
        )    
    }
}

export default PreFooter
