import * as React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import ComputerTypeCard from './ComputerTypeCard';
import '../../style/css/FeatureSection.css';
import { COMPUTIR_TYPES } from '../create-computer/computirTypes';

let features = [];
// COMPUTIR_TYPES.forEach((type) => {
//   console.log(type);
//   features.push();
// });
features.push(COMPUTIR_TYPES['computir_quadro-windows']);
features.push(COMPUTIR_TYPES['computir_xeon_v3-windows']);
// const features = [
//   {
//     price: '$1.99 per hour',
//     headline: 'Computir Pro',
//     text: 'NVIDIA-powered Gaming Computer',
//     icon: <SpeedIcon style={{ fontSize: '30px' }} />,
//     mdDelay: '0',
//     smDelay: '0',
//   },
//   {
//     price: '$0.25 per hour',
//     headline: 'Computir Lite',
//     text: 'Low-cost Cloud Workstation',
//     icon: <AutoFixHighIcon style={{ fontSize: '30px' }} />,
//     mdDelay: '0',
//     smDelay: '0',
//   },
// ];

function ComputerTypeSection() {
  return (
    <Box
      className="feature-section-container"
      sx={{ display: { xs: 'none', sm: 'block' } }}
    >
      <div className="container-fluid lg-p-top">
        <Typography
          fontWeight="bold"
          variant="h3"
          align="center"
          className="lg-mg-bottom"
        >
          Products
        </Typography>
        <div className="container-fluid" style={{ paddingTop: '15px' }}>
          <Grid container>
            {features.map((element) => (
              <Grid
                item
                xs={6}
                // md={4}
                data-aos="zoom-in-up"
                key={element.headline}
                sx={{ padding: '0px 15px 0px 15px' }}
              >
                <ComputerTypeCard
                  price={element.price}
                  headline={element.generalDisplayName}
                  // text={element.text}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '3%',
          marginBottom: '5%',
        }}
      >
        <Typography variant="h5">...and many others!</Typography>
      </div>
    </Box>
  );
}

export default ComputerTypeSection;
