import React from 'react';
import { Typography, Box } from '@mui/material';
import '../../style/css/FeatureCard.css';

// function shadeColor(hex, percent) {
//   const f = parseInt(hex.slice(1), 16);

//   const t = percent < 0 ? 0 : 255;

//   const p = percent < 0 ? percent * -1 : percent;

//   const R = f >> 16;

//   const G = (f >> 8) & 0x00ff;

//   const B = f & 0x0000ff;
//   return `#${(
//     0x1000000 +
//     (Math.round((t - R) * p) + R) * 0x10000 +
//     (Math.round((t - G) * p) + G) * 0x100 +
//     (Math.round((t - B) * p) + B)
//   )
//     .toString(16)
//     .slice(1)}`;
// }

function FeatureCard(props) {
  const { Icon, color, headline, text } = props;
  return (
    <Box sx={{ padding: { xs: '2% 0 2% 0', sm: '5%' } }}>
      <Box sx={{ display: { xs: 'flex', sm: 'block' }, alignItems: 'center' }}>
        <Box
          sx={{
            borderRadius: '0px',
            textAlign: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
            padding: '7px',
            marginRight: { xs: '5%', sm: '0' },
          }}
          bgcolor={color}
        >
          {Icon}
        </Box>
        <Typography variant="h5" paragraph>
          {headline}
        </Typography>
      </Box>
      <Typography variant="body1" color="text.primary">
        {text}
      </Typography>
    </Box>
  );
}

export default FeatureCard;
