import React from 'react';
import { ParagraphBox } from '../../components/ParagraphBox';

const pricingInfoText =
  'Computir bills only for the time computers are used, giving you ultimate flexibility.';

const pageContextText =
  'Computir is always working to bring more features and lower prices. Features and pricing below.';

export default function PricingContextParagraph() {
  return (
    <>
      <ParagraphBox
        title="Simple Pricing and High Performance"
        paragraph={pricingInfoText + ' ' + pageContextText}
      />
    </>
  );
}
