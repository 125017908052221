import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router, Switch } from 'react-router-dom';

// import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ComputersService from './services/computers.service';
import ComputersStore from './stores/computers.store';
import { Auth0Provider } from '@auth0/auth0-react';
import BillingService from './services/billing.service';
import BillingStore from './stores/billing.store';
import StripeService from './services/stripe.service';
import StripeStore from './stores/stripe.store';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MarketingService from './services/marketing.service';
import MarketingStore from './stores/marketing.store';

export default function ScrollToTop(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{props.children}</>;
}

// DISABLE LOGGING IN PRODUCTION
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

const services = {};
const stores = {};

stores.routerStore = new RouterStore();
const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, stores.routerStore);

services.computersService = new ComputersService(stores.routerStore);
services.billingService = new BillingService(stores.routerStore);
services.stripeService = new StripeService(stores.stripeService);
services.marketingService = new MarketingService(stores.marketingService);

stores.computersStore = new ComputersStore(services.computersService);
stores.billingStore = new BillingStore(services.billingService);
stores.stripeStore = new StripeStore(services.stripeService);
stores.marketingStore = new MarketingStore(services.marketingService);

const Root = (
  <Auth0Provider
    domain={
      process.env.REACT_APP_BRANCH === 'master'
        ? 'prod-computir.us.auth0.com'
        : 'dev-computir.us.auth0.com'
    }
    clientId={
      process.env.REACT_APP_BRANCH === 'master'
        ? 'GsVM7wQRkfjs3omACNKmz9gQ9M0R9PRR'
        : '7mgMSTmk3k3eAwqcMk3wdC6gWUR8tH7i'
    }
    audience={
      process.env.REACT_APP_BRANCH === 'master'
        ? 'https://computir-prod.com'
        : 'https://computir-dev.com'
    }
    redirectUri={window.location.origin + '?authenticated=true'}
    grantType="client_credentials"
    cookiePolicy="single-host-origin"
  >
    <Provider {...stores}>
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <App />
          </Switch>
        </ScrollToTop>
      </Router>
    </Provider>
  </Auth0Provider>
);
ReactDOM.render(Root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
