import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, Typography, Box } from '@mui/material';
import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { withRouter } from 'react-router-dom';
import SEO from '../../components/shared/SEO';
import PageTitle from '../../components/shared/PageTitle';
import { ComputersContainer } from '../../style/ComputersContainer';

@inject('stripeStore', 'routerStore')
@observer
class PaymentSuccessPage extends Component {
  // componentDidMount() {
  //     const { getAccessTokenSilently } = this.props.auth0;
  //     let parsedQueryString = queryString.parse(this.props.history.location.search); // could use .href as an alternative
  //     const stripeCheckoutSessionId = parsedQueryString["session_id"]; // TOOD this should be cleaned up
  //     if (stripeCheckoutSessionId) {
  //         this.props.stripeStore.token = getAccessTokenSilently();
  //         this.props.stripeStore.updateStripeMetaData(stripeCheckoutSessionId);
  //         this.props.stripeStore.associateStripeAuth0Accounts(stripeCheckoutSessionId);
  //     }
  // }
  render() {
    return (
      <>
        <SEO
          title={'Payment Success'}
          description={'Thank you for adding your payment method for Computir.'}
        />
        <ComputersContainer>
          <PageTitle title={'Successfully Added Payment Method'} />
          <Card
            sx={{
              borderRadius: '10px',
              marginTop: '1%',
              backgroundColor: 'cardBackground.secondary',
              width: '80%',
              left: '10%',
              '&::before': { display: 'none' },
              position: 'relative',
            }}
          >
            <Box sx={{ padding: '20px' }}>
              <Typography>
                Thank you for adding your payment method. We appreciate your
                business! As a reminder, you will be charged after a usage
                threshold is met or monthly, whichever is met first.
                <br />
                <br />
                If you have any questions, please email{' '}
                <a
                  style={{ color: '#247c87' }}
                  href="mailto:support@computir.com"
                >
                  support@computir.com
                </a>
              </Typography>
            </Box>
          </Card>
        </ComputersContainer>
      </>
    );
  }
}

export default withAuthenticationRequired(
  withRouter(withAuth0(PaymentSuccessPage)),
  {},
);
