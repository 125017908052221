import BaseHttpService from './base-http.service';

export default class StripeService extends BaseHttpService {

  getStripeCheckoutUrl(baseUrl) {
    return this.post('stripe/get/', { "baseUrl": baseUrl });
  }

  updateStripeMetaData(stripeCheckoutSessionId) {
    return this.post('stripe/update/', { "stripeCheckoutSessionId": stripeCheckoutSessionId });
  }

  getPaymentVerifiedStatus(user) {
    return this.post('stripe/get/paymentVerified/', {})
  }

  associateStripeAuth0Accounts(stripeCheckoutSessionId) {
    return this.post('stripe/associate/', {"stripeCheckoutSessionId": stripeCheckoutSessionId })
  }

  getStripePortalUrl() {
    return this.post('stripe/get/portal', {})
  }
  
}
