import * as React from 'react';
import { Box, Grid, Typography, Button, Slide } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import '../../style/css/HeadSection.css';
// import galaxyBackground from '../../assets/galaxyBackground.png'
// import MovingClouds from './ShootingStars';
import TopSummarySection from './TopSummarySection';
import { mainBlue, darkGray } from '../../theme';
import loginWithTags from '../../components/shared/loginWithTags';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function usePrevious(value) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function HeadSection() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [checked, setChecked] = React.useState(true);
  const [mounted, setMounted] = React.useState(0);
  const [randInt, setRandInt] = React.useState(0);
  const prevRandInt = usePrevious(randInt);
  const containerRef = React.useRef(null);
  const stringList = [
    'gaming computer',
    'streaming setup',
    'workstation',
    'video encoder',
  ];

  function randomIntFromInterval(min, max, prevNumber) {
    let x = Math.floor(Math.random() * (max - min + 1) + min);
    while (x === prevNumber) {
      x = Math.floor(Math.random() * (max - min + 1) + min);
    }
    return x;
  }

  let history = useHistory();

  React.useEffect(() => {
    // let isMounted = true
    async function handleTransition() {
      const test = checked;
      setChecked(!test);
      await delay(500);
      // TODO make this not select previous number
      setRandInt(randomIntFromInterval(0, stringList.length - 1), prevRandInt);
      setChecked(test);
    }
    // TODO handle the react warning here
    if (mounted === 0) {
      setInterval(() => handleTransition(), 5000);
      setMounted(mounted + 1);
    }
    return () => {
      setMounted(1);
    };
  }, [mounted, checked, prevRandInt, stringList.length]);

  // const Icon = ({fill }) => (
  //   <svg style={{display: "block"}} viewBox="0 0 1440 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
  //     <path fill={fill} fillOpacity="1" d="
  //                 M 0,50
  //                 L 80,45
  //                 C 160,70, 320,72, 480,78
  //                 C 640,82, 800,84, 960,86
  //                 C 1120,85, 1280,80, 1360,50
  //                 L 1440,52
  //                 L 1440,100
  //                 L 1360,100
  //                 C 1280,100, 1120,100, 960,100
  //                 C 800,100, 640,100, 480,100
  //                 C 320,100, 160,100, 80,100
  //                 L 0,100
  //                 Z"></path>
  // </svg>
  // );

  return (
    <>
      {/* <Card> */}
      {/* backgroundImage: `url(${galaxyBackground})` */}
      {/* <Box sx={{ backgroundColor: `black`, padding: '2% 0px 5% 0px' }}> */}
      {/* {localStorage.getItem('theme') === 'dark' ? */}
      <Box
        sx={{
          background: `linear-gradient(to right bottom, ${mainBlue}, ${
            localStorage.getItem('theme') === 'dark' ? darkGray : '#f1f1f1'
          })`,
          paddingTop: '2.5%',
        }}
      >
        <TopSummarySection />
        <svg
          style={{ display: 'block', position: 'relative', bottom: '-1.5px' }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 100"
        >
          <path
            fill={
              localStorage.getItem('theme') === 'dark' ? '#000000' : '#ffffff'
            }
            fillOpacity="1"
            d="
                  M 0,0
                  C 50,20, 50,30, 200,50
                  C 200,50, 300,60, 400,80
                  C 450,100, 700,60, 1000,100
                  C 1100,90, 1300,90, 1440,0
                  L 1440,100
                  L 0,100
                  Z"
          ></path>
        </svg>
      </Box>
      {/* </Card> */}
      {/* TODO could break into own file */}
      <Box
        className="head-section-2-box"
        sx={{
          flexGrow: 1,
          backgroundColor: 'cardBackground.default',
          left: { xs: '5%', sm: '10%' },
          width: { xs: '90%', sm: '80%' },
        }}
      >
        <Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
              <Typography variant={'h6'} color="text.primary">
                Connect to top-of-the-line computers directly from your browser.
                Play the latest games from the computer browsing this site.{' '}
              </Typography>
              {isAuthenticated ? (
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className="head-section-2-button"
                  onClick={() => history.push('/computirs')}
                >
                  Get started!
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className="head-section-2-button"
                  onClick={() => loginWithTags(loginWithRedirect)}
                  sx={{
                    color: 'text.dark',
                    display: { xs: 'none', sm: 'inline-flex' },
                  }}
                >
                  Sign up now
                </Button>
              )}
              <div className="head-section-2-learn-more">
                <Button
                  onClick={() => history.push('/specs')}
                  variant="contained"
                  sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
                >
                  Learn more
                </Button>
                <Button
                  onClick={() => history.push('/specs')}
                  variant="text"
                  sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
                >
                  Learn more
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              ref={containerRef}
              style={{ textAlign: 'center' }}
            >
              <Typography variant={'h4'}>
                With Computir, you always have the most up-to-date
                <br />
                <Slide
                  direction="up"
                  in={checked}
                  container={containerRef.current}
                  easing={{
                    enter: 'cubic-bezier(0, 1.5, .8, 1)',
                    exit: 'linear',
                  }}
                >
                  <p>{stringList[randInt]}</p>
                </Slide>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* TODO could break into own file */}
    </>
  );
}

export default HeadSection;
