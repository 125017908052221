import React, { Component } from 'react';
import {
  Box,
  Alert,
  Card,
  CardContent,
  IconButton,
  Button,
  Typography,
  Switch,
} from '@mui/material';
import styled from 'styled-components';
import { inject } from 'mobx-react';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import RdpButton from './RdpButton';
import TerminateDialog from '../../components/TerminateDialog';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import EstimatedCountdown, {
  checkIfLessThanTwentyMinsSinceCreation,
} from '../../components/EstimatedCountdown';
import { COMPUTIR_TYPES } from '../create-computer/computirTypes';
import UserFeedback from '../../components/UserFeedback';

const CardContainer = styled.div`
  margin-bottom: 20px;
  width: 90%;
  position: relative;
  left: 5%;
`;

export const CardTitle = styled.h1`
  margin: 8px 0;
  font-size: 22px;
`;

function getEstimatedMinutes(computerType) {
  // TODO - do this programatically form COMPUTIR_TYPES
  const fastGpuInstances = [
    'computir_quadro-windows',
    'computir_quadro-linux',
    'computir_rtx_5000-windows',
    'computir_rtx_5000-linux',
    'computir_rtx_a5000-windows',
    'computir_rtx_a5000-linux',
  ];
  const fastCpuInstances = ['computir_xeon_v3-windows'];
  const awsCpuInstances = ['Computir Lite'];
  const awsGpuInstances = ['Computir Pro'];
  const timeMapping = new WeakMap();
  timeMapping[fastGpuInstances] = 2;
  timeMapping[fastCpuInstances] = 2;
  timeMapping[awsCpuInstances] = 3;
  timeMapping[awsGpuInstances] = 17;
  let response = 0;
  Object.entries(timeMapping).forEach(([instanceTypeArray, configTime]) => {
    if (instanceTypeArray.includes(computerType)) {
      response = configTime;
    }
  });
  return response;
}

export function ComputerCardButton({ onClick, buttonText, disabled }) {
  return (
    <Button
      onClick={() => onClick()}
      variant={'contained'}
      disabled={disabled}
      sx={{
        color: 'text.primary',
        borderColor: 'text.secondary',
        backgroundColor: 'text.secondary',
        '&:hover': { backgroundColor: 'transparent' },
      }}
    >
      {buttonText}
    </Button>
  );
}

function translateComputerPassword(pw) {
  if (
    pw ===
    'Wait at least 6 mins after creation before the admin password is available'
  ) {
    return 'Please wait for your Computir to finish configuring.';
  } else {
    return pw;
  }
}

function translateComputerType(computerType) {
  return COMPUTIR_TYPES[computerType]['displayName'];
}

@inject('computersStore')
class Computer extends Component {
  state = {
    displayPassword: false,
    openSnackbarMsg: false,
    showUserFeedback: false,
  };

  handleStatusChange = async (e) => {
    await this.props.computersStore.updateComputerStatus(
      this.props.computer_name,
      e.target.value,
    );
    if (this.props.status === 'stopped') {
      this.setState({ showUserFeedback: true });
    }
  };

  handleFetchComputerPassword = () => {
    if (this.props.computerPassword) {
      this.setState({ displayPassword: true });
    } else {
      this.props.computersStore.fetchComputerPassword(this.props.computer_name);
      this.setState({ displayPassword: true });
      return this.props.computerPassword;
    }
  };

  handleClosePasswordAlert = () => {
    this.setState({ displayPassword: false });
  };

  handleTerminateComputer = () => {
    this.props.computersStore.terminateComputer(this.props.computer_name);
  };

  handleCopyPassword = () => {
    if (
      typeof this.props.computerPassword !== 'undefined' &&
      this.props.computerPassword !==
        'Please wait for your Computir to finish configuring.'
    ) {
      navigator.clipboard.writeText(this.props.computerPassword);
      this.setState({
        openSnackbarMsg: true,
      });
    }
  };

  handleCloseSnackbar = (event, reason) => {
    this.setState({
      openSnackbarMsg: false,
    });
  };

  render() {
    const {
      computer_name,
      status,
      ipAddress,
      actionState,
      computerPassword,
      computer_type,
    } = this.props;
    let ipIndicator;
    let checked;

    const creationTime = window.localStorage.getItem(computer_name);
    const showFlag = checkIfLessThanTwentyMinsSinceCreation(creationTime);

    const statusColorMap = {
      stopped: 'gray',
      stopping: 'gray',
      terminated: 'red',
      'shutting-down': 'red',
      running: 'green',
      pending: 'yellow',
    };

    if (
      status === 'stopped' ||
      status === 'stopping' ||
      status === 'terminated' ||
      status === 'shutting-down'
    ) {
      ipIndicator = '';
      checked = false;
    } else if (status === 'running') {
      const displayIp =
        JSON.stringify(ipAddress) === 'null' ? 'loading...' : ipAddress;
      ipIndicator = `${displayIp} `;
      checked = true;
    } else if (status === 'pending') {
      ipIndicator = '';
      checked = true;
    }

    return (
      <CardContainer>
        <Card elevation={5}>
          <CardContent>
            <Box display={'flex'}>
              <Box width={'100%'}>
                <Typography variant="h4" sx={{ marginBottom: '5%' }}>
                  {computer_name}
                </Typography>
                <Typography display="inline">Status: </Typography>
                <Typography
                  fontFamily={'monospace'}
                  display="inline"
                  color={statusColorMap[status]}
                >
                  {status}
                </Typography>
                <br />
                {status === 'terminated' || status === 'shutting-down' ? (
                  <></>
                ) : (
                  <>
                    <Typography display="inline">Type: </Typography>
                    <Typography fontFamily={'monospace'} display="inline">
                      {translateComputerType(computer_type)}
                    </Typography>
                  </>
                )}
                <br />
                {status === 'terminated' || status === 'shutting-down' ? (
                  <></>
                ) : (
                  <>
                    <Typography display="inline">IP Address: </Typography>
                    <Typography fontFamily={'monospace'} display="inline">
                      {ipIndicator}
                    </Typography>
                  </>
                )}
                <br />
                {status === 'terminated' || status === 'shutting-down' ? (
                  <></>
                ) : (
                  <>
                    <Typography display="inline">Username: </Typography>
                    <Typography fontFamily={'monospace'} display="inline">
                      ComputirUser
                    </Typography>
                  </>
                )}
                <br />
                {status === 'running' || status === 'stopped' ? (
                  <Tooltip title={checked ? 'Stop Computir' : 'Start Computir'}>
                    <Switch
                      checked={checked}
                      disabled={actionState === 'pending' ? true : false}
                      onClick={this.handleStatusChange}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
                {this.state.showUserFeedback ? <UserFeedback /> : null}
                {this.state.displayPassword && status === 'running' ? (
                  <IconButton disableRipple>
                    <Alert
                      severity={'info'}
                      sx={{
                        width: 'fit-content',
                        left: '20%',
                      }}
                      icon={
                        <ContentCopyIcon
                          onClick={() => {
                            this.handleCopyPassword();
                          }}
                          fontSize="inherit"
                        />
                      }
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.handleClosePasswordAlert();
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {computerPassword
                        ? translateComputerPassword(computerPassword)
                        : 'loading...'}
                    </Alert>
                  </IconButton>
                ) : null}
                <Snackbar
                  open={this.state.openSnackbarMsg}
                  autoHideDuration={3000}
                  onClose={this.handleCloseSnackbar}
                  message="Copied password to clipboard"
                />
              </Box>
              <Box width="100%" display="inline-flex">
                <Box width="100%" style={{ paddingTop: '2%' }}>
                  {(status === 'running' || status === 'pending') &&
                  showFlag ? (
                    <EstimatedCountdown
                      estimatedMinutes={getEstimatedMinutes(computer_type)}
                      startTime={creationTime}
                    />
                  ) : null}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  flexDirection="column"
                  width="50%"
                >
                  {status === 'running' ? (
                    <RdpButton
                      ipAddress={ipAddress}
                      computerName={computer_name}
                      actionState={actionState}
                    />
                  ) : null}
                  <br />
                  {/* {status === 'running' ? (
                    <Tooltip title="Password">
                      <ComputerCardButton
                        onClick={this.handleFetchComputerPassword}
                        buttonText={'Password'}
                      />
                    </Tooltip>
                  ) : null} */}
                  <br />
                  <br />
                  {status === 'Error Creating' ? (
                    <></>
                  ) : (
                    <TerminateDialog
                      store={this.props.computersStore}
                      computerName={computer_name}
                      actionState={actionState}
                      computerStatus={status}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </CardContent>
          {actionState === 'pending' ? (
            <Box sx={{ width: '100%' }} active={false}>
              <LinearProgress />
            </Box>
          ) : null}
        </Card>
      </CardContainer>
    );
  }
}

export default Computer;
