import React from 'react';
import SEO from '../../components/shared/SEO';

const termsString = `
TERMS OF SERVICE
Revised: December 30, 2022
1. ACCEPTANCE OF TERMS
Computir Inc. (“Computir”) provides technology-enabled services, including the Software as a Service offering branded as Computir, the website at https://www.computir.com, any Mobile Apps (as defined below), and other related software, content, and services, including all versions and upgrades thereto (collectively, the “Services”).  Your use of the Services is subject to and governed by the terms and conditions in this Terms of Service (“TOS”). Computir may, at its discretion, update this TOS at any time. You can access and review the most current version of this TOS at the URL for this page or by clicking on the “Terms of Service” link within the Services, or as otherwise made available by Computir.
PLEASE REVIEW THIS TOS CAREFULLY. BY REGISTERING FOR AN ACCOUNT OR OTHERWISE ACCESSING OR USING THE SERVICES, YOU AGREE TO BE BOUND BY THIS TOS, INCLUDING ANY UPDATES OR REVISIONS POSTED HERE OR OTHERWISE COMMUNICATED TO YOU. IF YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS OF THIS TOS, YOU MAY NOT ACCESS OR USE THE SERVICES.
THIS TOS REQUIRES FINAL AND BINDING ARBITRATION TO RESOLVE ANY DISPUTE OR CLAIM ARISING OUT OF OR RELATING IN ANY WAY TO THIS TOS, OR YOUR ACCESS TO OR USE OF THE SERVICES, INCLUDING THE VALIDITY, APPLICABILITY OR INTERPRETATION OF THIS TOS, AND YOU AGREE THAT ANY SUCH CLAIM WILL BE RESOLVED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION, ARBITRATION OR OTHER SIMILAR PROCESS. PLEASE REVIEW SECTION 15 CAREFULLY TO UNDERSTAND YOUR RIGHTS AND OBLIGATIONS WITH RESPECT TO THE RESOLUTION OF ANY CLAIM. 
You represent and warrant that you: (a) are of legal age to form a binding contract; (b) have the right, authority, and capacity to agree to and abide by this TOS; and (c) are not a person barred from using the Services under the laws of any applicable jurisdiction. THE SERVICES ARE NOT INTENDED FOR USERS UNDER THE AGE OF 13, AND SUCH USERS ARE EXPRESSLY PROHIBITED FROM SUBMITTING ANY PERSONAL DATA OR USING ANY ASPECT OF THE SERVICES, AND BY TAKING SUCH ACTIONS YOU AGREE, REPRESENT, AND WARRANT THAT YOU ARE LEGAL AGE OF MAJORITY IN THE COUNTRY IN WHICH THE SERVICE IS USED.  IF YOU ARE UNDER THE LEGAL AGE OF MAJORITY, YOU MUST ASK A PARENT OR LEGAL GUARDIAN TO CONSENT TO THIS TOS.  


2. RIGHTS
   1. Grant. Subject to and conditioned on your compliance with this TOS, Computir hereby grants you a personal, non-exclusive, non-transferable, non-sublicensable, revocable license solely to use and access the Services only for your personal, non-commercial use for your use with high performance computer or remote desktop needs. Your access to and use of the Services must further comply in all material respects with all usage guidelines posted by Computir.  
Subject to and conditioned on your compliance with this TOS, Computir will provide you with reasonable technical support services via email.
   2. Mobile Apps. Computir may make available mobile software applications for access to and use of certain components of the Services (collectively, “Mobile Apps”). Your access to and use of Mobile Apps is subject to and governed by this TOS. If any Mobile App is downloaded by you from the iTunes App Store (each, an “iOS Mobile App”), Google Play Store (each, an “Android Mobile App”) or other app store provider, your use of such iOS Mobile App, Android Mobile App or other app, as applicable, is further subject to your compliance in all material respects with the terms and conditions of (i) the Usage Rules set forth in the iTunes App Store Terms of Service, (ii) Google Play Store Terms of Service or (iii) other app store provider’s terms of service, as applicable. This TOS is between you and Computir only, and not with Apple Inc. (“Apple”), Google LLC (“Google”) or any other app store provider, and Apple, Google or such other app store provider is not responsible for iOS Mobile Apps, Android Mobile Apps or such other Mobile Apps, as applicable, and the contents thereof; however, Apple and Apple’s subsidiaries, Google and Google’s subsidiaries or such other app store provider and its subsidiaries are third-party beneficiaries of this TOS with respect to iOS Mobile Apps, Android Mobile Apps or Mobile Apps, as applicable.
To use the Mobile App, you must have a mobile device that is compatible with the Mobile App. Computir does not warrant that the Mobile App will be compatible with your mobile device. You may use mobile data in connection with the Mobile App and incur additional charges from your wireless provider for these services. You agree that you are solely responsible for such charges.
   3. Trademarks.  You may not use the Computir, names, brands, trademarks, service marks and logos that Computir makes available on the Services (“Marks”). Computir claims trademark protection over all such Marks and you will not use the Marks except as expressly authorized herein. You will not remove or alter the Marks or any proprietary notices on the Services. The Marks may not be included in or as part of any registered corporate name, any other logo, or service or product name.  You may not create any derivative works of the Marks or use the Marks in a manner that creates or reasonably implies an inaccurate sense of endorsement, sponsorship, or association with Computir.  You will not otherwise use business names or logos in a manner that can mislead, confuse, or deceive any third party. All use of the Marks and all goodwill arising out of such use, will inure to Computir’s benefit
   4. Performance. A number of factors may affect your use of the Service, or may impede or interfere with your ability to start or continue using the Service or certain content or may result in the loss of data such as game saves, and you understand that Computir is not responsible for these: (i) any factors or circumstances outside of Computir’s reasonable control, including issues with your device, telecommunications, firewall, your service providers, or the public internet; (ii) the type of device you have, your location, the available bandwidth, or the speed of your internet connection, and in some cases Computir may decrease the resolution and bitrate of the content streamed to you in an effort to provide a better user experience; or (ii) other reasonable technical reasons, errors, unexpected demand for a service, or bandwidth constraints.
3. PRIVACY POLICY
In addition to this TOS, the Computir Privacy Policy at https://www.computir.com/privacy (“Privacy Policy”) applies to how Computir may process information provided as part of the Services. You acknowledge and agree that by accessing or using the Services, Computir may receive certain information about you, including personal information, as set forth in the Privacy Policy, and Computir may collect, use, disclose, store, share, and process such personal information in accordance with such Privacy Policy. 
4. PROPRIETARY RIGHTS
   1. You grant Computir and its service providers a perpetual, irrevocable, worldwide, royalty-free, fully-paid-up, non-exclusive, sublicensable, transferable license to use, reproduce, modify, adapt, create derivative works from, publicly perform, publicly display, distribute, make and have made all content (in any form and any medium, whether now known or later developed) that you provide in connection with the Services. You acknowledge and agree that the technical processing and transmission of data associated with the Services, may require: (i) transmissions over various networks and across borders; and (ii) modifications to conform, connect, and adapt to technical requirements of networks or devices.
   2. The Services provided to you hereunder or available to you through the Services are licensed, not sold, and Computir retains and reserves all rights not expressly granted in this TOS. You acknowledge and agree that, as between you and Computir, Computir and its licensors owns all rights, title and interest (including all intellectual property rights) in the Services and all data, content, and other materials within the Services. The Services are protected by U.S. and international copyright and other intellectual property laws and treaties. Computir reserves all rights not expressly granted to you in this TOS.
   3. You may not share your account or password with anyone. You are fully responsible for all activities that occur under your account. You agree to notify Computir immediately of any unauthorized use of your account or password or any other similar breach of security. If your account remains inactive for three months or longer, Computir reserves the right to suspend or terminate your account, with or without notice to you, and delete your content all without liability.
5. CONTENT
   1. Content. Computir is licensing you the Services for high performance computer or remote desktop needs, and it is your responsibility to have sufficient rights to use the content (i.e. third-party video games or DLC (downloadable content)) in connection with the Services. Certain content may only work on certain types of devices. Not all the content you have may be used when streaming from the Services. When you obtain content the content provider may require you to accept their terms, open a user account and/or may charge you for the content.
   2. Content Management. You acknowledge and agree that Computir may, but is not obligated to, block, monitor, scan or review communications or content transmitted through the Services and that Computir reserves the right to stop communications or remove content as described in Sections 13 and/or 18. Computir accepts no responsibility or liability for content from other parties (yours or of content providers) regardless of whether such content is transmitted to or by you in breach of this TOS.
6. USER CONDUCT AND RESTRICTIONS
   1. In your use of the Services, you will not: 
      1. use, reproduce, modify, adapt, create derivative works from, sublicense, publicly perform, publicly display, distribute, sell, lease, rent, make, have made, assign, pledge, transfer or otherwise grant rights to the Services, except as expressly permitted under this TOS; 
      2. reverse engineer, disassemble, decompile, translate, or otherwise attempt to derive trade secrets, algorithms, or the source code, architectural framework, or data records, within or associated with the Services; 
      3. interfere with or disrupt the integrity or performance of the Services, including by disrupting the ability of any other person to use or enjoy the Services; 
      4. use the Services commercially, for virtual item mining, botting or farming, for character level up botting, or for crypto currency mining;
      5. provide use of the Services on a service bureau, rental or managed services basis, provide or permit other individuals or entities to create Internet "links" to the Services or "frame" or "mirror" the Services on any other server, or wireless or Internet-based device; 
      6. access the Services for the purpose of developing, marketing, selling or distributing any product or service that competes with or includes features substantially similar to the Services; 
      7. violate any applicable local, state, provincial, federal or international law or regulation, or use the Services for any illegal, unauthorized or otherwise improper purposes, including to store or transmit malicious code, or to store or transmit material in violation of third-party privacy rights; 
      8. remove or obscure any proprietary notice that appears within the Services;
      9. impersonate any person or entity, including Computir personnel, or falsely state or otherwise misrepresent your affiliation with Computir, or any other entity or person;
      10. forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted through the Services;
      11. take any action that imposes an unreasonable or disproportionately heavy load on the Services or its infrastructure; or
      12. use spiders, crawlers, robots, scrapers, automated tools or any other similar means to access the Services; or download, reproduce, or archive any substantial portion of the Services.
   2. You will not: upload, post, email, store, transmit, or otherwise make available any content that: 
      1. is illegal, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or otherwise objectionable; 
      2. may not be made available under any law or under contractual or fiduciary relationships (such as confidential or proprietary information learned as part of an employment relationship or under a non-disclosure agreement); 
      3. infringes any patent, trademark, trade secret, copyright, or other proprietary right of any party; 
      4. consists of unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, commercial electronic messages, or any other form of solicitation; 
      5. contains software viruses or any other code, files or programs designed to interrupt, destroy or limit the functionality of any software or hardware; 
      6. contains infringing, libelous, or otherwise unlawful or tortious material; or 
      7. consists of information that you know or have reason to know is false or inaccurate.
   3. Computir’s failure to enforce any of these restrictions or guidelines shall not act as a waiver for any future enforcement, will not be considered a breach of this TOS by Computir, and does not create a private right of action for any other party.
7. FEES AND PAYMENT
   1. Fees and Payment. Fees for the Services are calculated monthly by Computir based upon your account usage and subscriptions. You will be charged and must pay all calculated fees and charges monthly. Computir reserves the option to bill you more frequently for fees accrued if Computir suspects that your account is fraudulent or at risk of non-payment. Monthly calculations are based upon the pricing and charges for your account use of the Services as listed on our website and/or within the Services. All amounts payable by you under this TOS will be paid to Computir without deductions or withholdings and without setoff or counterclaim. For any new Service or new feature of the Service, fees will be effective when Computir posts updated fees and charges on our website and/or within the Services, unless we expressly state otherwise in a notice. Computir may increase or add new fees and charges for any existing Services you are using by giving you at least 30 days’ prior notice. Unpaid amounts are subject to a finance charge of one and a half percent (1.5%) per month on any outstanding balance, or the maximum permitted by law, whichever is lower, plus all expenses of collection. Your failure to pay fees in a timely manner as per the terms of this TOS will be deemed a material breach of this TOS, which may result in immediate termination of the Services by Computir in Computir’s sole discretion and determination.
   2. Taxes.  Unless otherwise noted, your use of the Services is subject to state and local sales tax in accordance with applicable laws. Computir may not collect sales or use taxes in all states. For states imposing sales or use taxes, your purchase is subject to use tax unless it is specifically exempt from taxation. Your subscription to the Services is not exempt merely because it is made over the Internet or by other remote means. Many states require subscribers to file a sales/use tax return at the end of the year reporting all of the taxable subscriptions that were not taxed and to pay tax on those subscriptions. Details of how to file these returns may be found at the websites of your respective taxing authorities.  The sales tax indicated on the checkout page is an estimate. The sales tax ultimately charged to your credit card will be calculated when your credit card charge is authorized and will reflect applicable state and local taxes.
8. FEEDBACK
If you elect to provide or make available to Computir any suggestions, comments, ideas, improvements or other feedback relating to the Services (“Feedback”), Computir shall own and be free to use, reproduce, modify, adapt, create derivative works from, publicly perform, publicly display, distribute, make, have made, assign, pledge, transfer or otherwise grant rights in your Feedback in any form and any medium (whether now known or later developed), without credit or compensation to you.
9. DEALINGS WITH ADVERTISERS AND OTHER THIRD PARTIES
The Services may include or provide access to third party products, services, content, or offerings, including advertising for such (“Third Party Services”).  You acknowledge that different terms of use and privacy policies may apply to your use of such Third Party Services and that terms and policies are solely between you and the advertiser or other third party. You agree that does not endorse and is not responsible or liable for any issues related to Third Party Services. 
10. INDEMNIFICATION
You shall indemnify and hold Computir and its affiliates, and each of their officers, directors, employees, agents, partners and licensors (collectively, “Computir Parties”) harmless from and against all losses, damages, costs, liabilities, and expenses, including reasonable attorneys’ fees, to extent resulting from or arising out of any third party claim, demand, or action due to (a) content you provide to Computir; (b) your violation of this TOS, any law or regulation, or any rights (including intellectual property rights) of another party; or (c) your use of the Services, except as expressly permitted in this TOS.
11. DISCLAIMER OF WARRANTIES
   1. YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPUTIR PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED OR ARISING FROM STATUTE, COURSE OF DEALING, USAGE OF TRADE OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
   2. COMPUTIR PARTIES MAKE NO WARRANTY OR REPRESENTATION THAT: (i) THE SERVICES WILL MEET YOUR REQUIREMENTS; (ii) ACCESS TO THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, VIRUS-FREE, OR ERROR-FREE; OR (iii) THE INFORMATION AND ANY RESULTS THAT MAY BE OBTAINED FROM ACCESS TO OR USE OF THE SERVICES WILL BE ACCURATE, RELIABLE, CURRENT, OR COMPLETE.
   3. ALL CONTENT MADE AVAILABLE THROUGH THE SERVICES IS MADE AVAILABLE FOR INFORMATIONAL PURPOSES ONLY. YOU ARE SOLELY RESPONSIBLE FOR CONFIRMING THE ACCURACY OF ALL CONTENT BEFORE TAKING OR OMITTING ANY ACTION.
   4. THIS DISCLAIMER OF WARRANTY MAY NOT BE VALID IN SOME JURISDICTIONS AND YOU MAY HAVE WARRANTY RIGHTS UNDER LAW WHICH MAY NOT BE WAIVED OR DISCLAIMED.  ANY SUCH WARRANTY EXTENDS ONLY FOR THIRTY (30) DAYS FROM THE EFFECTIVE DATE OF THIS AGREEMENT (UNLESS SUCH LAW PROVIDES OTHERWISE).
12. LIMITATION OF LIABILITY
   1. COMPUTIR PARTIES SHALL NOT BE LIABLE FOR ANY LOST PROFITS, LOSS OF DATA OR GOODWILL, OR COST OF COVER, OR FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING DAMAGES ARISING FROM ANY TYPE OR MANNER OF COMMERCIAL, BUSINESS, OR FINANCIAL LOSS, EVEN IF COMPUTIR PARTIES HAD ACTUAL OR CONSTRUCTIVE KNOWLEDGE OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE. IN NO EVENT SHALL COMPUTIR PARTIES’ TOTAL LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING FROM OR RELATING TO THIS TOS OR YOUR ACCESS TO OR USE OF (OR INABILITY TO ACCESS OR USE) THE SERVICES OR CONTENT EXCEED THE AMOUNT PAID BY YOU TO COMPUTIR FOR ACCESS TO THE SERVICES WITHIN THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH THE APPLICABLE CLAIM(S) AROSE.
   2. THE FOREGOING LIMITATIONS, EXCLUSIONS AND DISCLAIMERS SHALL APPLY REGARDLESS OF WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.  INSOFAR AS APPLICABLE LAW PROHIBITS ANY LIMITATION ON LIABILITY HEREIN, THE PARTIES AGREE THAT SUCH LIMITATION WILL BE AUTOMATICALLY MODIFIED, BUT ONLY TO THE EXTENT SO AS TO MAKE THE LIMITATION COMPLIANT WITH APPLICABLE LAW.  THE PARTIES AGREE THAT THE LIMITATIONS ON LIABILITIES SET FORTH HEREIN ARE AGREED ALLOCATIONS OF RISK AND SUCH LIMITATIONS WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
13. SUSPENSION AND TERMINATION
   1. Either party may terminate this TOS at any time.
   2. If you violate this TOS, Computir may, with or without notice to you, immediately suspend or terminate your access and use of the Services.
   3. Computir reserves the right at any time to modify, suspend, or discontinue the Services (or any portion thereof) with or without notice, and Computir shall not be liable to you or any third party for any such modification or discontinuance;
   4. Upon termination of this TOS for any reason: (i) Computir, in its sole discretion, may remove and discard your content and information; (ii) you will immediate cease your use of the Services; and (iii) any provision that, by its terms, is intended to survive the expiration or termination of this TOS shall survive such expiration or termination.  Further, you agree that that Computir shall not be liable to you or any third party for any termination of your account or access to the Services. 
14. GOVERNING LAW
This TOS shall be governed by and construed and enforced in accordance with the United States Federal Arbitration Act, other applicable federal laws and the laws of the State of Texas, without regard to conflict of laws principles. The parties agree that neither the United Nations Convention on Contracts for the International Sale of Goods, nor the Uniform Computer Information Transaction Act (UCITA) shall apply to this TOS, regardless of the states in which the parties do business or are incorporated.
15. BINDING ARBITRATION AND CLASS ACTION WAIVER
   1. ALL CLAIMS ARISING IN CONNECTION WITH THIS TOS SHALL BE RESOLVED BY FINAL AND BINDING ARBITRATION RATHER THAN IN COURT, EXCEPT THAT YOU MAY ASSERT CLAIMS IN SMALL CLAIMS COURT (DEFINED FOR THE PURPOSES OF THIS TOS AS A COURT OF LIMITED JURISDICTION THAT MAY ONLY HEAR CLAIMS NOT EXCEEDING $5,000) IF YOUR CLAIMS ARE WITHIN THE COURT’S JURISDICTION. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.
   2. The arbitration shall be conducted by the American Arbitration Association (AAA) under its then-applicable Commercial Arbitration Rules or, as appropriate, its Consumer Arbitration Rules. The AAA’s rules are available at http://www.adr.org/. Payment of all filing, administration and arbitrator fees shall be governed by the AAA’s rules. The arbitration shall be conducted in the English language by a single independent and neutral arbitrator. For any hearing conducted in person as part of the arbitration, you agree that such hearing shall be conducted in Austin, Texas or, if the Consumer Arbitration Rules apply, another location reasonably convenient to both parties with due consideration of their ability to travel and other pertinent circumstances, as determined by the arbitrator. The decision of the arbitrator shall be final and binding. Judgment on the arbitral award may be entered in any court of competent jurisdiction.
   3. WE EACH AGREE THAT ALL CLAIMS SHALL BE RESOLVED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION OR OTHER SIMILAR PROCESS (INCLUDING ARBITRATION). IF FOR ANY REASON A CLAIM PROCEEDS IN COURT RATHER THAN IN ARBITRATION, WE EACH WAIVE ANY RIGHT TO A JURY TRIAL AND AGREE THAT SUCH CLAIM SHALL BE BROUGHT ONLY IN A COURT OF COMPETENT JURISDICTION IN AUSTIN, TEXAS. YOU HEREBY SUBMIT TO THE PERSONAL JURISDICTION AND VENUE OF SUCH COURTS AND WAIVE ANY OBJECTION ON THE GROUNDS OF VENUE, FORUM NON-CONVENIENS OR ANY SIMILAR GROUNDS WITH RESPECT TO ANY SUCH CLAIM.
   4. Notwithstanding anything to the contrary, you and Computir may seek injunctive relief and any other equitable remedies from any court of competent jurisdiction to protect its intellectual property rights, whether in aid of, pending, or independently of the resolution of any dispute pursuant to the arbitration procedures set forth in this Section 15.
   5. If Computir implements any material change to this Section 15, such change shall not apply to any Claim for which you provided written notice to Computir before the implementation of the change.
16. LEGAL COMPLIANCE
You represent and warrant that you will comply with all applicable foreign, federal, state, and local laws, rules and regulations, including without limitation, U.S. export laws and import and use laws of the country where the Services are accessed or used and you are not: (a) located in a country that is subject to a U.S. Government embargo, or designated by the U.S. Government as a “terrorist supporting” country; and (b) listed on any U.S. Government list of prohibited or restricted parties, including the Specially Designated Nationals List.
17. CALIFORNIA USERS & RESIDENTS
In accordance with California Civil Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting such unit in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (800) 952-5210.
18. GENERAL PROVISIONS
This TOS constitutes the entire agreement between you and Computir concerning your access to and use of the Services. It supersedes all prior and contemporaneous oral or written negotiations and agreements between you and Computir with respect to such subject matter. In the event of any conflict between or among this TOS and any end user license agreement, privacy policy or usage guidelines to which this TOS refers, the terms and conditions of this TOS shall take precedence and govern.  This TOS may not be amended by you except in a writing executed by you and an authorized representative of Computir. Except as otherwise expressly provided in this TOS, there shall be no third-party beneficiaries to this TOS. For the purposes of this TOS, the words “such as,” “include,” “includes” and “including” shall be deemed to be followed by the words “without limitation.” You may not assign or delegate any right or obligation under this TOS without the prior written consent of Computir. The failure of Computir to exercise or enforce any right or provision of this TOS shall not constitute a waiver of such right or provision. If any provision of this TOS is held to be invalid or unenforceable under applicable law, then such provision shall be construed, limited, modified or, if necessary, severed to the extent necessary to eliminate its invalidity or unenforceability, without in any way affecting the remaining parts of this TOS. Any prevention of or delay in performance by Computir hereunder due to labor disputes, acts of god, failure of the Internet, governmental restrictions, enemy or hostile governmental action, fire or other casualty or other causes beyond its reasonable control shall excuse the performance of its obligations for a period equal to the duration of any such prevention or delay.
`

export default function Terms() {
  return (
      <>
         <SEO
         title={'Terms of Service'}
         description={'Page containing Computir Terms of Service.'} />
         <pre style={{whiteSpace: 'pre-wrap', paddingLeft: '5%', paddingRight: '5%'}}>{termsString}</pre>
      </>
  )
}