import React from 'react';
import { Typography, Card, Box } from '@mui/material';
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import '../../style/css/PricingSection.css';

function PricingSection() {
  let features = [
    'Powerful hardware',
    'Always on demand',
    'Only pay for what you use',
  ];

  return (
    <Card
      sx={{ backgroundColor: 'cardBackground.default', padding: '2% 8% 2% 8%' }}
      className="pricing-section-container"
    >
      <Typography
        fontWeight="bold"
        variant="h3"
        align="center"
        className="lg-mg-bottom"
      >
        Pricing
      </Typography>
      <br />
      <Typography>
        With Computir, you only pay for the compute and storage time you use -
        never a subscription fee. Computir allows you to create and use any type
        of cloud server that you want, starting and stopping any time. Use for
        as long or as short as you want - only paying for your usage of the
        Computir, or a nominal hourly fee when it is stopped. When you are done
        gaming or creating, come back to create a new Computir any time.
      </Typography>
      <br />
      {features.map((feature, index) => (
        <Box display="flex" alignItems="center" mb={1} key={index}>
          <CheckCircleRounded />
          <Box ml={1}>
            <Typography>{feature}</Typography>
          </Box>
        </Box>
      ))}
    </Card>
  );
}

export default PricingSection;
