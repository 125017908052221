import React from 'react';
import BillingCard from '../billing/BillingCard';
import Box from '@mui/material/Box';

const sampleMonthYear = '2023-01-01 - 2023-02-01';
const sampleMonthPrice = 487;
const sampleData = [
  {
    computerName: 'sampleComputir',
    running: 78,
    stopped: 160,
    computer_type: 'Computir RTX 4000 (Windows)',
    usage_cost: 99,
  },
  {
    computerName: 'sampleComputir2',
    running: 312,
    stopped: 480,
    computer_type: 'Computir RTX 4000 (Windows)',
    usage_cost: 388,
  },
];

export default function BillingExample() {
  return (
    <>
      <p>
        Your bill is calculated solely off usage, then passed to Stripe for
        payment processing. Let's follow an example: someone uses a{' '}
        {sampleData[0]['computer_type']} for {sampleData[0]['running']} minutes
        and a separate {sampleData[1]['computer_type']} for{' '}
        {sampleData[1]['running']} minutes in the month of January 2023:
      </p>
      <BillingCard
        monthYear={sampleMonthYear}
        monthPrice={sampleMonthPrice}
        usageEntryList={sampleData}
        defaultExpanded={true}
      />
      <p>
        This is then translated and sent to Stripe. Stripe is configured to use
        a a $0.01 base price for a subscription, by which your final cost is
        multiplied:
      </p>
      <Box sx={{ maxWidth: { xs: '250px', sm: '500px' } }}>
        <img
          src="/stripe_billing_example.jpg"
          alt="stripe-example-invoice"
          style={{ width: '100%', height: 'auto' }}
        />
      </Box>
    </>
  );
}
