import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CreateComputerForm from './CreateComputerForm';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../components/Loading';
import SEO from '../../components/shared/SEO';
import CreateNotAllowed, {
  CreateBoxSkeleton,
  CreateErrorMessage,
} from './CreateNotAllowed';

@inject('computersStore', 'stripeStore', 'routerStore')
@observer
class CreateComputerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      computerName: '',
      computerType: '',
      computerStorage: 50,
      errorMessage: null,
    };
  }

  componentDidMount() {
    console.log(this.props.computersStore);
    console.log(this.props.computersStore.computers.length);
  }
  componentDidUpdate() {
    console.log(this.props.computersStore.computers.length);
  }

  renderComputerForm = () => {
    if (this.props.computersStore.actionState !== 'done') {
      return <CreateBoxSkeleton />;
    } else {
      if (this.props.computersStore.computers.length > 0) {
        if (this.props.computersStore.errorState) {
          return <CreateErrorMessage />;
        } else {
          return <CreateNotAllowed />;
        }
      } else {
        return (
          <CreateComputerForm
            store={this.props.computersStore}
            stripeStore={this.props.stripeStore}
          />
        );
      }
    }
  };

  render() {
    return (
      <>
        <SEO
          title={'Create Computir'}
          description={
            'A customizable hub for creating low cost work stations or powerful cloud gaming GPU computers.'
          }
        />
        <div>{this.renderComputerForm()}</div>
        {/* {this.props.computersStore.actionState !== 'done' ? (
          <Skeleton />
        ) : // listComputirs only returns non-terminated computirs
        !this.props.computersStore.computers.length ? (
          this.props.computersStore.errorState ? (
            <CreateErrorMessage />
          ) : (
            <CreateComputerForm
              store={this.props.computersStore}
              stripeStore={this.props.stripeStore}
            />
          )
        ) : (
          <CreateNotAllowed />
        )} */}
      </>
    );
  }
}

export default withAuthenticationRequired(CreateComputerPage, {
  onRedirecting: () => <Loading />,
});
