import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
// import Bill from '../../components/Bill';
import BillingCard, { parseEntryPrice, parseMonthPrice } from './BillingCard';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../components/Loading';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ComputersContainer } from '../../style/ComputersContainer';
import PageTitle from '../../components/shared/PageTitle';
import Link from '@mui/material/Link';
import TextButton from '../../components/TextButton';
import SEO from '../../components/shared/SEO';
import InvoicePreview from './InvoicePreview';

function getCurrentTotalCost(bills) {
  return 0;
}

function translateToDate(billDate) {
  // this expects milliseconds, we get seconds
  const dateObj = new Date(billDate * 1000);
  return (
    // it 0 indexes month (January = 0) so add 1
    (dateObj.getMonth() + 1).toString() +
    '-' +
    dateObj.getDate().toString() +
    '-' +
    dateObj.getFullYear().toString()
  );
}

function ViewBillingInfoButton(props) {
  switch (true) {
    case props.checkoutActionState === 'pending' &&
      props.portalActionState === 'done':
      return (
        <Button
          variant={'contained'}
          disableRipple
          onClick={() => props.handleClickToPortal()}
        >
          Click here to view / edit your billing information
        </Button>
      );
    case props.checkoutActionState === 'done' &&
      props.portalActionState === 'pending':
      return (
        <Button
          variant={'contained'}
          disableRipple
          onClick={() => props.handleClickToCheckout()}
        >
          Click here to add a payment method
        </Button>
      );
    default:
      return <Skeleton width={400} height={50} />;
  }
}
// TODO are these the correct routes
// TODO also attach these to function
@inject('billingStore', 'stripeStore')
@observer
class BillingPage extends Component {
  async componentDidMount() {
    const { getAccessTokenSilently, user, isLoading } = this.props.auth0;
    if (!isLoading && user.email_verified) {
      this.props.billingStore.token = getAccessTokenSilently();
      // TODO - add button to refresh bills, otherwise user will have to hard refresh the page (which is fine for now?)
      if (this.props.billingStore.actionState !== 'done') {
        this.props.billingStore.fetchBills();
      }
    }
    // get stripe store link
    if (
      this.props.stripeStore.paymentVerified === 'not verified' &&
      this.props.stripeStore.actionState === 'pending'
    ) {
      await this.props.stripeStore.getStripeCheckoutUrl();
    } else if (
      this.props.stripeStore.paymentVerified === 'verified' &&
      this.props.stripeStore.portalActionState === 'pending'
    ) {
      await this.props.stripeStore.getStripePortalUrl();
    }
  }
  async componentDidUpdate() {
    if (
      this.props.stripeStore.paymentVerified === 'not verified' &&
      this.props.stripeStore.actionState === 'pending'
    ) {
      await this.props.stripeStore.getStripeCheckoutUrl();
    } else if (
      this.props.stripeStore.paymentVerified === 'verified' &&
      this.props.stripeStore.portalActionState === 'pending'
    ) {
      await this.props.stripeStore.getStripePortalUrl();
    }
  }

  handleClickToPortal = (e) => {
    if (this.props.stripeStore.portalActionState === 'pending') {
      alert('Whoops - something went wrong, please try again...');
    } else {
      window.open(this.props.stripeStore.portalUrl);
    }
  };

  handleClickToCheckout = (e) => {
    if (this.props.stripeStore.actionState === 'pending') {
      alert('Whoops - something went wrong, please try again...');
    } else {
      window.open(this.props.stripeStore.url);
    }
  };

  handleClick = (e) => {
    this.props.billingStore.fetchBills();
    if (e.target.style.rotate) {
      if (e.target.style.rotate === '90deg') {
        e.target.style.rotate = '0deg';
        document.getElementById('dummyIdTemp').style.display = 'none';
      } else {
        e.target.style.rotate = '90deg';
        document.getElementById('dummyIdTemp').style.display = 'block';
      }
    } else {
      e.target.style.rotate = '90deg';
      document.getElementById('dummyIdTemp').style.display = 'block';
    }
  };

  getCurrentInvoice = () => {
    if (this.props.billingStore.actionState === 'pending') {
      return (
        <>
          <Typography
            fontWeight="bold"
            variant="h4"
            align="center"
            sx={{ marginTop: '3%' }}
          >
            Current Invoice (Preview)
          </Typography>
          <Skeleton width={'80%'} height={'100px'} />
        </>
      );
    } else {
      if (this.props.billingStore.currentInvoice.billDate === 'n/a') {
        return <></>;
      } else {
        console.log(this.props.billingStore);
        return (
          <>
            <Typography
              fontWeight="bold"
              variant="h4"
              align="center"
              sx={{ marginTop: '3%' }}
            >
              Current Invoice (Preview)
            </Typography>
            <InvoicePreview
              currentInvoice={this.props.billingStore.currentInvoice}
              billingThreshold={parseEntryPrice(
                this.props.billingStore.billingThreshold ?? 0,
              )}
            />
          </>
        );
      }
    }
  };
  // // This fetches each time... probably not optimal
  getBills = () => {
    const { billingStore } = this.props;
    if (billingStore.actionState === 'pending') {
      return <Skeleton width={'80%'} height={'100px'} />;
    } else {
      if (billingStore.bills.length !== 0) {
        return billingStore.bills.map((bill) => (
          <Fragment key={bill.monthYear}>
            <BillingCard
              monthYear={bill.monthYear}
              monthPrice={bill.monthPrice}
              usageEntryList={bill.details}
            />
          </Fragment>
        ));
      } else {
        return (
          <Box
            display={'flex'}
            sx={{
              borderRadius: '10px',
              marginTop: '1%',
              backgroundColor: 'cardBackground.secondary',
              width: '80%',
              height: '100px',
              alignItems: 'center',
              justifyContent: 'center',
              '&::before': { display: 'none' },
            }}
          >
            <Typography display={'inline'}>
              No usage yet! Let's get started: Check out the&nbsp;
            </Typography>
            <TextButton
              buttonText={'Frequently Asked Questions'}
              windowLocationHash={'faq'}
            />
            <Typography display={'inline'}>&nbsp;or&nbsp;</Typography>
            <TextButton
              buttonText={'create a computir'}
              windowLocationHash={'create'}
            />
          </Box>
        );
      }
    }
  };

  render() {
    return (
      <div>
        <SEO
          title={'Billing'}
          description={
            'View your current month usage and adjust billing preferences.'
          }
        />
        <ComputersContainer>
          <PageTitle title="Billing" />

          <Box
            display={'flex'}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: '1%',
            }}
          >
            {this.getCurrentInvoice()}
          </Box>
          <Typography
            fontWeight="bold"
            variant="h4"
            align="center"
            sx={{ marginTop: '3%' }}
          >
            Usage Records
          </Typography>
          <Box
            display={'flex'}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: '2%',
            }}
          >
            {this.getBills()}
          </Box>
          <Typography
            fontWeight="bold"
            variant="h4"
            align="center"
            sx={{ marginTop: '3%' }}
          >
            Past Invoices
          </Typography>
          <Box
            align="center"
            sx={{
              justifyContent: 'center',
              textAlign: 'center',
              padding: '0px 10% 0px 10%',
              marginTop: '2%',
            }}
          >
            <Typography sx={{ display: 'inline' }}>
              Computir partners with{' '}
              <Link href="https://stripe.com/" color="text.primary">
                Stripe
              </Link>{' '}
              to simplify billing. Stripe stores credit card information
              securely, issues invoices, and processes payments. Please visit
              the secure link below to the Stripe portal to manage your billing
              information and view past invoices.
            </Typography>
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '3%',
              }}
            >
              <ViewBillingInfoButton
                portalActionState={this.props.stripeStore.portalActionState}
                handleClickToPortal={this.handleClickToPortal}
                checkoutActionState={this.props.stripeStore.actionState}
                handleClickToCheckout={this.handleClickToCheckout}
              />
            </div>
          </Box>
        </ComputersContainer>
      </div>
    );
  }
}

export default withAuthenticationRequired(withAuth0(BillingPage), {
  onRedirecting: () => <Loading />,
});
