import React, { Component } from 'react';
import { CircularProgress, Modal } from '@mui/material';
import { inject } from 'mobx-react';
import { Button } from '@mui/material';
import { ComputerCardButton } from './Computer';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import SendIcon from '@mui/icons-material/Send';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function RdpCardButton({
  disabled,
  setComputerPassword,
  handleClosePasswordModal,
  statePassword,
  handleLaunchRdp,
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEnterKeyboardPressed = (e) => {
    // triggered when enter key is pressed
    if (e.charCode === 13) {
      handleLaunchRdp();
      setComputerPassword('');
      handleClosePasswordModal();
    }
  };

  const handleClick = () => {
    handleLaunchRdp();
    setComputerPassword('');
    handleClosePasswordModal();
  };

  return (
    <FormControl
      sx={{ m: 1, width: '30ch', display: 'flex', flexDirection: 'row' }}
      variant="outlined"
    >
      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        autoFocus
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
        onChange={(e) => {
          setComputerPassword(e.target.value);
        }}
        onKeyPress={(e) => handleEnterKeyboardPressed(e)}
        value={statePassword}
      />
      <Button
        onClick={() => handleClick()}
        variant={'contained'}
        disabled={disabled ? true : statePassword ? false : true}
        // endIcon={<ConnectWithoutContactIcon />}
        sx={{
          color: 'text.primary',
          borderColor: 'text.secondary',
          backgroundColor: 'text.secondary',
          '&:hover': { backgroundColor: 'transparent' },
          marginLeft: '2%',
        }}
      >
        {<SendIcon />}
      </Button>
    </FormControl>
  );
}

@inject('computersStore')
class RdpButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openSnackbarMsg: false,
      snackbarError: false,
      passwordModalOpen: false,
      computerPassword: '',
      openModal: false,
    };
  }

  // handleOpenPasswordModal() {
  //   this.setState((prevState) => ({
  //     passwordModalOpen: !prevState.passwordModalOpen,
  //   }));
  // }
  handleOpenPasswordModal = () => {
    this.setState({ passwordModalOpen: true });
  };
  handleClosePasswordModal = () => {
    this.setState({ passwordModalOpen: false });
  };

  handlePwChange = (passwordValue) => {
    this.setState({ computerPassword: passwordValue });
  };

  handleLaunchRdp() {
    this.setState({ loading: true });
    const computerRdp = this.props.computersStore.getRdpUrl(
      this.props.computerName,
      this.state.computerPassword,
    );
    computerRdp.then((results) => {
      if (results.data === 'notReady') {
        this.setState({
          loading: false,
          openSnackbarMsg: true,
          snackbarError: false,
        });
      } else if (results.data === 'something went wrong') {
        this.setState({
          loading: false,
          openSnackbarMsg: true,
          snackbarError: true,
        });
      } else {
        this.setState({ loading: false });
        window.open(results.data, '_blank');
      }
    });
  }

  handleCloseSnackbar = (event, reason) => {
    this.setState({
      openSnackbarMsg: false,
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="secondary" />
        </div>
      );
    } else {
      return (
        <>
          <Tooltip title="Connect via browser" placement="top">
            <ComputerCardButton
              onClick={this.handleOpenPasswordModal}
              buttonText={'CONNECT'}
            ></ComputerCardButton>
          </Tooltip>
          <Modal
            open={this.state.passwordModalOpen}
            onClose={this.handleClosePasswordModal}
            aria-labelledby="enter-password-modal"
            aria-describedby="a-modal-to-enter-password"
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#2C2C2C',
                boxShadow: 24,
                padding: '1.5% 4% 1.5% 4%',
                zIndex: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <RdpCardButton
                disabled={this.state.loading}
                setComputerPassword={(p) => this.handlePwChange(p)}
                handleClosePasswordModal={() => this.handleClosePasswordModal()}
                statePassword={this.state.computerPassword}
                handleLaunchRdp={() => this.handleLaunchRdp()}
              />
            </div>
          </Modal>

          <Snackbar
            open={this.state.openSnackbarMsg}
            autoHideDuration={10000}
            onClose={this.handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            severity={this.state.snackbarError ? 'error' : 'warning'}
            message={
              this.state.snackbarError
                ? 'Sorry, something went wrong. Please try again or contact support@computir.com for assistance'
                : 'Please wait, your computir has not been fully configured yet'
            }
          />
        </>
      );
    }
  }
}

export default RdpButton;
