import React, { Component } from 'react';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import { inject, observer } from 'mobx-react';
import { withAuth0 } from '@auth0/auth0-react';

function refreshList(computersStore, auth0, renderComputers) {
  const { user, isLoading, getAccessTokenSilently } = auth0;
    if (!isLoading && user.email_verified) {
      computersStore.token = getAccessTokenSilently();
      computersStore.fetchComputers();
      if (computersStore.actionState === 'done') {
        renderComputers()
      }
    }
}

@inject('computersStore')
@observer
class RefreshButton extends Component {

  render() {
    return (
      <Box sx={{ position: 'absolute', right: '0', display: 'flex', marginRight: '5%' }}>
      {this.props.computersStore.actionState === 'fetching'
        ? <Button variant='contained' disabled onClick={() => { refreshList(this.props.computersStore, this.props.auth0, this.props.renderComputers) }}
        sx={{ backgroundColor: 'primary.main' }}>
        <RefreshIcon sx={{animation: "spin 1s linear infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(-360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },}}/>
      </Button>
        : <Button variant='contained' onClick={() => { refreshList(this.props.computersStore, this.props.auth0, this.props.renderComputers) }}
        sx={{ backgroundColor: 'primary.main' }}>
        <RefreshIcon />
      </Button>
      }
      
      </Box>
    )
  }
}

export default withAuth0(RefreshButton)