import styled from 'styled-components';

export const ComputersContainer = styled.div`
padding-top: 20px;
`;

export const ComputersWrapper = styled.div`
width: 100%;
margin: auto;
box-sizing: border-box;
`;
// old max width that we applied, not sure if we ever want to bring this styling back
// max-width: 1200px;
// padding: 0px 20px 0px 20px;