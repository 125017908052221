import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withAuth0 } from '@auth0/auth0-react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { signInorUpStyle } from './Header';
import Snackbar from '@mui/material/Snackbar';
import { SmallLoading } from './Loading';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';

const textStyle = {
  width: '100%',
};

@inject('marketingStore', 'routerStore')
@observer
class CouponCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: '',
      promoCodeValidationError: false,
      openSnackbarMsg: false,
      openSnackbarErrorMsg: false,
      detailsModalOpen: false,
      displayLoading: false,
    };
  }

  promoCodeValidator(promoCode) {
    var re = /^COMPUTIR\d+$/;
    this.setState({ promoCodeValidationError: !re.test(promoCode) });
    return promoCode;
  }

  async redeemPromoCode() {
    const { getAccessTokenSilently } = this.props.auth0;
    this.props.marketingStore.token = getAccessTokenSilently();
    this.setState({ displayLoading: true });
    let response = { status: 201 };
    // TODO redeem code
    // let response = await this.props.marketingStore.redeemPromoCode({
    //   promoCode: this.state.promoCode,
    // });
    if (response.status === 201) {
      this.setState({
        promoCode: '',
        openSnackbarMsg: true,
        displayLoading: false,
      });
    } else {
      this.setState({
        openSnackbarErrorMsg: true,
        displayLoading: false,
      });
    }
  }

  handleCloseSnackbar = (event, reason) => {
    this.setState({
      openSnackbarMsg: false,
    });
  };

  handleCloseSnackbarError = (event, reason) => {
    this.setState({
      openSnackbarErrorMsg: false,
    });
  };

  render() {
    return (
      <div
        style={{
          marginLeft: 'auto !important',
          marginRight: 'auto !important',
        }}
      >
        <Accordion
          sx={{
            maxWidth: { xs: '80%', md: '50%' },
            border: '1px solid',
            borderColor: 'text.primary',
            borderRadius: '7px',
            marginLeft: 'auto !important',
            marginRight: 'auto !important',
            marginBottom: '5%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Redeem Promotion Code</Typography>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
          <Container sx={{ marginBottom: '5%' }}>
            <TextField
              onChange={(e) =>
                this.setState({
                  promoCode: this.promoCodeValidator(e.target.value),
                })
              }
              value={this.state.promoCode}
              sx={textStyle}
              required
              id="promotion-code-input"
              label="Promotion Code"
              variant="standard"
              error={this.state.promoCodeValidationError}
              helperText={
                !this.state.promoCodeValidationError
                  ? ''
                  : 'please enter a valid promotion code'
              }
            />
            <Box textAlign="center">
              <Button
                onClick={() => this.redeemPromoCode()}
                variant={'contained'}
                sx={{ marginTop: '5%', ...signInorUpStyle }}
                disabled={
                  this.state.promoCode.length === 0 ||
                  this.state.promoCodeValidationError
                }
              >
                Redeem Code
              </Button>
              {this.state.displayLoading ? <SmallLoading /> : ''}
            </Box>
          </Container>
          <Snackbar
            open={this.state.openSnackbarMsg}
            autoHideDuration={10000}
            onClose={this.handleCloseSnackbar}
            message={`Discount has been applied successfully!`}
          />
          <Snackbar
            open={this.state.openSnackbarErrorMsg}
            autoHideDuration={10000}
            onClose={this.handleCloseSnackbarError}
            message="Sorry - something went wrong please try again"
            color="red"
          />
        </Accordion>
      </div>
    );
  }
}

export default withAuth0(CouponCode);
