import React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// import SendIcon from '@mui/icons-material/Send';
// import Button from '@mui/material/Button';
import { ParagraphBox } from '../../components/ParagraphBox';
import SEO from '../../components/shared/SEO';

const aboutParagraph = "At Computir we strive to bring the most seamless and transparent remote gaming platform to our customers. We want a platform where someone could access a powerful computer on demand without paying a premium or having to pay the cost of a whole month to play a game for an hour";

function BulletBox() {
  return (
    <Box
      sx={{
        position: 'relative',
        p: { xs: 3, md: 6 },
        pr: { md: 0 },
        borderRadius: "10px", marginTop: "2%", width: "80%", left: "10%", backgroundColor: 'cardBackground.default'
      }}
    >
      <Typography component="h1" variant="h3" gutterBottom>
        New Features
      </Typography>
      <Typography>
        Coming Soon!
      </Typography>
      <ul>
        <li>Persist Computirs and storage</li>
        <li>bulk rate plan for high use gamers</li>
        <li>scheduled start and stop</li>
      </ul>
      {/* <RequestFeature /> */}
    </Box>
  )
}

// TODO - implement this?
// function RequestFeature() {
//   const [feature, setFeature] = React.useState('');
//   const handleChange = (event) => {
//     setFeature(event.target.value);
//   };
//   const sendRequest = (event) => {
//     // TODO idk send this to our API to process?
//     setFeature("");
//   }
//   return (
//     <Box
//       sx={{
//         width: "80%",
//         display: "inline",
//       }}
//     >
//       <TextField
//         sx={{ width: "80%" }}
//         InputLabelProps={{ sx: { color: 'text.primary' }, }}
//         fullWidth 
//         label="Request Feature"
//         id="requestFeature"
//         onChange={handleChange} value={feature} />
//       <Button sx={{ flexDirection: "column", justifyContent: "center" }} variant="contained" endIcon={<SendIcon />} onClick={sendRequest}>
//         Send
//       </Button>
//     </Box>
//   )
// }


export default function AboutPage() {
  return (
    <>
      <SEO
      title={'About'}
      description={'Learn more about Computir Inc and how we strive to bring low cost, accesible cloud gaming GPU computers to consumers.'} />
      <ParagraphBox title="About" paragraph={aboutParagraph} />
      <Divider variant="middle" sx={{ position: "relative", left: "20%", width: "60%", borderColor: 'text.primary', marginTop: "2%" }} />
      <BulletBox />
      <Divider variant="middle" sx={{ position: "relative", left: "20%", width: "60%", borderColor: 'text.primary', marginTop: "2%" }} />
    </>
  )
}