import React from 'react';
import HeadSection from './HeadSection';
import FeatureSection from './FeatureSection';
import PricingSection from './PricingSection';
import ComputerTypeSection from './ComputerTypeSection';
import NewsLetterSignUp from '../../components/NewsLetterSignUp';
import SEO from '../../components/shared/SEO';
import AccessDenied from '../accessDenied/accessDenied';
import { useLocation } from 'react-router-dom';
import PromoModal from '../../components/PromoModal';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function HomePage() {
  let query = useQuery();

  if (
    query.get('authenticated') === 'true' &&
    query.get('error') === 'access_denied'
  ) {
    return <AccessDenied />;
  } else {
    return (
      <>
        <SEO
          title={'Computir Home'}
          description={
            'Computir is a pay-as-you-go service for using powerful GPU cloud gaming computers or low cost Windows workstations, designed for people of all ages or abilities to get on a level playing field of hardware and computer performance for gaming, learning, or personal use.'
          }
        />
        {/* <PromoModal /> */}
        <HeadSection />
        <ComputerTypeSection />
        <PricingSection />
        <NewsLetterSignUp />
        <FeatureSection />
      </>
    );
  }
}
