import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function renderFeatures(feature) {
  return (
    <TableRow key={feature.title}>
      <TableCell>{feature.title}</TableCell>
      <TableCell align="right">{feature.content}</TableCell>
    </TableRow>
  );
}

export default function FeaturesBox() {
  const featuresObject = [
    {
      title: 'Billing Cycle',
      content:
        'Once monthly, or after a usage threshold is met - whichever occurs first.',
    },
    { title: 'Billing Unit', content: 'One (1) minute of usage' },
    { title: 'Minimum Usage / Charge per Month', content: '$0.50 USD' },
    { title: 'Usage Term', content: 'None! Usage is on demand' },
    {
      title: 'Connection Options',
      content:
        '(1) our simple in-browser RDP connection (2) BYOC! (bring your own client)',
    },
    { title: 'Mobile Support', content: 'Coming soon' },
    {
      title: 'Pricing for stopped Computir',
      content:
        'When your Computir is in the stopped state, you are charged for storage and reservation of the GPU resource, a nominal fee of $0.03/hour.',
    },
  ];

  return (
    <>
      <TableContainer sx={{ width: { xs: 350, sm: 800 } }} component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="features table">
          <TableBody>
            {featuresObject.map((feature) => {
              return renderFeatures(feature);
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
