import BaseHttpService from './base-http.service';

export default class ComputersService extends BaseHttpService {
  translateSelectedRegion(selectedRegion) {
    const mappings = {
      'us-east-1': 'us east',
      'us-east-2': 'us central',
      'us-west-1': 'us west',
    };
    return mappings[selectedRegion];
  }

  translateComputerTypeToProvider(computerType) {
    const mappings = {
      '': '',
    };
    return mappings[computerType];
  }

  fetchComputers() {
    return this.post('computers/get/', {
      ipAddress: '0.0.0.0',
      currentCommand: 'list_computers',
      computerType: '',
      computerName: '',
      computerStorage: 100,
    });
  }

  async updateComputerStatus(computer) {
    //TODO - not hardcoded
    if (computer.status === 'running' || computer.status === 'pending') {
      return this.post('computers/update/', {
        ipAddress: '0.0.0.0',
        currentCommand: 'stop',
        computerType: computer.computer_type,
        computerName: computer.computer_name,
        selectedRegion: this.translateSelectedRegion(computer.region),
      });
    } else if (computer.status === 'stopped') {
      return this.post('computers/update/', {
        ipAddress: '0.0.0.0',
        currentCommand: 'start',
        computerType: computer.computer_type,
        computerName: computer.computer_name,
        selectedRegion: this.translateSelectedRegion(computer.region),
      });
    }
  }

  fetchComputerPassword(computer) {
    return this.post('computers/update/', {
      ipAddress: '0.0.0.0',
      currentCommand: 'password',
      computerType: '',
      computerName: computer.computer_name,
      selectedRegion: this.translateSelectedRegion(computer.region),
    });
  }

  fetchRdpUrl(computer, computerPassword) {
    return this.post('computers/update/', {
      ipAddress: '0.0.0.0',
      currentCommand: 'rdp',
      computerType: computer.computer_type,
      computerName: computer.computer_name,
      selectedRegion: this.translateSelectedRegion(computer.region),
      computerPassword: computerPassword,
    });
  }

  async terminateComputer(computer) {
    return this.post('computers/update/', {
      ipAddress: '0.0.0.0',
      currentCommand: 'terminate',
      computerType: computer.computer_type,
      computerName: computer.computer_name,
      selectedRegion: this.translateSelectedRegion(computer.region),
    });
  }

  async createComputer(
    computerName,
    computerType,
    computerStorage,
    selectedRegion,
    computerPassword,
  ) {
    // const [computerTypeFinal, osType] = computerType.split('-');
    return this.post('computers/create/', {
      ipAddress: '0.0.0.0',
      currentCommand: 'create',
      computerType: computerType,
      computerName: computerName,
      computerStorage: Number(computerStorage),
      selectedRegion: selectedRegion,
      computerPassword: computerPassword,
    });
  }

  async sendVerificationEmail() {
    return this.post('authmanagement/email/');
  }

  async deleteUser() {
    return this.post('authmanagement/delete/');
  }
}
